import axios from "axios";
import authHeader from "./AuthHeader";

const API_URL = "https://sicecom.veracruz.gob.mx/api/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        console.clear();
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("status", JSON.stringify(false));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("status");
  }

  expireToken() {
    if (localStorage.getItem("user")) {
      localStorage.setItem("status", JSON.stringify(true));
    }
  }

  register(nombre, apellidoP, apellidoM, username, password) {
    const data = { nombre, apellidoP, apellidoM, username, password };

    return axios.post(API_URL + "signup", data, {
      headers: authHeader(),
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getCurrentStatus() {
    return JSON.parse(localStorage.getItem("status"));
  }
}

export default new AuthService();
