import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import EventBus from "../../common/EventBus";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import {
  Table,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Popover,
  Modal,
} from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import ReporteService from "../../services/ReporteService";
import MunicipioService from "../../services/MunicipioService";
import AuthService from "../../services/AuthService";
import { ExportCSV } from "../../services/ExportCSV";
import {
  BsEyeFill,
  BsCheckLg,
  BsFillFolderFill,
  BsFillCloudDrizzleFill,
} from "react-icons/bs";
import { MdDoubleArrow } from "react-icons/md";
import { FaQuestionCircle, FaLeaf, FaUsers } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { GiEarthCrack, GiSmallFire } from "react-icons/gi";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";

import {
  TiArrowUpThick,
  TiArrowUp,
  TiArrowDownThick,
  TiArrowDown,
} from "react-icons/ti";

const icons = [
  <BsFillCloudDrizzleFill />,
  <GiEarthCrack />,
  <FaLeaf />,
  <FaUsers />,
  <GiSmallFire />,
  <AiOutlinePaperClip />,
  <BiWorld />,
];
const colors = [
  "bluetext",
  "yellowtext",
  "greentext",
  "purpletext",
  "redtext",
  "graytext",
  "lightbluetext",
];

const fechaActual = new Date();
let year = new Date(fechaActual).getFullYear();
let month = new Date(fechaActual).getMonth() + 1;
let day = new Date(fechaActual).getDate();

let mesActual;
let diaActual;

if (month < 10) {
  mesActual = "0" + month;
} else {
  mesActual = month;
}

if (day < 10) {
  diaActual = "0" + day;
} else {
  diaActual = day;
}

let estatusNames = ["ÚNICOS", "PRELIMINARES", "CON SEGUIMIENTO", "CERRADOS"];

let nivelNames = ["Nivel 1", "Nivel 2", "Nivel 3"];

let fallecidosNames = ["Sin fallecidos", "Con fallecidos"];

let monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

class ReporteList extends Component {
  constructor(props) {
    super(props);
    this.retrieveReportes = this.retrieveReportes.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.getRequestParams = this.getRequestParams.bind(this);
    this.orderBy = this.orderBy.bind(this);
    this.onChangeDirection = this.onChangeDirection.bind(this);
    this.onChangeFolio = this.onChangeFolio.bind(this);
    this.onChangeEvento = this.onChangeEvento.bind(this);
    this.getEstatusList = this.getEstatusList.bind(this);
    this.getFallecidosList = this.getFallecidosList.bind(this);
    this.getNivelList = this.getNivelList.bind(this);
    this.getFenomenos = this.getFenomenos.bind(this);
    this.getTipos = this.getTipos.bind(this);
    this.onChangeFenomeno = this.onChangeFenomeno.bind(this)
    this.onChangeTipo = this.onChangeTipo.bind(this);
    this.getMunicipios = this.getMunicipios.bind(this);
    this.goToReporte = this.goToReporte.bind(this);
    this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
    this.onChangeLocalidad = this.onChangeLocalidad.bind(this);
    this.onChangeFechaIni = this.onChangeFechaIni.bind(this);
    this.onChangeFechaFin = this.onChangeFechaFin.bind(this);
    this.onChangeEstatus = this.onChangeEstatus.bind(this);
    this.onChangeFallecidos = this.onChangeFallecidos.bind(this);
    this.onChangeNivel = this.onChangeNivel.bind(this);

    this.resetEstatus = this.resetEstatus.bind(this);
    this.resetFallecidos = this.resetFallecidos.bind(this);
    this.resetNivel = this.resetNivel.bind(this);
    this.resetFenomeno = this.resetFenomeno.bind(this);
    this.resetTipo = this.resetTipo.bind(this);
    this.resetMunicipio = this.resetMunicipio.bind(this);
    this.resetLocalidad = this.resetLocalidad.bind(this);
    this.resetFechaIni = this.resetFechaIni.bind(this);
    this.resetFechaFin = this.resetFechaFin.bind(this);
    this.resetFolio = this.resetFolio.bind(this);
    this.resetEvento = this.resetEvento.bind(this);
    this.resetFilters = this.resetFilters.bind(this);

    this.deleteReporte = this.deleteReporte.bind(this);
    this.openModalBorrar = this.openModalBorrar.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      reportes: [],
      reportesCompletos: [],
      estatusList: [],
      fallecidosList :[],
      nivelList :[],
      fenomenos: [],
      tipos: [],
      municipios: [],

      estatus: null,
      fallecidos: null,
      nivel : null,
      idF: null,
      //Desde 0 hasta (CantidadTipoR + 1)
      idT:
        this.props.router.params.tipo > 0 && this.props.router.params.tipo < 53
          ? this.props.router.params.tipo
          : null,
      mun: null,
      loc: null,
      fechaIni: null,
      fechaFin: null,

      folio: "",
      evento: "",

      link: null,

      reportesTotales: 0,
      page: 1,
      count: 0,
      pageSize: 12,

      cargandoReportes: true,

      variable: "",
      direction: true,

      showAdminBoard: false,

      selectedReporte: {},

      isOpen: false,
      isBorrar: false,
      messageError: "",
    };

    this.pageSizes = [8, 10, 12, 14, 16];
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Lista de reportes";

      this.setState({
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
      });

      this.retrieveReportes();
      this.getEstatusList();
      this.getNivelList();
      this.getFallecidosList();
      this.getFenomenos();
      this.getMunicipios();
    }
  }

  onChangeDirection() {
    this.setState(
      {
        direction: !this.state.direction,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeFolio(e) {
    this.setState(
      {
        evento: "",
        page: 1,
        folio: e.target.value.toUpperCase(),
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeEvento(e) {
    this.setState(
      {
        folio: "",
        page: 1,
        evento: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeFechaIni(e) {
    this.setState(
      {
        page: 1,
        fechaIni: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeFechaFin(e) {
    this.setState(
      {
        page: 1,
        fechaFin: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeEstatus(e) {
    this.setState(
      {
        page: 1,
        estatus: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeNivel(e) {
    this.setState(
      {
        page: 1,
        nivel: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeFallecidos(e) {
    this.setState(
      {
        page: 1,
        fallecidos: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }
  

  onChangeFenomeno(e) {
    this.getTipos(e.target.value);
    this.setState(
      {
        tipos: [],
        idT: null,
        page: 1,
        idF: e.target.value,
      },
      () => {
        this.getMunicipios();
        this.retrieveReportes();
      }
    );
  }

  onChangeTipo(e) {
    this.setState(
      {
        page: 1,
        idT: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  onChangeMunicipio(e) {
    this.setState(
      {
        loc: null,
        page: 1,
        mun: e.target.value,
      },
      () => {
        this.getFenomenos();
        this.retrieveReportes();
      }
    );
  }

  onChangeLocalidad(e) {
    this.setState(
      {
        page: 1,
        loc: e.target.value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetEstatus() {
    this.setState(
      {
        estatus: null,
        page: 1,
      },
      () => {
        this.getFenomenos();
        this.retrieveReportes();
      }
    );
  }

  resetNivel() {
    this.setState(
      {
        nivel: null,
        page: 1,
      },
      () => {
        this.getFenomenos();
        this.retrieveReportes();
      }
    );
  }

  resetFallecidos() {
    this.setState(
      {
        fallecidos: null,
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetFenomeno() {
    this.setState(
      {
        fenomenos: [],
        tipos: [],
        idF: null,
        idT: null,
        page: 1,
      },
      () => {
        this.getFenomenos();
        this.retrieveReportes();
      }
    );
  }

  resetTipo() {
    this.setState(
      {
        idT: null,
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetMunicipio() {
    this.setState(
      {
        municipios: [],
        mun: null,
        loc: "",
        page: 1,
      },
      () => {
        this.getMunicipios();
        this.retrieveReportes();
      }
    );
  }

  resetLocalidad() {
    this.setState(
      {
        loc: "",
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetFechaIni() {
    this.setState(
      {
        fechaIni: null,
        fechaFin: null,
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetFechaFin() {
    this.setState(
      {
        fechaFin: null,
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetFolio() {
    this.setState(
      {
        folio: "",
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetEvento() {
    this.setState(
      {
        evento: "",
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  resetFilters() {
    this.setState(
      {
        fenomenos: [],
        tipos: [],
        municipios: [],
        folio: "",
        evento: "",
        page: 1,
        idF: null,
        idT: null,
        mun: null,
        loc: "",
        fechaIni: null,
        fechaFin: null,
        estatus: null,
        variable: "",
        direction: true,
        fallecidos: null,
        nivel: null,
      },
      () => {
        this.getFenomenos();
        this.getMunicipios();
        this.retrieveReportes();
      }
    );
  }

  getRequestParams(
    folio,
    evento,
    idF,
    idT,
    mun,
    loc,
    fechaIni,
    fechaFin,
    nivel,
    fallecidos,
    estatus,
    page,
    pageSize,
    variable,
    direction,
    
  ) {
    let params = {};
    if (folio) {
      params["folio"] = folio;
    }
    if (evento) {
      params["evento"] = evento;
    }

    if (idF) {
      params["idF"] = idF;
    }
    if (idT) {
      params["idT"] = idT;
    }
    if (mun) {
      params["mun"] = mun;
    }
    if (loc) {
      params["loc"] = loc;
    }
    if (nivel) {
      params["nivel"] = nivel;      
    }
    if (fallecidos) {
      params["fallecidos"] = fallecidos;      
    }
    if (fechaIni) {
      params["fechaIni"] = fechaIni + "T00:00:00";
    }
    if (fechaFin) {
      params["fechaFin"] = fechaFin + "T23:59:59";
    }
    if (estatus) {
      params["estatus"] = estatus;
    }
    if (page) {
      params["page"] = page - 1;
    }
    if (pageSize) {
      params["size"] = pageSize;
    }
    if (variable) {
      params["var"] = variable;
    }
    if (direction) {
      params["direction"] = direction;
    }
    
    return params;
  }

  retrieveReportes() {
    const {
      folio,
      evento,
      idF,
      idT,
      mun,
      loc,
      fechaIni,
      fechaFin,
      nivel,
      fallecidos,
      estatus,
      page,
      pageSize,
      variable,
      direction
    } = this.state;

    const params = this.getRequestParams(
      folio,
      evento,
      idF,
      idT,
      mun,
      loc,
      fechaIni,
      fechaFin,
      nivel,
      fallecidos,
      estatus,
      page,
      pageSize,
      variable,
      direction ? "DESC" : "ASC"
    );

    ReporteService.getReportes(params)
      .then((response) => {
        const { reporte, totalPages, totalItems } = response.data;
        this.setState({
          reportes: reporte,
          count: totalPages,
          reportesTotales: totalItems,
          cargandoReportes: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });

    ReporteService.getReportesCompletos(params)
      .then((response) => {
        const { reporte } = response.data;
        this.setState({
          reportesCompletos: reporte,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });

  }

  getEstatusList() {
    ReporteService.getEstatusList()
      .then((response) => {
        this.setState({
          estatusList: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getNivelList() {
    ReporteService.getNivelList()
      .then((response) => {
        this.setState({
          nivelList: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getFallecidosList() {
    ReporteService.getFallecidosList()
      .then((response) => {
        this.setState({
          fallecidosList: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getFenomenos() {
    ReporteService.getFenomenos()
      .then((response) => {
        this.setState({
          fenomenos: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getTipos(fenomeno) {
    ReporteService.getTipo(fenomeno)
      .then((response) => {
        this.setState({
          tipos: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getMunicipios() {
    MunicipioService.getMunicipios()
      .then((response) => {
        this.setState({
          municipios: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  goToReporte(tipo) {
    switch (tipo) {
      //HIDRO_ABCDEF
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        this.setState({
          link: "/HidA/",
        });
        break;

      //HIDRO_GHI
      case 7:
      case 8:
      case 9:
        this.setState({
          link: "/HidG/",
        });
        break;

      //HIDRO_JKL
      case 10:
      case 11:
      case 12:
        this.setState({
          link: "/HidJ/",
        });
        break;

      //GEO_ABCDE
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
        this.setState({
          link: "/GeoA/",
        });
        break;

      //GEO_F
      case 18:
        this.setState({
          link: "/GeoF/",
        });
        break;

      //ECO_AB
      case 19:
      case 20:
        this.setState({
          link: "/EcoA/",
        });
        break;

      //ORG_ABC
      case 21:
      case 22:
      case 23:
        this.setState({
          link: "/OrgA/",
        });
        break;

      //ORG_DEFGHI
      case 24:
      case 25:
      case 26:
      case 27:
      case 28:
      case 29:
        this.setState({
          link: "/OrgD/",
        });
        break;

      //ORG_JKLMNO
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        this.setState({
          link: "/OrgJ/",
        });
        break;

      //ORG_P
      case 50:
        this.setState({
          link: "/OrgP/",
        });
        break;

      //QUITEC_ABC
      case 36:
      case 37:
      case 38:
        this.setState({
          link: "/QuiA/",
        });
        break;

      //QUITEC_DEFGHIJKL
      case 39:
      case 40:
      case 41:
      case 42:
      case 43:
      case 44:
      case 45:
      case 51:
      case 52:
        this.setState({
          link: "/QuiD/",
        });
        break;

      //PER_A
      case 46:
        this.setState({
          link: "/PerA/",
        });
        break;

      //ASTRO_ABC
      case 47:
      case 48:
      case 49:
        this.setState({
          link: "/AstA/",
        });
        break;

      default:
        this.setState({
          link: "",
        });
        break;
    }
  }

  openModalBorrar(id) {
    ReporteService.getReporte(id)
      .then((response) =>
        this.setState({
          selectedReporte: response.data,
          isOpen: true,
          isBorrar: true,
          messageError: "",
        })
      )
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            selectedReporte: {},
            isOpen: true,
            isBorrar: true,
            messageError: err.message,
          });
        }
      });
  }

  closeModal() {
    this.setState({
      selectedReporte: {},
      isOpen: false,
      isBorrar: false,
      messageError: "",
    });
  }

  deleteReporte(id) {
    ReporteService.deleteReporte(id)
      .then(() => {
        this.retrieveReportes();
        this.closeModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            messageError: err.message,
          });
        }
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  orderBy(variable) {
    this.setState(
      {
        variable: variable,
        page: 1,
      },
      () => {
        this.retrieveReportes();
      }
    );
  }

  render() {
    const {
      reportes,
      reportesCompletos,
      page,
      count,
      pageSize,
      reportesTotales,
      cargandoReportes,
      showAdminBoard,
    } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <Row>
              <Col md={12}>
                <p className="nocolor mediumtext">&zwnj;</p>
                <h3 className="text-center">
                  <b>
                    {"LISTA DE REPORTES "}
                    <b
                      className={
                        !this.state.estatus
                          ? ""
                          : this.state.estatus === "1" ||
                            this.state.estatus === "4"
                          ? "concluidotext"
                          : "activotext"
                      }
                    >
                      {!this.state.estatus
                        ? "TOTALES"
                        : estatusNames[this.state.estatus - 1]}
                    </b>
                    {cargandoReportes ? (
                      <>
                        {" "}
                        <Spinner animation="border" size="lg" />
                      </>
                    ) : (
                      " (" + reportesTotales + ")"
                    )}{" "}
                    <BsFillFolderFill />
                  </b>
                </h3>

                {cargandoReportes ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th scope="col">
                          <OverlayTrigger
                            overlay={<Tooltip>Cambiar orden</Tooltip>}
                          >
                            {this.state.direction ? (
                              <Button
                                onClick={this.onChangeDirection}
                                size="sm"
                                variant="dark"
                              >
                                <TiArrowUpThick />
                                <TiArrowDown />
                              </Button>
                            ) : (
                              <Button
                                onClick={this.onChangeDirection}
                                size="sm"
                                variant="dark"
                              >
                                <TiArrowUp />
                                <TiArrowDownThick />
                              </Button>
                            )}
                          </OverlayTrigger>
                        </th>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por folio</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("folio")}
                          >
                            Folio
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>Ordenar por fecha de reporte</Tooltip>
                          }
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("fechaReporte")}
                          >
                            Fecha reporte
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>Ordenar por fecha de ocurrencia</Tooltip>
                          }
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("fechaOcurrencia")}
                          >
                            Fecha ocurrencia
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por fenómeno</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("tipoR_fenomeno")}
                          >
                            Fenómeno
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por tipo</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("tipoR")}
                          >
                            Tipo de reporte
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por municipio</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("municipio")}
                          >
                            Municipio
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por localidad</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("localidad")}
                          >
                            Localidad
                          </th>
                        </OverlayTrigger>
                        <th scope="col" style={{ cursor: "default" }}>
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportes.map((reporte, index) => (
                        <tr
                          key={reporte.id}
                          onMouseOver={() => this.goToReporte(reporte.tipoR.id)}
                          style={{ cursor: "default" }}
                        >
                          <td>
                            <strong>{index + 1}</strong>
                          </td>
                          <td>
                            <b
                              className={colors[reporte.tipoR.fenomeno.id - 1]}
                            >
                              {!reporte.estatus ? null : reporte.estatus.id ===
                                  1 || reporte.estatus.id === 4 ? (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {estatusNames[
                                        reporte.estatus.id - 1
                                      ].slice(
                                        0,
                                        estatusNames[reporte.estatus.id - 1]
                                          .length - 1
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <u>{reporte.folio}</u>
                                </OverlayTrigger>
                              ) : reporte.estatus.id === 2 ? (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {estatusNames[
                                        reporte.estatus.id - 1
                                      ].slice(
                                        0,
                                        estatusNames[reporte.estatus.id - 1]
                                          .length - 2
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <b>{reporte.folio}</b>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {estatusNames[reporte.estatus.id - 1]}
                                    </Tooltip>
                                  }
                                >
                                  <b>{reporte.folio}</b>
                                </OverlayTrigger>
                              )}
                            </b>
                          </td>

                          <td>
                            <strong>
                              {(new Date(reporte.fechaReporte).getDate() < 10
                                ? "0"
                                : "") +
                                new Date(reporte.fechaReporte).getDate() +
                                "/" +
                                monthNames[
                                  new Date(reporte.fechaReporte).getMonth()
                                ] +
                                "/" +
                                new Date(
                                  reporte.fechaReporte
                                ).getUTCFullYear() +
                                " - " +
                                (new Date(reporte.fechaReporte).getHours() < 10
                                  ? "0"
                                  : "") +
                                new Date(reporte.fechaReporte).getHours() +
                                ":" +
                                (new Date(reporte.fechaReporte).getMinutes() <
                                10
                                  ? "0"
                                  : "") +
                                new Date(reporte.fechaReporte).getMinutes()}
                            </strong>
                          </td>
                          <td>
                            {(new Date(reporte.fechaOcurrencia).getDate() < 10
                              ? "0"
                              : "") +
                              new Date(reporte.fechaOcurrencia).getDate() +
                              "/" +
                              monthNames[
                                new Date(reporte.fechaOcurrencia).getMonth()
                              ] +
                              "/" +
                              new Date(
                                reporte.fechaOcurrencia
                              ).getUTCFullYear() +
                              " - " +
                              (new Date(reporte.fechaOcurrencia).getHours() < 10
                                ? "0"
                                : "") +
                              new Date(reporte.fechaOcurrencia).getHours() +
                              ":" +
                              (new Date(reporte.fechaOcurrencia).getMinutes() <
                              10
                                ? "0"
                                : "") +
                              new Date(reporte.fechaOcurrencia).getMinutes()}
                          </td>
                          <td>
                            <strong>
                              {!reporte.tipoR
                                ? "N/A"
                                : reporte.tipoR.fenomeno.nombreF}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {!reporte.tipoR
                                ? "N/A"
                                : reporte.tipoR.id === 46 && reporte.evento
                                ? reporte.evento
                                : reporte.tipoR.nombreT}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {!reporte.municipio
                                ? "Sin municipio"
                                : reporte.municipio.municipio}
                            </strong>
                          </td>
                          <td>
                            {!reporte.localidad ? "N/A" : reporte.localidad}
                          </td>

                          <td className="nowrap">
                            {showAdminBoard && (
                              <OverlayTrigger
                                overlay={<Tooltip>Borrar</Tooltip>}
                              >
                                <Button
                                  variant="error"
                                  size="sm"
                                  onClick={() =>
                                    this.openModalBorrar(reporte.id)
                                  }
                                >
                                  <ImCross />
                                </Button>
                              </OverlayTrigger>
                            )}

                            <NavLink to={"/reporte/" + reporte.id}>
                              <OverlayTrigger overlay={<Tooltip>Ver</Tooltip>}>
                                <Button variant="gob" size="sm">
                                  <BsEyeFill />
                                </Button>
                              </OverlayTrigger>
                            </NavLink>
                            <Link
                              to={
                                this.state.link
                                  ? this.state.link + reporte.id
                                  : "#"
                              }
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    {!reporte.tipoR
                                      ? null
                                      : "Seguimiento / Editar"}
                                  </Tooltip>
                                }
                              >
                                {!reporte.estatus ? null : reporte.estatus
                                    .id === 1 || reporte.estatus.id === 4 ? (
                                  <Button variant="correct" size="sm">
                                    <BsCheckLg />
                                  </Button>
                                ) : (
                                  <Button variant="gold" size="sm">
                                    <MdDoubleArrow />
                                  </Button>
                                )}
                              </OverlayTrigger>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                <div className="footerp">
            
                  <Row>        
                    <h5 className="text-center">
                    <b>
                      {"Filtros"}
                    </b>
                    </h5>
                  </Row>    
                  <Row>
                    <Col xs="1">
                      <Form.Group className="mb-1" controlId="estatus">
                        <Form.Label>
                          <strong>Exportar:</strong>
                        </Form.Label>
                        <ExportCSV csvData={reportesCompletos} />
                      </Form.Group>
                    </Col>

                    <Col xs="1">
                      <Form.Group
                        className="mb-1 d-flex justify-content-center"
                        controlId="ayuda"
                      >
                        <Form.Label>
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Popover>
                                <Popover.Header as="h3">
                                  <b>Ayuda </b> <FaQuestionCircle />
                                </Popover.Header>
                                <Popover.Body>
                                  {" - Se pueden usar filtros múltiples"}
                                  <br />
                                  {
                                    " - Haga click en (X) sobre un filtro para limpiarlo individualmente"
                                  }
                                  <br />
                                  {
                                    " - Haga click en (Limpiar Filtros) para limpiar todos los filtros"
                                  }
                                  <br />
                                  {
                                    " - El excel generado incluirá los filtros aplicados"
                                  }
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <h4>
                              <FaQuestionCircle />
                            </h4>
                          </OverlayTrigger>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="fallecidos">
                        <Form.Label>
                          {this.state.fallecidos ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetFallecidos}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{"Personas fallecidas:"}</strong>
                        </Form.Label>
                        <Form.Select
                          value={!this.state.fallecidos ? "" : this.state.fallecidos}
                          onChange={this.onChangeFallecidos}
                          size="sm"
                        >
                          <option defaultValue={""} hidden>
                            Seleccione reporte...
                          </option>
                          {this.state.fallecidosList.map((fallecidos) => (
                            <option key={fallecidos.id} value={fallecidos.id}>
                              {fallecidosNames[fallecidos.id - 1]}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col> 


                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="nivel">
                        <Form.Label>
                          {this.state.nivel ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetNivel}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{"Nivel Emergencia:"}</strong>
                        </Form.Label>
                        <Form.Select
                          value={!this.state.nivel ? "" : this.state.nivel}
                          onChange={this.onChangeNivel}
                          size="sm"
                        >
                          <option defaultValue={""} hidden>
                            Seleccione el nivel...
                          </option>
                          {this.state.nivelList.map((nivel) => (
                            <option key={nivel.id} value={nivel.id}>
                              {nivelNames[nivel.id - 1]}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>  



                
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="estatus">
                        <Form.Label>
                          {this.state.estatus ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetEstatus}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{"Estatus:"}</strong>
                        </Form.Label>
                        <Form.Select
                          value={!this.state.estatus ? "" : this.state.estatus}
                          onChange={this.onChangeEstatus}
                          size="sm"
                        >
                          <option defaultValue={""} hidden>
                            Seleccione el estatus...
                          </option>
                          {this.state.estatusList.map((estatus) => (
                            <option key={estatus.id} value={estatus.id}>
                              {estatusNames[estatus.id - 1]}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>  
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="idF">
                        <Form.Label>
                          {this.state.idF ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetFenomeno}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{"Fenómeno:"}</strong>
                        </Form.Label>
                        <Form.Select
                          value={!this.state.idF ? "" : this.state.idF}
                          onChange={this.onChangeFenomeno}
                          size="sm"
                        >
                          <option defaultValue={""} hidden>
                            Seleccione el fenómeno...
                          </option>
                          {this.state.fenomenos.map((fenomeno) => (
                            <option key={fenomeno.id} value={fenomeno.id}>
                              {fenomeno.nombreF}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="idT">
                        <Form.Label>
                          {this.state.idT ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetTipo}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{"Tipo de Reporte:"}</strong>
                        </Form.Label>
                        <Form.Select
                          value={!this.state.idT ? "" : this.state.idT}
                          onChange={this.onChangeTipo}
                          size="sm"
                        >
                          <option defaultValue={""} hidden>
                            Seleccione el tipo de reporte...
                          </option>
                          {this.state.tipos.map((tipo) => (
                            <option key={tipo.id} value={tipo.id}>
                              {tipo.nombreT}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="mun">
                        <Form.Label>
                          {this.state.mun ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetMunicipio}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{"Buscar por municipio:"}</strong>
                        </Form.Label>
                        <Form.Select
                          value={!this.state.mun ? "" : this.state.mun}
                          onChange={this.onChangeMunicipio}
                          size="sm"
                        >
                          <option defaultValue={""} hidden>
                            Seleccione el municipio...
                          </option>
                          {this.state.municipios.map((municipio) => (
                            <option key={municipio.id} value={municipio.id}>
                              {municipio.municipio}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>


                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="loc">
                        <Form.Label>
                          {this.state.loc ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetLocalidad}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{" Buscar por localidad:"}</strong>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={!this.state.loc ? "" : this.state.loc}
                          onChange={this.onChangeLocalidad}
                          autoComplete="off"
                          maxLength="128"
                          name="localidad"
                          placeholder="Ingrese la localidad..."
                          size="sm"
                        />
                      </Form.Group>
                    </Col>



                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="fechaIni">
                        <Form.Label>
                          {this.state.fechaIni ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetFechaIni}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{" Fecha inicial (reporte):"}</strong>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={
                            !this.state.fechaIni ? "" : this.state.fechaIni
                          }
                          onChange={this.onChangeFechaIni}
                          max={
                            this.state.fechaFin
                              ? this.state.fechaFin
                              : year + "-" + mesActual + "-" + diaActual
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="fechaFin">
                        <Form.Label>
                          {this.state.fechaFin ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetFechaFin}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{" Fecha final (reporte):"}</strong>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={
                            !this.state.fechaFin ? "" : this.state.fechaFin
                          }
                          onChange={this.onChangeFechaFin}
                          min={this.state.fechaIni ? this.state.fechaIni : ""}
                          max={year + "-" + mesActual + "-" + diaActual}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="folio">
                        <Form.Label>
                          {this.state.folio ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetFolio}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{" Buscar por folio:"}</strong>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={!this.state.folio ? "" : this.state.folio}
                          onChange={this.onChangeFolio}
                          autoComplete="off"
                          maxLength="8"
                          name="folio"
                          placeholder="Ingrese el folio..."
                          size="sm"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="2">
                      <Form.Group className="mb-1" controlId="evento">
                        <Form.Label>
                          {this.state.evento ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetEvento}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{" Buscar por evento:"}</strong>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={!this.state.evento ? "" : this.state.evento}
                          onChange={this.onChangeEvento}
                          autoComplete="off"
                          maxLength="255"
                          name="evento"
                          placeholder="Ej. frente frío, huracán, etc."
                          size="sm"
                        />
                      </Form.Group>
                    </Col>



                    <Col xs="4">
                      <p className="nocolor bigtext">&zwnj;</p>
                      <strong>
                        &zwnj; Reportes por página:{" "}
                        <select
                          onChange={this.handlePageSizeChange}
                          value={pageSize}
                        >
                          {this.pageSizes.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                        <Button
                          variant="dark"
                          size="sm"
                          className="ms-2"
                          onClick={this.resetFilters}
                        >
                          Limpiar filtros
                        </Button>
                        <Link to={"/add"}>
                          <Button
                            variant="secondary"
                            size="sm"
                            className="ms-2"
                          >
                            Agregar reporte
                          </Button>
                        </Link>
                      </strong>

                      <Pagination
                        className="my-3"
                        count={count}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={this.handlePageChange}
                      />
                    </Col>



                  </Row>
                </div>

                <Modal
                  show={this.state.isOpen}
                  onHide={this.closeModal}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header>
                    {this.state.isBorrar ? (
                      <Modal.Title>
                        <div className="text-center">
                          ¿Desea <strong>BORRAR</strong> el reporte?
                          <br />
                          <b
                            className={
                              colors[
                                this.state.selectedReporte.tipoR.fenomeno.id - 1
                              ]
                            }
                          >
                            {" " + this.state.selectedReporte.folio}
                            {
                              icons[
                                this.state.selectedReporte.tipoR.fenomeno.id - 1
                              ]
                            }
                          </b>
                          <br />
                          <h5>
                            <strong>
                              (Se borrarán sus imágenes y seguimientos)
                            </strong>
                          </h5>
                          <p className="nocolor smalltext">&zwnj;</p>
                          <Button
                            className="m-2"
                            variant="secondary"
                            onClick={this.closeModal}
                          >
                            Cancelar
                          </Button>
                          <Button
                            className="m-2"
                            variant="error"
                            onClick={() =>
                              this.deleteReporte(this.state.selectedReporte.id)
                            }
                          >
                            <ImCross /> <strong>BORRAR</strong>
                          </Button>
                          {this.state.messageError ? (
                            <>
                              <p className="nocolor smalltext">&zwnj;</p>
                              <h5 className="redtext bolder centeralign">
                                ERROR
                              </h5>
                              <p className="redtext bigtext">
                                {this.state.messageError}
                              </p>
                            </>
                          ) : null}
                        </div>
                      </Modal.Title>
                    ) : null}
                  </Modal.Header>
                </Modal>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(ReporteList);
