import React, { Component } from "react";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import {
  Container,
  Row,
  Col,
  Alert,
  Carousel,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { AiFillInfoCircle, AiOutlinePaperClip } from "react-icons/ai";
import { BsFillCloudDrizzleFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";

import { GiEarthCrack, GiSmallFire } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";
import EventBus from "../../common/EventBus";

import { FaLeaf } from "react-icons/fa";
import ReporteService from "../../services/ReporteService";
import AuthService from "../../services/AuthService";

const variants = [
  "primary",
  "warning",
  "success",
  "purple",
  "danger",
  "secondary",
  "info",
];
const icons = [
  <BsFillCloudDrizzleFill />,
  <GiEarthCrack />,
  <FaLeaf />,
  <FaUsers />,
  <GiSmallFire />,
  <AiOutlinePaperClip />,
  <BiWorld />,
];
const colors = [
  "bluetext paragraph",
  "yellowtext paragraph",
  "greentext paragraph",
  "purpletext paragraph",
  "redtext paragraph",
  "graytext paragraph",
  "lightbluetext paragraph",
];

class HomeBoard extends Component {
  constructor(props) {
    super(props);
    this.getFenomenos = this.getFenomenos.bind(this);
    this.getTipos = this.getTipos.bind(this);
    this.getCountFenomenos = this.getCountFenomenos.bind(this);
    this.getCountTipos = this.getCountTipos.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      fenomenos: [],
      tipos: [],

      countFenomenos: [],
      countTipos: [],

      cargandoInfo: true,

      reportesTotales: null,

      index: 0,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Home";

      this.getCountFenomenos();
      this.getCountTipos(1);
      this.getFenomenos();
      this.getTipos(1);
    }
  }

  handleSelect(selectedIndex) {
    setTimeout(() => {
      this.getTipos(selectedIndex + 1);
      this.getCountTipos(selectedIndex + 1);
    }, 400);
    this.setState({
      index: selectedIndex,
    });
  }

  getCountFenomenos() {
    ReporteService.getContadoresFenomeno()
      .then((response) => {
        const { total, countFenomenos } = response.data;
        this.setState({
          reportesTotales: total,
          countFenomenos: countFenomenos,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getCountTipos(fenomeno) {
    ReporteService.getContadoresTipo(fenomeno)
      .then((response) => {
        const { countTipos } = response.data;
        this.setState({
          countTipos: countTipos,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getFenomenos() {
    ReporteService.getFenomenos()
      .then((response) => {
        this.setState({
          fenomenos: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        }
      });
  }

  getTipos(fenomenos) {
    ReporteService.getTipo(fenomenos)
      .then((response) => {
        this.setState({
          tipos: response.data,
          cargandoInfo: false,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        }
      });
  }

  goTo = (tipo) => {
    this.props.router.navigate("/list/" + tipo);
  };

  render() {
    const { reportesTotales, countFenomenos, countTipos } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div>
              <Container>
                <p className="nocolor bigtext">&zwnj;</p>
                <Row>
                  {this.state.cargandoInfo ? (
                    <div className="d-flex justify-content-center">
                      <h4>
                        <Spinner animation="border" size="lg" />
                      </h4>
                    </div>
                  ) : (
                    <>
                      <Col xl="3" sm="6" className="centered">
                        <Alert
                          variant="dark"
                          className="alert-size text-center"
                          style={{ cursor: "default" }}
                        >
                          <h3>
                            <AiFillInfoCircle />
                          </h3>
                          <h5>
                            Reportes
                            <br />
                            <strong> Totales:</strong>
                            <br />
                            <b>{reportesTotales}</b>
                          </h5>
                        </Alert>
                      </Col>

                      {this.state.fenomenos.map((fenomeno, index) => (
                        <Col
                          key={fenomeno.id}
                          xl="3"
                          sm="6"
                          className="centered"
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip>Ver {fenomeno.nombreF + "s"}</Tooltip>
                            }
                          >
                            <Alert
                              variant={variants[index]}
                              className="alert-size text-center"
                              onClick={() => this.handleSelect(index)}
                              style={{ cursor: "pointer" }}
                            >
                              <h3>{icons[index]}</h3>
                              <h5>
                                Reportes
                                <br />
                                <strong>{" " + fenomeno.nombreF + "s:"}</strong>
                                <br />
                                <b>{countFenomenos[index]}</b>
                              </h5>
                            </Alert>
                          </OverlayTrigger>
                        </Col>
                      ))}

                      <Col xs="1"></Col>
                      <Col xl="10" md="12">
                        <Carousel
                          interval={6000}
                          className="carousel-size"
                          variant="dark"
                          activeIndex={this.state.index}
                          onSelect={this.handleSelect}
                          style={{ cursor: "default" }}
                        >
                          {this.state.fenomenos.map((fenomeno, index) => (
                            <Carousel.Item key={fenomeno.id}>
                              <Alert
                                variant={variants[index]}
                                className="carousel-size"
                              >
                                <Alert.Heading className="text-center">
                                  <h3>
                                    <strong>{fenomeno.nombreF + "s "}</strong>{" "}
                                    {icons[index]}
                                  </h3>
                                </Alert.Heading>

                                <Table borderless hover size="sm">
                                  <tbody
                                    className={colors[index] + " boldtext"}
                                  >
                                    {this.state.tipos.map((tipo, index) => (
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip>Consultar tipo</Tooltip>
                                        }
                                        key={tipo.id}
                                      >
                                        <tr
                                          style={{ cursor: "pointer" }}
                                          onClick={() => this.goTo(tipo.id)}
                                        >
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>{index + 1 + "."}</td>
                                          <td>{tipo.nombreT}</td>
                                          <td>{countTipos[index]}</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </OverlayTrigger>
                                    ))}
                                  </tbody>
                                </Table>
                              </Alert>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(HomeBoard);
