import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBus from "../../common/EventBus";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import {
  Alert,
  Row,
  Col,
  Stack,
  Button,
  Carousel,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { ImArrowLeft2 } from "react-icons/im";
import ReporteService from "../../services/ReporteService";
import AuthService from "../../services/AuthService";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsFillCloudDrizzleFill, BsWhatsapp, BsCheckLg } from "react-icons/bs";
import { GiEarthCrack, GiSmallFire } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";
import { FaLeaf, FaUsers } from "react-icons/fa";
import { MdDoubleArrow, MdDownload, MdContentCopy } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";

const variants = [
  "primary",
  "warning",
  "success",
  "purple",
  "danger",
  "secondary",
  "info",
];
const icons = [
  <BsFillCloudDrizzleFill />,
  <GiEarthCrack />,
  <FaLeaf />,
  <FaUsers />,
  <GiSmallFire />,
  <AiOutlinePaperClip />,
  <BiWorld />,
];
const colors = [
  "text-primary paragraph",
  "text-warning paragraph",
  "text-success paragraph",
  "purpletext paragraph",
  "text-danger paragraph",
  "text-secondary paragraph",
  "text-info paragraph",
];
const buttons = [
  "primary",
  "warning",
  "success",
  "purple",
  "danger",
  "secondary",
  "info",
];
const outlineButtons = [
  "outline-primary",
  "outline-warning",
  "outline-success",
  "outline-purple",
  "outline-danger",
  "outline-secondary",
  "outline-info",
];
const monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

const niveles = ["nivel1", "nivel2", "nivel3"];

class ReporteView extends Component {
  constructor(props) {
    super(props);
    this.goToReporte = this.goToReporte.bind(this);
    this.getReporte = this.getReporte.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.getSeguimientos = this.getSeguimientos.bind(this);

    this.state = {
      files: [],
      seguimientos: [],
      seguimientosN: null,

      reporte: {
        id: null,

        ubicacionPersonalizada: null,
        localizacion: null,
        profundidad: null,
        magnitud: null,
        municipiosPercepcion: null,
        municipiosPercepcionN: null,
        inmueblesEvacuados: null,
        inmueblesEvacuadosN: null,
        cna: null,
        pemex: null,
        secretariaSalud: null,
        lagunaVerde: null,
        tunelSumergido: null,

        ubicacion: null,
        ubicaciones: null,
        ubicacionesN: null,
        afectaciones: null,
        afectacionesN: null,

        sustancias: null,
        estatusFuga: {
          id: null,
          estatusFuga: null,
        },
        estatusControl: {
          id: null,
          estatusControl: null,
        },
        estatusLiquidacion: {
          id: null,
          estatusLiquidacion: null,
        },

        viasAlternas: null,
        localidadesAfectadas: null,
        localidadesAfectadasN: null,
        localidadesIncomunicadas: null,
        localidadesIncomunicadasN: null,
        localidadesConAcceso: null,
        localidadesConAccesoN: null,
        viviendasA: null,
        viviendasAN: null,
        coloniasA: null,
        coloniasAN: null,
        comunidadesA: null,
        comunidadesAN: null,
        caidaArboles: null,
        caidaArbolesN: null,
        caidaPostes: null,
        caidaPostesN: null,
        caidaEspectaculares: null,
        caidaEspectacularesN: null,
        viasA: null,
        viasAN: null,
        construccionesA: null,
        construccionesAN: null,
        puentesA: null,
        puentesAN: null,
        escuelasA: null,
        escuelasAN: null,
        serviciosEstrategicos: null,
        serviciosEstrategicosN: null,

        subcentrosRegionales: null,

        inconformidad: null,
        afluenciaN: null,
        atencionesMedicas: null,

        unidadesInvolucradas: null,
        unidadesInvolucradasN: null,

        personasEvacuadas: null,
        personasEvacuadasN: null,
        personasRefugiadas: null,
        personasRefugiadasN: null,
        personasRescatadas: null,
        personasRescatadasN: null,
        personasLocalizadas: null,
        personasLocalizadasN: null,
        personasNoLocalizadas: null,
        personasNoLocalizadasN: null,
        personasAtrapadas: null,
        personasAtrapadasN: null,
        personasLesionadas: null,
        personasLesionadasN: null,
        personasAhogadas: null,
        personasAhogadasN: null,
        personasFallecidas: null,
        personasFallecidasN: null,

        titulo1: null,
        titulo2: null,
        titulo3: null,
        titulo4: null,
        contenido1: null,
        contenido2: null,
        contenido3: null,
        contenido4: null,

        fechaTermino: null,
        estimado2: null,

        reporte: {
          id: null,
          folio: null,
          evento: null,
          fechaReporte: null,
          estimado: null,
          reporta: null,
          cargo: null,
          latitud: null,
          longitud: null,
          fechaOcurrencia: null,
          accionesImplementadas: null,
          fuerzaTarea: null,
          notas: null,
          estatus: {
            id: null,
            estatus: null,
          },
          tipoR: {
            id: null,
            nombreT: null,
            prefijo: null,
            contador: null,
            fenomeno: {
              id: null,
              nombreF: null,
            },
          },
          user: {
            id: null,
            username: null,
            password: null,
            roles: [],
            apellidoM: null,
            apellidoP: null,
            nombre: null,
          },
          region: {
            id: null,
            region: null,
          },
          municipio: {
            id: null,
            municipio: null,
            region: {
              id: null,
              region: null,
            },
          },
          localidad: null,
          refugio: {
            id: null,
            nombre: null,
            latitud: null,
            longitud: null,
            direccion: null,
            estatus: null,
            nivelEmergencia: null,
            capacidad: null,
            municipio: {
              id: null,
              municipio: null,
              region: {
                id: null,
                region: null,
              },
            },
            fechaActivacion: null,
            fechaDesactivacion: null,
          },
        },
      },

      link: null,

      copiado: false,
      messageError: null,
      cargandoReporte: true,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      this.getSeguimientos();
      this.getFiles();
      this.getReporte();
    }
  }

  getReporte() {
    ReporteService.getReporteCompleto(this.props.router.params.id)
      .then((response) => {
        this.setState({
          cargandoReporte: false,
          reporte: response.data,
        });
        this.goToReporte(response.data.reporte.tipoR.id);
        document.title = "SICECOM - Reporte " + response.data.reporte.folio;
      })
      .catch((err) => {
        console.log(err);
        document.title = "SICECOM - Reporte ERROR";

        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
          err = "Usuario no identificado";

          this.setState({
            messageError: "Error en la autentificación del usuario: " + err,
          });
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 404) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/reportesList");
        } else {
          this.setState({
            cargandoReporte: false,
            messageError: "Error en la carga del reporte: " + err,
          });
        }
      });
  }

  getFiles() {
    ReporteService.getReporteFiles(this.props.router.params.id)
      .then((response) => {
        this.setState({
          files: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
          err = "Usuario no identificado";
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/reportesList");
        } else {
          console.log(err);
        }
      });
  }

  getSeguimientos() {
    ReporteService.getReporteSeguimientos(this.props.router.params.id)
      .then((response) => {
        this.setState({
          seguimientos: response.data.seguimiento,
          seguimientosN: response.data.totalItems,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
          err = "Usuario no identificado";
        } else {
          this.setState({
            messageError: "Error en la carga de seguimientos: " + err,
          });
        }
      });
  }

  goToReporte(tipo) {
    switch (tipo) {
      //HIDRO_ABCDEF
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        this.setState({
          link: "/HidA/",
        });
        break;

      //HIDRO_GHI
      case 7:
      case 8:
      case 9:
        this.setState({
          link: "/HidG/",
        });
        break;

      //HIDRO_JKL
      case 10:
      case 11:
      case 12:
        this.setState({
          link: "/HidJ/",
        });
        break;

      //GEO_ABCDE
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
        this.setState({
          link: "/GeoA/",
        });
        break;

      //GEO_F
      case 18:
        this.setState({
          link: "/GeoF/",
        });
        break;

      //ECO_AB
      case 19:
      case 20:
        this.setState({
          link: "/EcoA/",
        });
        break;

      //ORG_ABC
      case 21:
      case 22:
      case 23:
        this.setState({
          link: "/OrgA/",
        });
        break;

      //ORG_DEFGHI
      case 24:
      case 25:
      case 26:
      case 27:
      case 28:
      case 29:
        this.setState({
          link: "/OrgD/",
        });
        break;

      //ORG_JKLMNO
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        this.setState({
          link: "/OrgJ/",
        });
        break;

      //ORG_P
      case 50:
        this.setState({
          link: "/OrgP/",
        });
        break;

      //QUITEC_ABC
      case 36:
      case 37:
      case 38:
        this.setState({
          link: "/QuiA/",
        });
        break;

      //QUITEC_DEFGHIJKL
      case 39:
      case 40:
      case 41:
      case 42:
      case 43:
      case 44:
      case 45:
      case 51:
      case 52:
        this.setState({
          link: "/QuiD/",
        });
        break;

      //PER_A
      case 46:
        this.setState({
          link: "/PerA/",
        });
        break;

      //ASTRO_ABC
      case 47:
      case 48:
      case 49:
        this.setState({
          link: "/AstA/",
        });
        break;

      default:
        this.setState({
          link: "",
        });
        break;
    }
  }

  onChangeCopiado() {
    this.setState({
      copiado: true,
    });
    setTimeout(() => {
      this.setState({
        copiado: false,
      });
    }, 1500);
  }

  goBack = () => {
    this.props.router.navigate(-1);
  };

  render() {
    const { reporte, seguimientos } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                {this.state.cargandoReporte ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Alert
                    variant={variants[reporte.reporte.tipoR.fenomeno.id - 1]}
                  >
                    <div className="m-1 d-grid gap-2 d-md-block">
                      <Row>
                        <Col xl="10" sm="12">
                          <h3>
                            <b>
                              {!reporte.reporte.estatus
                                ? null
                                : reporte.reporte.estatus.id === 1
                                ? ""
                                : reporte.reporte.estatus.estatus + " A "}

                              {reporte.reporte.tipoR.id !== 46
                                ? reporte.reporte.tipoR
                                  ? reporte.reporte.tipoR.nombreT.toUpperCase()
                                  : "REPORTE"
                                : reporte.reporte.evento
                                ? reporte.reporte.evento.toUpperCase()
                                : "REPORTE"}
                              {reporte.reporte.municipio
                                ? " EN " +
                                  reporte.reporte.municipio.municipio.toUpperCase()
                                : reporte.region
                                ? " EN LA REGIÓN DE " +
                                  reporte.region.region.toUpperCase()
                                : reporte.ubicacionPersonalizada
                                ? " EN " +
                                  reporte.ubicacionPersonalizada.toUpperCase()
                                : ""}
                            </b>
                            <p>
                              {reporte.reporte.nivelEmergencia ? (
                                <>
                                  {" "}
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip>
                                        {"Nivel de emergencia " +
                                          reporte.reporte.nivelEmergencia}
                                      </Tooltip>
                                    }
                                  >
                                    <b>
                                      <Button
                                        variant="nocolor"
                                        className={
                                          niveles[
                                            reporte.reporte.nivelEmergencia - 1
                                          ]
                                        }
                                        size="sm"
                                        style={{ pointerEvents: "none" }}
                                      >
                                        {"NIVEL " +
                                          reporte.reporte.nivelEmergencia}
                                      </Button>
                                    </b>
                                  </OverlayTrigger>
                                </>
                              ) : null}
                            </p>
                          </h3>
                        </Col>
                        <Col xl="2" sm="12">
                          <h3
                            className={
                              colors[reporte.reporte.tipoR.fenomeno.id - 1]
                            }
                          >
                            <b>{reporte.reporte.folio}</b>
                            {icons[reporte.reporte.tipoR.fenomeno.id - 1]}
                            {reporte.reporte.estatus ? (
                              <>
                                {" "}
                                <Button
                                  variant={
                                    variants[
                                      reporte.reporte.tipoR.fenomeno.id - 1
                                    ]
                                  }
                                  size="sm"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <b>
                                    {reporte.reporte.estatus.estatus}
                                    {reporte.reporte.estatus.id === 1 ||
                                    reporte.reporte.estatus.id === 4 ? (
                                      <>
                                        {" "}
                                        <BsCheckLg />
                                      </>
                                    ) : null}
                                  </b>
                                </Button>
                              </>
                            ) : null}
                          </h3>
                        </Col>
                        <Col xs="12">
                          {reporte.reporte.localidad ? (
                            <h5>
                              <strong>
                                {"Localidad: "}
                                <strong
                                  className={
                                    colors[
                                      reporte.reporte.tipoR.fenomeno.id - 1
                                    ]
                                  }
                                >
                                  {reporte.reporte.localidad
                                    ? reporte.reporte.localidad
                                    : null}
                                </strong>
                              </strong>
                            </h5>
                          ) : null}
                        </Col>
                        {reporte.reporte.user ? (
                          <Col xs="12">
                            <strong>
                              {"Última modificación: "}
                              <strong
                                className={
                                  colors[reporte.reporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {reporte.reporte.user.username
                                  ? reporte.reporte.user.nombre +
                                    " " +
                                    reporte.reporte.user.apellidoP +
                                    " " +
                                    reporte.reporte.user.apellidoM
                                  : null}
                              </strong>
                            </strong>
                          </Col>
                        ) : null}

                        <hr />

                        <Col xs="12">
                          {reporte.reporte.fechaReporte ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Fecha del reporte: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {(new Date(
                                      reporte.reporte.fechaReporte
                                    ).getDate() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaReporte
                                      ).getDate() +
                                      "/" +
                                      monthNames[
                                        new Date(
                                          reporte.reporte.fechaReporte
                                        ).getMonth()
                                      ] +
                                      "/" +
                                      new Date(
                                        reporte.reporte.fechaReporte
                                      ).getUTCFullYear() +
                                      " - "}
                                    {(new Date(
                                      reporte.reporte.fechaReporte
                                    ).getHours() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaReporte
                                      ).getHours() +
                                      ":" +
                                      (new Date(
                                        reporte.reporte.fechaReporte
                                      ).getMinutes() < 10
                                        ? "0"
                                        : "") +
                                      new Date(
                                        reporte.reporte.fechaReporte
                                      ).getMinutes()}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.reporte.fechaOcurrencia ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Fecha de ocurrencia: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {(new Date(
                                      reporte.reporte.fechaOcurrencia
                                    ).getDate() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaOcurrencia
                                      ).getDate() +
                                      "/" +
                                      monthNames[
                                        new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getMonth()
                                      ] +
                                      "/" +
                                      new Date(
                                        reporte.reporte.fechaOcurrencia
                                      ).getUTCFullYear() +
                                      " - "}
                                    {(new Date(
                                      reporte.reporte.fechaOcurrencia
                                    ).getHours() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaOcurrencia
                                      ).getHours() +
                                      ":" +
                                      (new Date(
                                        reporte.reporte.fechaOcurrencia
                                      ).getMinutes() < 10
                                        ? "0"
                                        : "") +
                                      new Date(
                                        reporte.reporte.fechaOcurrencia
                                      ).getMinutes() +
                                      (new Date(
                                        reporte.reporte.fechaOcurrencia
                                      ).getSeconds() < 1
                                        ? ""
                                        : new Date(
                                            reporte.reporte.fechaOcurrencia
                                          ).getSeconds() < 10
                                        ? ":0" +
                                          new Date(
                                            reporte.reporte.fechaOcurrencia
                                          ).getSeconds()
                                        : ":" +
                                          new Date(
                                            reporte.reporte.fechaOcurrencia
                                          ).getSeconds())}
                                    {reporte.reporte.estimado
                                      ? " aproximadamente"
                                      : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        {reporte.reporte.tipoR.id === 46 ? null : reporte
                            .reporte.evento ? (
                          <Col xs="12">
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Evento: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.reporte.evento}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          </Col>
                        ) : null}

                        <Col xs="12">
                          {reporte.reporte.reporta ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Reporta: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.reporte.reporta}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.reporte.cargo ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Cargo: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.reporte.cargo}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.reporte.latitud &&
                          reporte.reporte.longitud ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Coordenadas: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <a
                                    href={
                                      "https://www.google.com/maps/search/" +
                                      reporte.reporte.latitud +
                                      ",+" +
                                      reporte.reporte.longitud +
                                      ""
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="linktext"
                                  >
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>Ver en Google maps</Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        variant={
                                          buttons[
                                            reporte.reporte.tipoR.fenomeno.id -
                                              1
                                          ]
                                        }
                                        className="mb-1"
                                      >
                                        <b>
                                          {reporte.reporte.latitud +
                                            "°, " +
                                            reporte.reporte.longitud +
                                            "° "}
                                          <FaMapMarkerAlt />
                                        </b>
                                      </Button>
                                    </OverlayTrigger>
                                    <strong></strong>
                                  </a>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.magnitud ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Magnitud: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.magnitud}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.profundidad ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Profundidad: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.profundidad + " km."}{" "}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.localizacion ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Localización: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.localizacion}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.municipiosPercepcionN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Municipios con percepción (" +
                                      reporte.municipiosPercepcionN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.municipiosPercepcion ? (
                                      <strong
                                        className={
                                          colors[
                                            reporte.reporte.tipoR.fenomeno.id -
                                              1
                                          ]
                                        }
                                      >
                                        {reporte.municipiosPercepcion}
                                      </strong>
                                    ) : (
                                      "Sin reporte de percepción"
                                    )}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.inmueblesEvacuadosN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Inmuebles Evacuados (" +
                                      reporte.inmueblesEvacuadosN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.inmueblesEvacuados ? (
                                      <strong
                                        className={
                                          colors[
                                            reporte.reporte.tipoR.fenomeno.id -
                                              1
                                          ]
                                        }
                                      >
                                        {reporte.inmueblesEvacuados}
                                      </strong>
                                    ) : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.ubicacion ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Ubicación: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.ubicacion}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.ubicacionesN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Ubicaciones (" +
                                      reporte.ubicacionesN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.ubicaciones}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.afectacionesN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {reporte.reporte.tipoR.id === 39
                                      ? "Unidades afectadas (" +
                                        reporte.afectacionesN +
                                        "): "
                                      : reporte.reporte.tipoR.id === 40
                                      ? "Locales afectados (" +
                                        reporte.afectacionesN +
                                        "): "
                                      : reporte.reporte.tipoR.id === 42
                                      ? "Casas afectadas (" +
                                        reporte.afectacionesN +
                                        "): "
                                      : reporte.reporte.tipoR.id === 52
                                      ? "Instalaciones afectadas (" +
                                        reporte.afectacionesN +
                                        "): "
                                      : "Superficies afectadas (" +
                                        reporte.afectacionesN +
                                        "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.afectaciones}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.sustancias &&
                          reporte.reporte.tipoR.id !== 36 ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Sustancia: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.sustancias}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.estatusFuga ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Estatus: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.estatusFuga.estatusFuga}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.estatusControl ||
                          reporte.estatusLiquidacion ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Estatus del incendio: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {(reporte.estatusControl
                                      ? reporte.estatusControl.estatusControl
                                      : "") +
                                      (reporte.estatusControl &&
                                      reporte.estatusLiquidacion
                                        ? ", "
                                        : "") +
                                      (reporte.estatusLiquidacion
                                        ? reporte.estatusLiquidacion
                                            .estatusLiquidacion
                                        : "")}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.viasAlternas ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Vías de comunicación alternas: "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.viasAlternas}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        {/* AQUÍ EMPIEZA LO NUEVO HID_JKL */}
                        <Col xs="12">
                          {reporte.localidadesAfectadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Localidades afectadas (" +
                                      reporte.localidadesAfectadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.localidadesAfectadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.localidadesAfectadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.localidadesIncomunicadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Localidades incomunicadas (" +
                                      reporte.localidadesIncomunicadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.localidadesIncomunicadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.localidadesIncomunicadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.localidadesConAccesoN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Localidades con acceso (" +
                                      reporte.localidadesConAccesoN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.localidadesConAcceso ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.localidadesConAcceso}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        {/* AQUÍ TERMINA LO NUEVO HID_JKL */}
                        <Col xs="12">
                          {reporte.viviendasAN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Viviendas afectadas (" +
                                      reporte.viviendasAN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.viviendasA ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.viviendasA}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.coloniasAN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Colonias afectadas (" +
                                      reporte.coloniasAN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.coloniasA ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.coloniasA}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.comunidadesAN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Comunidades afectadas (" +
                                      reporte.comunidadesAN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.comunidadesA ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.comunidadesA}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.caidaArbolesN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Árboles caídos (" +
                                      reporte.caidaArbolesN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.caidaArboles ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.caidaArboles}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.caidaPostesN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Postes caídos (" +
                                      reporte.caidaPostesN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.caidaPostes ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.caidaPostes}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.caidaEspectacularesN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Espectaculares caídos (" +
                                      reporte.caidaEspectacularesN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.caidaEspectaculares ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.caidaEspectaculares}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.viasAN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Vías afectadas (" +
                                      reporte.viasAN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.viasA ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.viasA}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.construccionesAN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Construcciones afectadas (" +
                                      reporte.construccionesAN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.construccionesA ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.construccionesA}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.puentesAN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Puentes afectados (" +
                                      reporte.puentesAN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.puentesA ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.puentesA}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.escuelasAN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Escuelas afectadas (" +
                                      reporte.escuelasAN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.escuelasA ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.escuelasA}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.inconformidad ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Inconformidad: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.inconformidad}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.afluenciaN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Afluencia: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.afluenciaN}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.atencionesMedicas ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Atenciones médicas: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.atencionesMedicas}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {reporte.unidadesInvolucradasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Unidades involucradas (" +
                                      reporte.unidadesInvolucradasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.unidadesInvolucradas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.unidadesInvolucradas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.personasEvacuadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas evacuadas (" +
                                      reporte.personasEvacuadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasEvacuadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasEvacuadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {reporte.personasRefugiadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas refugiadas (" +
                                      reporte.personasRefugiadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasRefugiadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasRefugiadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {reporte.personasRescatadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas rescatadas (" +
                                      reporte.personasRescatadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasRescatadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasRescatadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.personasLocalizadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas localizadas (" +
                                      reporte.personasLocalizadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasLocalizadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasLocalizadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.personasNoLocalizadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas no localizadas (" +
                                      reporte.personasNoLocalizadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasNoLocalizadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasNoLocalizadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.personasAtrapadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas atrapadas (" +
                                      reporte.personasAtrapadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasAtrapadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasAtrapadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.personasLesionadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {reporte.reporte.tipoR.id === 20
                                      ? "Personas intoxicadas (" +
                                        reporte.personasLesionadasN +
                                        "): "
                                      : "Personas lesionadas (" +
                                        reporte.personasLesionadasN +
                                        "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasLesionadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasLesionadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.personasAhogadasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas ahogadas (" +
                                      reporte.personasAhogadasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasAhogadas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasAhogadas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.personasFallecidasN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Personas fallecidas (" +
                                      reporte.personasFallecidasN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.personasFallecidas ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.personasFallecidas}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.serviciosEstrategicosN ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Servicios estratégicos (" +
                                      reporte.serviciosEstrategicosN +
                                      "): "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.serviciosEstrategicos ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.serviciosEstrategicos}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : reporte.serviciosEstrategicos ? (
                            <Col xs="12">
                              <Form.Group as={Row}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>
                                      {"Servicios estratégicos: "}
                                    </strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.serviciosEstrategicos}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : reporte.reporte.tipoR.id === 18 ? (
                            <Col xs="12">
                              <Form.Group as={Row}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>
                                      {"Servicios estratégicos: "}
                                    </strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {"Sin reporte de novedad"}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.cna ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"CNA: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.cna}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : reporte.reporte.tipoR.id === 18 ? (
                            <Col xs="12">
                              <Form.Group as={Row}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>{"CNA: "}</strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {"Sin reporte de novedad"}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.pemex ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"PEMEX: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.pemex}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : reporte.reporte.tipoR.id === 18 ? (
                            <Col xs="12">
                              <Form.Group as={Row}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>{"PEMEX: "}</strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {"Sin reporte de novedad"}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.subcentrosRegionales ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Subcentros regionales: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.subcentrosRegionales}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : reporte.reporte.tipoR.id === 18 ? (
                            <Col xs="12">
                              <Form.Group as={Row}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>{"Subcentros regionales: "}</strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {"Sin reporte de novedad"}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.lagunaVerde ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Laguna verde: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.lagunaVerde}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : reporte.reporte.tipoR.id === 18 ? (
                            <Col xs="12">
                              <Form.Group as={Row}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>{"Laguna verde: "}</strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {"Sin reporte de novedad"}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.secretariaSalud ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Secretaría de salud: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.secretariaSalud}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : reporte.reporte.tipoR.id === 18 ? (
                            <Col xs="12">
                              <Form.Group as={Row}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>{"Secretaría de salud: "}</strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {"Sin reporte de novedad"}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            </Col>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.tunelSumergido ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Túnel sumergido: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.tunelSumergido
                                      ? reporte.tunelSumergido
                                      : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.titulo1 ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{reporte.titulo1 + ": "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.contenido1
                                      ? reporte.contenido1
                                      : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.titulo2 ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{reporte.titulo2 + ": "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.contenido2
                                      ? reporte.contenido2
                                      : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.titulo3 ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{reporte.titulo3 + ": "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.contenido3
                                      ? reporte.contenido3
                                      : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.titulo4 ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{reporte.titulo4 + ": "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.contenido4
                                      ? reporte.contenido4
                                      : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {reporte.reporte.refugio ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Refugio utilizado: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.reporte.refugio.nombre ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.reporte.refugio.nombre}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Capacidad: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  {reporte.reporte.refugio.capacidad ? (
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {reporte.reporte.refugio.capacidad +
                                        " personas"}
                                    </strong>
                                  ) : null}
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {reporte.reporte.fechaActivacion ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Fecha de activación refugio: "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {(new Date(
                                      reporte.reporte.fechaActivacion
                                    ).getDate() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaActivacion
                                      ).getDate() +
                                      "/" +
                                      monthNames[
                                        new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getMonth()
                                      ] +
                                      "/" +
                                      new Date(
                                        reporte.reporte.fechaActivacion
                                      ).getUTCFullYear() +
                                      " - "}
                                    {(new Date(
                                      reporte.reporte.fechaActivacion
                                    ).getHours() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaActivacion
                                      ).getHours() +
                                      ":" +
                                      (new Date(
                                        reporte.reporte.fechaActivacion
                                      ).getMinutes() < 10
                                        ? "0"
                                        : "") +
                                      new Date(
                                        reporte.reporte.fechaActivacion
                                      ).getMinutes()}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.reporte.fechaDesactivacion ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>
                                    {"Fecha de desactivación refugio: "}
                                  </strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {(new Date(
                                      reporte.reporte.fechaDesactivacion
                                    ).getDate() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaDesactivacion
                                      ).getDate() +
                                      "/" +
                                      monthNames[
                                        new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getMonth()
                                      ] +
                                      "/" +
                                      new Date(
                                        reporte.reporte.fechaDesactivacion
                                      ).getUTCFullYear() +
                                      " - "}
                                    {(new Date(
                                      reporte.reporte.fechaDesactivacion
                                    ).getHours() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.reporte.fechaDesactivacion
                                      ).getHours() +
                                      ":" +
                                      (new Date(
                                        reporte.reporte.fechaDesactivacion
                                      ).getMinutes() < 10
                                        ? "0"
                                        : "") +
                                      new Date(
                                        reporte.reporte.fechaDesactivacion
                                      ).getMinutes()}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {reporte.reporte.accionesImplementadas ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Acciones implementadas: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.reporte.accionesImplementadas}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.reporte.notas ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Notas: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.reporte.notas}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.fechaTermino ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong> {"Fecha de término: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {(new Date(reporte.fechaTermino).getDate() <
                                    10
                                      ? "0"
                                      : "") +
                                      new Date(reporte.fechaTermino).getDate() +
                                      "/" +
                                      monthNames[
                                        new Date(
                                          reporte.fechaTermino
                                        ).getMonth()
                                      ] +
                                      "/" +
                                      new Date(
                                        reporte.fechaTermino
                                      ).getUTCFullYear() +
                                      " - "}
                                    {(new Date(
                                      reporte.fechaTermino
                                    ).getHours() < 10
                                      ? "0"
                                      : "") +
                                      new Date(
                                        reporte.fechaTermino
                                      ).getHours() +
                                      ":" +
                                      (new Date(
                                        reporte.fechaTermino
                                      ).getMinutes() < 10
                                        ? "0"
                                        : "") +
                                      new Date(
                                        reporte.fechaTermino
                                      ).getMinutes()}
                                    {reporte.estimado2
                                      ? " aproximadamente"
                                      : null}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                        <Col xs="12">
                          {reporte.reporte.fuerzaTarea ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong> {"Fuerzas de tarea: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong
                                    className={
                                      colors[
                                        reporte.reporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {reporte.reporte.fuerzaTarea}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        {!seguimientos ? null : (
                          <Col xs="12">
                            <hr />
                            {seguimientos.map((seguimiento) => (
                              <Form.Group as={Row} key={seguimiento.id}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>
                                      {"Seguimiento (" +
                                        (new Date(
                                          seguimiento.fechaSeguimiento
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          seguimiento.fechaSeguimiento
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            seguimiento.fechaSeguimiento
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          seguimiento.fechaSeguimiento
                                        ).getUTCFullYear() +
                                        "): "}
                                    </strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          reporte.reporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {seguimiento.seguimiento}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            ))}
                          </Col>
                        )}

                        {this.state.files.length === 0 ? null : (
                          <>
                            <Col xs="12">
                              <hr />
                            </Col>
                            <Col xl="2" md="12"></Col>
                            <Col xl="8" md="12" className="text-center">
                              <h5>
                                <strong className="text-left">
                                  {"Evidencias: "}
                                </strong>
                              </h5>
                              <Carousel
                                interval={8000}
                                className="alert-message"
                              >
                                {this.state.files.map((file) => (
                                  <Carousel.Item key={file.id}>
                                    <img
                                      className="img fluid d-block objectcover w-100"
                                      src={
                                        "https://sicecom.veracruz.gob.mx/api/auth/files/" +
                                        file.name
                                      }
                                      alt={file.name}
                                      height={400}
                                    />
                                    <Carousel.Caption>
                                      <a
                                        href={
                                          "https://sicecom.veracruz.gob.mx/api/auth/files/" +
                                          file.name
                                        }
                                      >
                                        <Button variant="light" className="m-1">
                                          <MdDownload />
                                        </Button>
                                      </a>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                              <p className="nocolor mediumtext">&zwnj;</p>
                            </Col>
                          </>
                        )}
                      </Row>

                      <p className="nocolor mediumtext">&zwnj;</p>

                      <Stack direction="horizontal" gap={3}>
                        <div>
                          <Button
                            variant={
                              outlineButtons[
                                reporte.reporte.tipoR.fenomeno.id - 1
                              ]
                            }
                            className="ms-auto"
                            size="lg"
                            onClick={this.goBack}
                          >
                            <ImArrowLeft2 /> Regresar
                          </Button>
                        </div>

                        <div className="ms-auto">
                          <Link
                            to={
                              this.state.link
                                ? this.state.link + this.props.router.params.id
                                : "#"
                            }
                          >
                            <Button variant={"gold"} size="lg">
                              Seguimiento / Editar <MdDoubleArrow />
                            </Button>
                          </Link>
                        </div>
                        <div>
                          <OverlayTrigger
                            placement="top"
                            show={this.state.copiado}
                            overlay={
                              <Tooltip>
                                Copiado <MdContentCopy />
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="whatsapp"
                              disabled={reporte.reporte.folio ? false : true}
                              size="lg"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  "*" +
                                    (!reporte.reporte.estatus
                                      ? null
                                      : reporte.reporte.estatus.id === 1
                                      ? ""
                                      : reporte.reporte.estatus.estatus +
                                        " A ") +
                                    (reporte.reporte.tipoR.id !== 46
                                      ? reporte.reporte.tipoR
                                        ? reporte.reporte.tipoR.nombreT.toUpperCase()
                                        : "REPORTE"
                                      : reporte.reporte.evento
                                      ? reporte.reporte.evento.toUpperCase()
                                      : "REPORTE") +
                                    (reporte.reporte.municipio
                                      ? " EN " +
                                        reporte.reporte.municipio.municipio.toUpperCase() +
                                        "*"
                                      : reporte.region
                                      ? " EN LA REGIÓN DE " +
                                        reporte.region.region.toUpperCase() +
                                        "*"
                                      : reporte.ubicacionPersonalizada
                                      ? " EN " +
                                        reporte.ubicacionPersonalizada.toUpperCase() +
                                        "*"
                                      : "*") +
                                    "\n" +
                                    (reporte.reporte.localidad
                                      ? "*Localidad:* " +
                                        reporte.reporte.localidad +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.folio
                                      ? "*" + reporte.reporte.folio + "*"
                                      : "") +
                                    "\n\n" +
                                    (reporte.reporte.fechaReporte
                                      ? "*Fecha del reporte:* " +
                                        (new Date(
                                          reporte.reporte.fechaReporte
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaReporte
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            reporte.reporte.fechaReporte
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          reporte.reporte.fechaReporte
                                        ).getUTCFullYear() +
                                        " - " +
                                        (new Date(
                                          reporte.reporte.fechaReporte
                                        ).getHours() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaReporte
                                        ).getHours() +
                                        ":" +
                                        (new Date(
                                          reporte.reporte.fechaReporte
                                        ).getMinutes() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaReporte
                                        ).getMinutes() +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.fechaOcurrencia
                                      ? "*Fecha de ocurrencia:* " +
                                        (new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            reporte.reporte.fechaOcurrencia
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getUTCFullYear() +
                                        " - " +
                                        (new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getHours() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getHours() +
                                        ":" +
                                        (new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getMinutes() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getMinutes() +
                                        (new Date(
                                          reporte.reporte.fechaOcurrencia
                                        ).getSeconds() < 1
                                          ? ""
                                          : new Date(
                                              reporte.reporte.fechaOcurrencia
                                            ).getSeconds() < 10
                                          ? ":0" +
                                            new Date(
                                              reporte.reporte.fechaOcurrencia
                                            ).getSeconds()
                                          : ":" +
                                            new Date(
                                              reporte.reporte.fechaOcurrencia
                                            ).getSeconds()) +
                                        (reporte.reporte.estimado
                                          ? " aproximadamente"
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.evento
                                      ? "*Evento:* " +
                                        reporte.reporte.evento +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.reporta
                                      ? "*Reporta:* " +
                                        reporte.reporte.reporta +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.cargo
                                      ? "*Cargo:* " +
                                        reporte.reporte.cargo +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.latitud &&
                                    reporte.reporte.longitud
                                      ? "*Coordenadas:* " +
                                        reporte.reporte.latitud.toFixed(5) +
                                        "°, " +
                                        reporte.reporte.longitud.toFixed(5) +
                                        "°\n"
                                      : "") +
                                    (reporte.magnitud
                                      ? "*Magnitud:* " + reporte.magnitud + "\n"
                                      : "") +
                                    (reporte.profundidad
                                      ? "*Profundidad:* " +
                                        reporte.profundidad +
                                        " KM\n"
                                      : "") +
                                    (reporte.localizacion
                                      ? "*Localización:* " +
                                        reporte.localizacion +
                                        "\n"
                                      : "") +
                                    (reporte.municipiosPercepcionN
                                      ? "*Municipios con percepción (" +
                                        reporte.municipiosPercepcionN +
                                        "):* " +
                                        (reporte.municipiosPercepcion
                                          ? "\n" + reporte.municipiosPercepcion
                                          : "Sin reporte de percepción") +
                                        "\n"
                                      : "") +
                                    (reporte.inmueblesEvacuadosN
                                      ? "*Inmuebles evacuados (" +
                                        reporte.inmueblesEvacuadosN +
                                        "):* " +
                                        (reporte.inmueblesEvacuados
                                          ? "\n" + reporte.inmueblesEvacuados
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.ubicacion
                                      ? "*Ubicación:* " +
                                        reporte.ubicacion +
                                        "\n"
                                      : "") +
                                    (reporte.ubicacionesN
                                      ? "*Ubicaciones (" +
                                        reporte.ubicacionesN +
                                        "):* " +
                                        (reporte.ubicaciones
                                          ? "\n" + reporte.ubicaciones
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.afectacionesN
                                      ? (reporte.reporte.tipoR.id === 39
                                          ? "*Unidades afectadas (" +
                                            reporte.afectacionesN +
                                            "):* "
                                          : reporte.reporte.tipoR.id === 40
                                          ? "*Locales afectados (" +
                                            reporte.afectacionesN +
                                            "):* "
                                          : reporte.reporte.tipoR.id === 42
                                          ? "*Casas afectadas (" +
                                            reporte.afectacionesN +
                                            "):* "
                                          : reporte.reporte.tipoR.id === 52
                                          ? "*Instalaciones afectadas (" +
                                            reporte.afectacionesN +
                                            "):* "
                                          : "*Superficies afectadas (" +
                                            reporte.afectacionesN +
                                            "):* ") +
                                        (reporte.afectaciones
                                          ? "\n" + reporte.afectaciones
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.sustancias &&
                                    reporte.reporte.tipoR.id !== 36
                                      ? "*Sustancia:* " +
                                        reporte.sustancias +
                                        "\n"
                                      : "") +
                                    (reporte.estatusFuga
                                      ? "*Estatus:* " +
                                        reporte.estatusFuga.estatusFuga +
                                        "\n"
                                      : "") +
                                    (reporte.estatusControl ||
                                    reporte.estatusLiquidacion
                                      ? "*Estatus del incendio:* " +
                                        ((reporte.estatusControl
                                          ? reporte.estatusControl
                                              .estatusControl
                                          : "") +
                                          (reporte.estatusControl &&
                                          reporte.estatusLiquidacion
                                            ? ", "
                                            : "") +
                                          (reporte.estatusLiquidacion
                                            ? reporte.estatusLiquidacion
                                                .estatusLiquidacion
                                            : "")) +
                                        "\n"
                                      : "") +
                                    (reporte.viasAlternas
                                      ? "*Vías de comunicación alternas:* " +
                                        reporte.viasAlternas +
                                        "\n"
                                      : "") +
                                    (reporte.localidadesAfectadasN
                                      ? "*Localidades afectadas (" +
                                        reporte.localidadesAfectadasN +
                                        "):* " +
                                        (reporte.localidadesAfectadas
                                          ? "\n" + reporte.localidadesAfectadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.localidadesIncomunicadasN
                                      ? "*Localidades incomunicadas (" +
                                        reporte.localidadesIncomunicadasN +
                                        "):* " +
                                        (reporte.localidadesIncomunicadas
                                          ? "\n" +
                                            reporte.localidadesIncomunicadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.localidadesConAccesoN
                                      ? "*Localidades con acceso (" +
                                        reporte.localidadesConAccesoN +
                                        "):* " +
                                        (reporte.localidadesConAcceso
                                          ? "\n" + reporte.localidadesConAcceso
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.viviendasAN
                                      ? "*Viviendas afectadas (" +
                                        reporte.viviendasAN +
                                        "):* " +
                                        (reporte.viviendasA
                                          ? "\n" + reporte.viviendasA
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.coloniasAN
                                      ? "*Colonias afectadas (" +
                                        reporte.coloniasAN +
                                        "):* " +
                                        (reporte.coloniasA
                                          ? "\n" + reporte.coloniasA
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.comunidadesAN
                                      ? "*Comunidades afectadas (" +
                                        reporte.comunidadesAN +
                                        "):* " +
                                        (reporte.comunidadesA
                                          ? "\n" + reporte.comunidadesA
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.caidaArbolesN
                                      ? "*Árboles caídos (" +
                                        reporte.caidaArbolesN +
                                        "):* " +
                                        (reporte.caidaArboles
                                          ? "\n" + reporte.caidaArboles
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.caidaPostesN
                                      ? "*Postes caídos (" +
                                        reporte.caidaPostesN +
                                        "):* " +
                                        (reporte.caidaPostes
                                          ? "\n" + reporte.caidaPostes
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.caidaEspectacularesN
                                      ? "*Espectaculares caídos (" +
                                        reporte.caidaEspectacularesN +
                                        "):* " +
                                        (reporte.caidaEspectaculares
                                          ? "\n" + reporte.caidaEspectaculares
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.viasAN
                                      ? "*Vías afectadas (" +
                                        reporte.viasAN +
                                        "):* " +
                                        (reporte.viasA
                                          ? "\n" + reporte.viasA
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.construccionesAN
                                      ? "*Construcciones afectadas (" +
                                        reporte.construccionesAN +
                                        "):* " +
                                        (reporte.construccionesA
                                          ? "\n" + reporte.construccionesA
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.puentesAN
                                      ? "*Puentes afectados (" +
                                        reporte.puentesAN +
                                        "):* " +
                                        (reporte.puentesA
                                          ? "\n" + reporte.puentesA
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.escuelasAN
                                      ? "*Escuelas afectadas (" +
                                        reporte.escuelasAN +
                                        "):* " +
                                        (reporte.escuelasA
                                          ? "\n" + reporte.escuelasA
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.inconformidad
                                      ? "*Inconformidad:* " +
                                        reporte.inconformidad +
                                        "\n"
                                      : "") +
                                    (reporte.afluenciaN
                                      ? "*Afluencia:* " +
                                        reporte.afluenciaN +
                                        " personas \n"
                                      : "") +
                                    (reporte.atencionesMedicas
                                      ? "*Atenciones médicas:* " +
                                        "\n" +
                                        reporte.atencionesMedicas +
                                        "\n"
                                      : "") +
                                    (reporte.unidadesInvolucradasN
                                      ? "*Unidades involucradas (" +
                                        reporte.unidadesInvolucradasN +
                                        "):* " +
                                        (reporte.unidadesInvolucradas
                                          ? "\n" + reporte.unidadesInvolucradas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasEvacuadasN
                                      ? "*Personas evacuadas (" +
                                        reporte.personasEvacuadasN +
                                        "):* " +
                                        (reporte.personasEvacuadas
                                          ? "\n" + reporte.personasEvacuadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasRefugiadasN
                                      ? "*Personas refugiadas (" +
                                        reporte.personasRefugiadasN +
                                        "):* " +
                                        (reporte.personasRefugiadas
                                          ? "\n" + reporte.personasRefugiadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasRescatadasN
                                      ? "*Personas rescatadas (" +
                                        reporte.personasRescatadasN +
                                        "):* " +
                                        (reporte.personasRescatadas
                                          ? "\n" + reporte.personasRescatadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasLocalizadasN
                                      ? "*Personas localizadas (" +
                                        reporte.personasLocalizadasN +
                                        "):* " +
                                        (reporte.personasLocalizadas
                                          ? "\n" + reporte.personasLocalizadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasNoLocalizadasN
                                      ? "*Personas no localizadas (" +
                                        reporte.personasNoLocalizadasN +
                                        "):* " +
                                        (reporte.personasNoLocalizadas
                                          ? "\n" + reporte.personasNoLocalizadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasAtrapadasN
                                      ? "*Personas atrapadas (" +
                                        reporte.personasAtrapadasN +
                                        "):* " +
                                        (reporte.personasAtrapadas
                                          ? "\n" + reporte.personasAtrapadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasLesionadasN
                                      ? (reporte.reporte.tipoR.id === 20
                                          ? "*Personas intoxicadas (" +
                                            reporte.personasLesionadasN +
                                            "):* "
                                          : "*Personas lesionadas (" +
                                            reporte.personasLesionadasN +
                                            "):* ") +
                                        (reporte.personasLesionadas
                                          ? "\n" + reporte.personasLesionadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasAhogadasN
                                      ? "*Personas ahogadas (" +
                                        reporte.personasAhogadasN +
                                        "):* " +
                                        (reporte.personasAhogadas
                                          ? "\n" + reporte.personasAhogadas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.personasFallecidasN
                                      ? "*Personas fallecidas (" +
                                        reporte.personasFallecidasN +
                                        "):* " +
                                        (reporte.personasFallecidas
                                          ? "\n" + reporte.personasFallecidas
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.serviciosEstrategicosN
                                      ? "*Servicios estratégicos (" +
                                        reporte.serviciosEstrategicosN +
                                        "):* " +
                                        (reporte.serviciosEstrategicos
                                          ? "\n" + reporte.serviciosEstrategicos
                                          : "") +
                                        "\n"
                                      : reporte.serviciosEstrategicos
                                      ? "*Servicios estratégicos:* \n" +
                                        reporte.serviciosEstrategicos +
                                        "\n"
                                      : reporte.reporte.tipoR.id === 18
                                      ? "*Servicios estratégicos:* \nSin reporte de novedad\n"
                                      : "") +
                                    (reporte.cna
                                      ? "*CNA:* \n" + reporte.cna + "\n"
                                      : reporte.reporte.tipoR.id === 18
                                      ? "*CNA:* \nSin reporte de novedad\n"
                                      : "") +
                                    (reporte.pemex
                                      ? "*PEMEX:* \n" + reporte.pemex + "\n"
                                      : reporte.reporte.tipoR.id === 18
                                      ? "*PEMEX:* \nSin reporte de novedad\n"
                                      : "") +
                                    (reporte.subcentrosRegionales
                                      ? "*Subcentros regionales:* \n" +
                                        reporte.subcentrosRegionales +
                                        "\n"
                                      : reporte.reporte.tipoR.id === 18
                                      ? "*Subcentros regionales:* \nSin reporte de novedad\n"
                                      : "") +
                                    (reporte.lagunaVerde
                                      ? "*Laguna verde:* \n" +
                                        reporte.lagunaVerde +
                                        "\n"
                                      : reporte.reporte.tipoR.id === 18
                                      ? "*Laguna verde:* \nSin reporte de novedad\n"
                                      : "") +
                                    (reporte.secretariaSalud
                                      ? "*Secretaría de salud:* \n" +
                                        reporte.secretariaSalud +
                                        "\n"
                                      : reporte.reporte.tipoR.id === 18
                                      ? "*Secretaría de salud:* \nSin reporte de novedad\n"
                                      : "") +
                                    (reporte.tunelSumergido
                                      ? "*Túnel sumergido:* " +
                                        "\n" +
                                        reporte.tunelSumergido +
                                        "\n"
                                      : "") +
                                    (reporte.titulo1
                                      ? "*" +
                                        reporte.titulo1 +
                                        ":* " +
                                        (reporte.contenido1
                                          ? "\n" + reporte.contenido1 + "\n"
                                          : "\n")
                                      : "") +
                                    (reporte.titulo2
                                      ? "*" +
                                        reporte.titulo2 +
                                        ":* " +
                                        (reporte.contenido2
                                          ? "\n" + reporte.contenido2 + "\n"
                                          : "\n")
                                      : "") +
                                    (reporte.titulo3
                                      ? "*" +
                                        reporte.titulo3 +
                                        ":* " +
                                        (reporte.contenido3
                                          ? "\n" + reporte.contenido3 + "\n"
                                          : "\n")
                                      : "") +
                                    (reporte.titulo4
                                      ? "*" +
                                        reporte.titulo4 +
                                        ":* " +
                                        (reporte.contenido4
                                          ? "\n" + reporte.contenido4 + "\n"
                                          : "\n")
                                      : "") +
                                    (reporte.reporte.refugio
                                      ? "*Refugio utilizado:* " +
                                        (reporte.reporte.refugio.nombre
                                          ? reporte.reporte.refugio.nombre
                                          : "") +
                                        "\n*Capacidad:* " +
                                        (reporte.reporte.refugio.capacidad
                                          ? reporte.reporte.refugio.capacidad +
                                            " personas \n"
                                          : "")
                                      : "") +
                                    (reporte.reporte.fechaActivacion
                                      ? "*Fecha de activación refugio:* " +
                                        (new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            reporte.reporte.fechaActivacion
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getUTCFullYear() +
                                        " - " +
                                        (new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getHours() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getHours() +
                                        ":" +
                                        (new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getMinutes() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaActivacion
                                        ).getMinutes() +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.fechaDesactivacion
                                      ? "*Fecha de desactivación refugio:* " +
                                        (new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            reporte.reporte.fechaDesactivacion
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getUTCFullYear() +
                                        " - " +
                                        (new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getHours() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getHours() +
                                        ":" +
                                        (new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getMinutes() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.reporte.fechaDesactivacion
                                        ).getMinutes() +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.accionesImplementadas
                                      ? "*Acciones implementadas:* \n" +
                                        reporte.reporte.accionesImplementadas +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.notas
                                      ? "*Notas:* \n" +
                                        reporte.reporte.notas +
                                        "\n"
                                      : "") +
                                    (reporte.fechaTermino
                                      ? "*Fecha de término:* " +
                                        (new Date(
                                          reporte.fechaTermino
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.fechaTermino
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            reporte.fechaTermino
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          reporte.fechaTermino
                                        ).getUTCFullYear() +
                                        " - " +
                                        (new Date(
                                          reporte.fechaTermino
                                        ).getHours() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.fechaTermino
                                        ).getHours() +
                                        ":" +
                                        (new Date(
                                          reporte.fechaTermino
                                        ).getMinutes() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          reporte.fechaTermino
                                        ).getMinutes() +
                                        (reporte.estimado2
                                          ? " aproximadamente"
                                          : "") +
                                        "\n"
                                      : "") +
                                    (reporte.reporte.fuerzaTarea
                                      ? "*Fuerzas de tarea:* \n" +
                                        reporte.reporte.fuerzaTarea +
                                        "\n"
                                      : "") +
                                    (!seguimientos
                                      ? ""
                                      : seguimientos
                                          .map(
                                            (seguimiento) =>
                                              "*Seguimiento (" +
                                              (new Date(
                                                seguimiento.fechaSeguimiento
                                              ).getDate() < 10
                                                ? "0"
                                                : "") +
                                              new Date(
                                                seguimiento.fechaSeguimiento
                                              ).getDate() +
                                              "/" +
                                              monthNames[
                                                new Date(
                                                  seguimiento.fechaSeguimiento
                                                ).getMonth()
                                              ] +
                                              "/" +
                                              new Date(
                                                seguimiento.fechaSeguimiento
                                              ).getUTCFullYear() +
                                              "):*\n" +
                                              seguimiento.seguimiento +
                                              "\n"
                                          )
                                          .join(""))
                                );
                                this.onChangeCopiado();
                              }}
                            >
                              <b>Copiar reporte</b> <BsWhatsapp />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </Stack>
                    </div>
                  </Alert>
                )}

                {this.state.messageError ? (
                  <Alert variant={"error"} className="text-center">
                    <Alert.Heading>
                      <strong>ERROR: </strong>
                    </Alert.Heading>
                    <div className="text-center">{this.state.messageError}</div>
                  </Alert>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(ReporteView);
