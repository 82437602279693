import axios from "axios";
import authHeader from "./AuthHeader";
const API_URL = "https://sicecom.veracruz.gob.mx/api/auth";

class MunicipioService {
  getMunicipio(id) {
    return axios.get(API_URL + `/municipio/id/${id}`, {
      headers: authHeader(),
    });
  }

  getMunicipios() {
    return axios.get(API_URL + `/municipio`, { headers: authHeader() });
  }

  getMunicipiosRegion(region) {
    return axios.get(API_URL + `/municipio/${region}`, {
      headers: authHeader(),
    });
  }

  getRegion(id) {
    return axios.get(API_URL + `/region/${id}`, {
      headers: authHeader(),
    });
  }

  getRegiones() {
    return axios.get(API_URL + `/region`, { headers: authHeader() });
  }

  getLocalidad(municipio) {
    return axios.get(API_URL + `/localidad/${municipio}`, {
      headers: authHeader(),
    });
  }
}

export default new MunicipioService();
