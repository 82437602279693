import "./bars.scss";
import React from "react";

const Navbar = () => {
  return (
    <>
      <p className="nocolor mediumtext">&zwnj;</p>
    </>
  );
};

export default Navbar;
