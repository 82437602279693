import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Form from "react-validation/build/form";
import EventBus from "../../common/EventBus";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/AuthService";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import Navbar from "../../bars/Navbar";
import { BsFillPersonPlusFill } from "react-icons/bs";
import UserService from "../../services/UserService";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-error" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

const vnombre = (value) => {
  if (value.length < 2 || value.length > 32) {
    return (
      <div className="alert alert-error" role="alert">
        El nombre debe tener mínimo 2 caracteres.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value !== "" && (value.length < 6 || value.length > 64)) {
    return (
      <div className="alert alert-error" role="alert">
        La contraseña debe tener mínimo 6 caracteres.
      </div>
    );
  }
};

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.getUser = this.getUser.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeApellidoP = this.onChangeApellidoP.bind(this);
    this.onChangeApellidoM = this.onChangeApellidoM.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);

    this.state = {
      showAdminBoard: false,

      nombre: "",
      apellidoP: "",
      apellidoM: "",
      user: "",
      password: "",
      successful: false,
      message: "",

      roles: [],

      userReady: false,
      currentUser: {
        username: "",
      },
    };
  }

  onChangeNombre(e) {
    this.setState({
      nombre: e.target.value,
    });
  }

  onChangeApellidoP(e) {
    this.setState({
      apellidoP: e.target.value,
    });
  }

  onChangeApellidoM(e) {
    this.setState({
      apellidoM: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeUser(e) {
    this.setState({
      user: e.target.value,
    });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      this.getUser();

      this.setState({
        currentUser: currentUser.username,
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  getUser() {
    UserService.getUser(this.props.router.params.id)
      .then((response) => {
        this.setState({
          cargandoReporte: false,
          nombre: response.data.nombre,
          apellidoP: response.data.apellidoP,
          apellidoM: response.data.apellidoM,
          user: response.data.username,

          roles: response.data.roles,
        });
        document.title = "SICECOM - Editar usuario " + response.data.user;
      })
      .catch((err) => {
        console.log(err);
        document.title = "SICECOM - Usuario ERROR";

        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
          err = "Usuario no identificado";

          this.setState({
            messageError: "Error en la autentificación del usuario: " + err,
          });
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 404) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/users");
        } else {
          this.setState({
            cargandoReporte: false,
            messageError: "Error en la carga del usuario: " + err,
          });
        }
      });
  }

  handleEdit(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      const data = {
        nombre:
          this.state.nombre.charAt(0).toUpperCase() +
          this.state.nombre.slice(1),
        apellidoP:
          this.state.apellidoP.charAt(0).toUpperCase() +
          this.state.apellidoP.slice(1),
        apellidoM:
          this.state.apellidoM.charAt(0).toUpperCase() +
          this.state.apellidoM.slice(1),
        username: this.state.user,

        password: this.state.password,

        roles: this.state.roles,
      };

      UserService.editUser(this.props.router.params.id, data).then(
        (response) => {
          this.setState({
            message: "Usuario editado",
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: "ERROR: " + resMessage,
          });
        }
      );
    }
  }

  render() {
    const { showAdminBoard } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />

          {showAdminBoard ? (
            <div className="content w-100 cont">
              <Navbar />
              <div className="col-md-12">
                <div className="card card-size">
                  <h3 className="text-center">
                    <b>EDITAR USUARIO</b>
                  </h3>
                  <h2 className={"text-center"}>
                    <BsFillPersonPlusFill />
                  </h2>

                  <Form
                    onSubmit={this.handleEdit}
                    ref={(c) => {
                      this.form = c;
                    }}
                  >
                    {!this.state.successful && (
                      <div>
                        <div className="form-group">
                          <label htmlFor="nombre">
                            <strong>Nombre:</strong>
                          </label>
                          <Input
                            type="text"
                            name="nombre"
                            value={this.state.nombre ? this.state.nombre : ""}
                            onChange={this.onChangeNombre}
                            validations={[required, vnombre]}
                            maxLength="32"
                            className="form-control"
                            autoFocus
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="apellidoP">
                            <strong>Apellido paterno:</strong>
                          </label>
                          <Input
                            type="text"
                            name="apellidoP"
                            value={
                              this.state.apellidoP ? this.state.apellidoP : ""
                            }
                            onChange={this.onChangeApellidoP}
                            maxLength="32"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="apellidoM">
                            <strong>Apellido materno:</strong>
                          </label>
                          <Input
                            type="text"
                            name="apellidoP"
                            value={
                              this.state.apellidoM ? this.state.apellidoM : ""
                            }
                            onChange={this.onChangeApellidoM}
                            maxLength="32"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="user">
                            <strong>Usuario:</strong>
                          </label>
                          <Input
                            type="text"
                            name="user"
                            value={this.state.user ? this.state.user : ""}
                            onChange={this.onChangeUser}
                            maxLength="34"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="password">
                            <strong>Contraseña:</strong>
                          </label>
                          <Input
                            type="password"
                            name="password"
                            autoComplete="off"
                            value={
                              this.state.password ? this.state.password : ""
                            }
                            placeholder="[SIN CAMBIOS]"
                            onChange={this.onChangePassword}
                            validations={[vpassword]}
                            maxLength="64"
                            className="form-control"
                          />
                        </div>
                        <br />
                        <div className="form-group centered">
                          <button className="btn btn-dark btn-block">
                            Guardar
                          </button>
                        </div>
                        <p className="nocolor mediumtext">&zwnj;</p>
                      </div>
                    )}

                    <div className="centered">
                      <CheckButton
                        className="centered invisible"
                        style={{ display: "" }}
                        ref={(c) => {
                          this.checkBtn = c;
                        }}
                      />
                    </div>

                    {this.state.message && (
                      <>
                        {this.state.successful ? (
                          <div className="form-group">
                            <div className="alert alert-success" role="alert">
                              <div className="text-center">
                                <h4>
                                  <b>{this.state.message}</b>
                                </h4>
                                <hr />
                                <strong>Usuario: </strong>
                                {this.state.user}
                                <br />
                                <strong>Contraseña: </strong>
                                {this.state.password
                                  ? this.state.password
                                  : "[SIN CAMBIOS]"}
                                <hr />
                                <h5>
                                  ¡Asegurese de guardar sus datos en un lugar
                                  seguro!
                                </h5>
                              </div>
                            </div>

                            <NavLink to={"/users"}>
                              <div className="form-group centered">
                                <button className="btn btn-dark btn-block">
                                  Regresar
                                </button>
                              </div>
                            </NavLink>
                          </div>
                        ) : (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              <div className="text-center">
                                <b>{this.state.message}</b>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          ) : (
            <h3 className="content w-100 cont">
              <strong>USUARIO NO PERMITIDO</strong>
            </h3>
          )}
        </div>
      </>
    );
  }
}

export default WithRouter(EditUser);
