import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBus from "../../common/EventBus";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import { Alert, Row, Col, Form, Stack, Button, Badge } from "react-bootstrap";
import { ImArrowLeft2, ImArrowRight2 } from "react-icons/im";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { FaExclamationTriangle } from "react-icons/fa";

import ReporteService from "../../services/ReporteService";
import AuthService from "../../services/AuthService";

const badge = (
  <Badge bg="secondary">
    Falta este campo por llenar <FaExclamationTriangle />
  </Badge>
);

class ReporteAdd extends Component {
  constructor(props) {
    super(props);
    this.onChangeFenomeno = this.onChangeFenomeno.bind(this);
    this.onChangeTipo = this.onChangeTipo.bind(this);
    this.getFenomenos = this.getFenomenos.bind(this);
    this.getTipos = this.getTipos.bind(this);

    this.state = {
      fenomenos: [],
      tipos: [],

      fenomeno: null,
      tipo: null,

      link: null,

      camposFaltantes: true,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Agregar reporte";

      this.getFenomenos();
    }
  }

  onChangeFenomeno(e) {
    this.getTipos(e.target.value);
    this.setState({
      tipo: null,
      tipos: [],
      fenomeno: e.target.value,
    });
  }

  onChangeTipo(e) {
    this.getTipos(this.state.fenomeno);
    this.setState(
      {
        tipo: e.target.value,
      },
      () => {
        switch (e.target.value) {
          //HIDRO_ABCDEF
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
            this.setState({
              link: "/addHidA/",
            });
            break;

          //HIDRO_GHI
          case "7":
          case "8":
          case "9":
            this.setState({
              link: "/addHidG/",
            });
            break;

          //HIDRO_JKL
          case "10":
          case "11":
          case "12":
            this.setState({
              link: "/addHidJ/",
            });
            break;

          //GEO_ABCDE
          case "13":
          case "14":
          case "15":
          case "16":
          case "17":
            this.setState({
              link: "/addGeoA/",
            });
            break;

          //GEO_F
          case "18":
            this.setState({
              link: "/addGeoF/",
            });
            break;

          //ECO_AB
          case "19":
          case "20":
            this.setState({
              link: "/addEcoA/",
            });
            break;

          //ORG_ABC
          case "21":
          case "22":
          case "23":
            this.setState({
              link: "/addOrgA/",
            });
            break;

          //ORG_DEFGHI
          case "24":
          case "25":
          case "26":
          case "27":
          case "28":
          case "29":
            this.setState({
              link: "/addOrgD/",
            });
            break;

          //ORG_JKLMNO
          case "30":
          case "31":
          case "32":
          case "33":
          case "34":
          case "35":
            this.setState({
              link: "/addOrgJ/",
            });
            break;

          //ORG_P
          case "50":
            this.setState({
              link: "/addOrgP/",
            });
            break;

          //QUITEC_ABC
          case "36":
          case "37":
          case "38":
            this.setState({
              link: "/addQuiA/",
            });
            break;

          //QUITEC_DEFGHIJKL
          case "39":
          case "40":
          case "41":
          case "42":
          case "43":
          case "44":
          case "45":
          case "51":
          case "52":
            this.setState({
              link: "/addQuiD/",
            });
            break;

          //PER_A
          case "46":
            this.setState({
              link: "/addPerA/",
            });
            break;

          //ASTRO_ABC
          case "47":
          case "48":
          case "49":
            this.setState({
              link: "/addAstA/",
            });
            break;

          default:
            this.setState({
              link: "#",
            });
            break;
        }
      }
    );
  }

  getFenomenos() {
    ReporteService.getFenomenos()
      .then((response) => {
        this.setState({
          fenomenos: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getTipos(fenomeno) {
    ReporteService.getTipo(fenomeno)
      .then((response) => {
        this.setState({
          tipos: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  handleKeypress = () => {
    if (!this.state.tipo) {
      this.setState({
        camposFaltantes: true,
      });
    } else {
      this.setState({
        camposFaltantes: false,
      });
    }
  };

  goBack = () => {
    this.props.router.navigate(-1);
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  render() {
    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                <p className="nocolor bigtext">&zwnj;</p>

                <Alert variant="secondary">
                  <div className="m-1 d-grid gap-2 d-md-block">
                    <h2 className="text-center text-dark">
                      <b>AGREGAR REPORTE </b> <BsFillFileEarmarkPlusFill />
                    </h2>
                    <Row>
                      <p className="nocolor mediumtext">&zwnj;</p>

                      <Col xs="12">
                        <Form.Group as={Row} className="mb-1">
                          <Col sm="1"></Col>
                          <Col sm="10">
                            <h4>
                              <strong className="text-secondary">
                                <i>
                                  Seleccione el fenómeno y el tipo del reporte:
                                </i>
                              </strong>
                            </h4>
                          </Col>
                          <Col sm="1"></Col>
                        </Form.Group>
                      </Col>

                      <Col xl="6" sm="12">
                        <Form.Group
                          as={Row}
                          className="mb-1"
                          controlId="fenomeno"
                        >
                          <Col sm="1"></Col>
                          <Col sm="10">
                            <Form.Label>
                              <h4>
                                <strong>Fenómeno:</strong>
                              </h4>
                            </Form.Label>
                            <Form.Select
                              value={
                                this.state.fenomeno == null
                                  ? ""
                                  : this.state.fenomeno
                              }
                              onClick={this.getFenomenos}
                              onChange={this.onChangeFenomeno}
                              onMouseLeave={this.handleKeypress}
                              size="lg"
                            >
                              <option defaultValue="" hidden>
                                Seleccione el fenomeno...
                              </option>
                              {this.state.fenomenos.map((fenomeno) => (
                                <option key={fenomeno.id} value={fenomeno.id}>
                                  {fenomeno.nombreF}
                                </option>
                              ))}
                            </Form.Select>
                            <Alert
                              variant={"nocolor"}
                              show={this.state.fenomeno ? false : true}
                            >
                              {badge}
                            </Alert>
                          </Col>
                          <Col sm="1"></Col>
                        </Form.Group>
                      </Col>

                      <Col xl="6" sm="12">
                        <Form.Group as={Row} className="mb-1" controlId="tipo">
                          <Col sm="1"></Col>
                          <Col sm="10">
                            <Form.Label>
                              <h4>
                                <strong>Tipo de reporte:</strong>
                              </h4>
                            </Form.Label>

                            <Form.Select
                              value={
                                this.state.tipo == null ? "" : this.state.tipo
                              }
                              onChange={this.onChangeTipo}
                              onMouseLeave={this.handleKeypress}
                              size="lg"
                            >
                              <option defaultValue="" hidden>
                                Seleccione el tipo de reporte...
                              </option>
                              {this.state.tipos.map((tipo, index) => (
                                <option key={tipo.id} value={tipo.id}>
                                  {tipo.nombreT}
                                </option>
                              ))}
                            </Form.Select>
                            <Alert
                              variant={"nocolor"}
                              show={this.state.tipo ? false : true}
                            >
                              {badge}
                            </Alert>
                          </Col>
                          <Col sm="1"></Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <p className="nocolor bigtext">&zwnj;</p>

                    <Stack direction="horizontal" gap={3}>
                      <div>
                        <Button
                          variant="outline-secondary"
                          className="ms-auto"
                          size="lg"
                          onClick={this.goBack}
                        >
                          <ImArrowLeft2 /> Regresar
                        </Button>
                      </div>

                      <div
                        className="ms-auto"
                        onMouseOver={this.handleKeypress}
                      >
                        <Link
                          to={this.state.link + this.state.tipo}
                          className={
                            this.state.camposFaltantes ? "disabled-link" : ""
                          }
                        >
                          <Button
                            variant="brown"
                            disabled={this.state.camposFaltantes}
                            className="m-2"
                            size="lg"
                          >
                            <b>Continuar</b> <ImArrowRight2 />
                          </Button>
                        </Link>
                      </div>
                    </Stack>
                  </div>
                </Alert>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(ReporteAdd);
