import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBus from "../../common/EventBus";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import {
  Alert,
  Row,
  Col,
  Form,
  Stack,
  Button,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  FormControl,
  Fade,
  Modal,
  Spinner,
  Badge,
  Popover,
} from "react-bootstrap";
import { ImArrowLeft2, ImHome, ImCross } from "react-icons/im";
import { BsCheckLg, BsEyeFill } from "react-icons/bs";
import {
  FaPaste,
  FaMapMarkerAlt,
  FaQuestionCircle,
  FaExclamationTriangle,
  FaCheckCircle,
} from "react-icons/fa";

import ReporteService from "../../services/ReporteService";
import MunicipioService from "../../services/MunicipioService";
import AuthService from "../../services/AuthService";

const badge = (
  <Badge bg="refugio">
    Falta este campo por llenar <FaExclamationTriangle />
  </Badge>
);

class Refugio extends Component {
  constructor(props) {
    super(props);
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
    this.onChangeDireccion = this.onChangeDireccion.bind(this);
    this.onChangeCapacidad = this.onChangeCapacidad.bind(this);
    this.onChangeEstatus = this.onChangeEstatus.bind(this);
    this.updateRefugio = this.updateRefugio.bind(this);
    this.deleteRefugio = this.deleteRefugio.bind(this);
    this.getRefugio = this.getRefugio.bind(this);
    this.getMunicipios = this.getMunicipios.bind(this);
    this.onChangeLatitud = this.onChangeLatitud.bind(this);
    this.onChangeLongitud = this.onChangeLongitud.bind(this);
    this.pasteLatitudLongitud = this.pasteLatitudLongitud.bind(this);
    this.errPegado = this.errPegado.bind(this);

    this.openModalActualizar = this.openModalActualizar.bind(this);
    this.openModalBorrar = this.openModalBorrar.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      municipios: [],

      showAdminBoard: false,
      showModeratorBoard: false,

      currentRefugio: {
        id: null,
        nombre: null,
        latitud: null,
        longitud: null,
        direccion: null,
        capacidad: null,
        estatus: null,

        municipio: {
          id: null,
          municipio: null,
          region: {
            id: null,
            region: null,
          },
        },
      },

      camposFaltantes: false,
      message: null,
      fileInfos: null,

      errPegado: false,
      posicionLa: false,
      posicionLo: false,
      cargandoRefugio: true,

      open: false,
      isOpen: false,
      isBorrar: false,
      isActualizar: false,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Editar refugio";

      this.getRefugio(this.props.router.params.id);

      this.setState({
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
        showModeratorBoard: currentUser.roles.includes("ROLE_MODERATOR"),
      });

      setTimeout(() => {
        this.handlePosicion();
      }, 50);
    }
  }

  onChangeNombre(e) {
    const nombre = e.target.value;

    this.setState(function (prevState) {
      return {
        currentRefugio: {
          ...prevState.currentRefugio,
          nombre: nombre,
        },
      };
    });
  }

  onChangeLatitud(e) {
    const latitud = e.target.value.replace(/[^\d.]/g, "");

    this.setState(function (prevState) {
      return {
        currentRefugio: {
          ...prevState.currentRefugio,
          latitud: latitud,
        },
      };
    });
  }

  onChangeLongitud(e) {
    const longitud = e.target.value.replace(/[^\d.]/g, "");

    this.setState(function (prevState) {
      return {
        currentRefugio: {
          ...prevState.currentRefugio,
          longitud: longitud,
        },
      };
    });
  }

  pasteLatitudLongitud() {
    navigator.clipboard
      .readText()
      .then((response) => {
        var paste = response.split(", -");

        if (paste.length > 1) {
          this.setState(function (prevState) {
            setTimeout(() => {
              this.handlePosicion();
            }, 20);
            return {
              currentRefugio: {
                ...prevState.currentRefugio,
                latitud: paste[0].slice(0, 13),
                longitud: paste[1].slice(0, 13),
              },
            };
          });
        } else {
          this.errPegado();
        }
      })
      .catch((err) => console.log(err));
  }

  errPegado() {
    this.setState({
      errPegado: true,
    });

    setTimeout(() => {
      this.setState({
        errPegado: false,
      });
    }, 1500);
  }

  onChangeMunicipio(e) {
    this.setState({
      municipioN: e.target.value,
    });
  }

  onChangeDireccion(e) {
    const direccion = e.target.value;

    this.setState(function (prevState) {
      return {
        currentRefugio: {
          ...prevState.currentRefugio,
          direccion: direccion,
        },
      };
    });
  }

  onChangeCapacidad(e) {
    const capacidad = e.target.value.replace(/[^0-9]/gi, "");

    this.setState(function (prevState) {
      return {
        currentRefugio: {
          ...prevState.currentRefugio,
          capacidad: capacidad,
        },
      };
    });
  }

  onChangeEstatus() {
    if (this.state.currentRefugio.estatus) {
      this.setState(function (prevState) {
        return {
          currentRefugio: {
            ...prevState.currentRefugio,
            estatus: false,
          },
        };
      });
    } else {
      this.setState(function (prevState) {
        return {
          currentRefugio: {
            ...prevState.currentRefugio,
            estatus: true,
          },
        };
      });
    }
  }

  getRefugio(id) {
    ReporteService.getRefugio(id)
      .then((response) => {
        this.setState({
          cargandoRefugio: false,
          currentRefugio: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 404) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/refugiosList");
        } else {
          this.setState({
            cargandoRefugio: false,
          });
          console.log(err);
        }
      });
  }

  getMunicipios() {
    MunicipioService.getMunicipios()
      .then((response) => {
        this.setState({
          municipios: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  handlePosicion = () => {
    if (
      this.state.currentRefugio.latitud < 22.5 &&
      this.state.currentRefugio.latitud > 17.12
    ) {
      this.setState({
        posicionLa: true,
      });
    } else {
      this.setState({
        posicionLa: false,
      });
    }
    if (
      this.state.currentRefugio.longitud < 98.71 &&
      Math.abs(this.state.currentRefugio.longitud) > 93.59
    ) {
      this.setState({
        posicionLo: true,
      });
    } else {
      this.setState({
        posicionLo: false,
      });
    }

    setTimeout(() => {
      this.handleKeypress();
    }, 50);
  };

  handleKeypress = () => {
    if (
      this.state.currentRefugio.nombre &&
      (this.state.posicionLa || !this.state.currentRefugio.latitud) &&
      (this.state.posicionLo || !this.state.currentRefugio.longitud) &&
      this.state.currentRefugio.direccion &&
      this.state.currentRefugio.capacidad
    ) {
      this.setState({
        camposFaltantes: false,
      });
    } else {
      this.setState({
        camposFaltantes: true,
      });
    }
  };

  openModalActualizar() {
    this.setState({
      isOpen: true,
      isActualizar: true,
      isBorrar: false,
    });
  }

  openModalBorrar() {
    this.setState({
      isOpen: true,
      isBorrar: true,
      isActualizar: false,
    });
  }

  closeModal() {
    this.setState({
      isOpen: false,
      isActualizar: false,
      isBorrar: false,
    });
  }

  setOpen() {
    this.setState({
      open: true,
    });
  }

  setClose() {
    this.setState({
      open: false,
    });
  }

  updateRefugio() {
    this.setState({
      message: "",
      open: false,
      isOpen: false,
    });

    var data = {
      id: this.state.currentRefugio.id,
      nombre:
        this.state.currentRefugio.nombre.charAt(0).toUpperCase() +
        this.state.currentRefugio.nombre.slice(1),
      latitud: this.state.currentRefugio.latitud,
      longitud: this.state.currentRefugio.longitud
        ? this.state.currentRefugio.longitud < 0
          ? this.state.currentRefugio.longitud
          : "-" + this.state.currentRefugio.longitud
        : null,
      direccion: this.state.currentRefugio.direccion,
      capacidad: this.state.currentRefugio.capacidad,
      estatus: this.state.currentRefugio.estatus,
    };

    if (this.state.municipioN) {
      data.municipio = this.state.municipios[this.state.municipioN];
    } else {
      data.municipio = this.state.currentRefugio.municipio;
    }

    ReporteService.updateRefugio(this.state.currentRefugio.id, data)
      .then(() => {
        this.setState({
          error: false,
          message: "Refugio actualizado correctamente",
          open: true,
          selectedFiles: null,
        });
        this.getRefugio(this.props.router.params.id);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              error: true,
              message: err,
              open: true,
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
        }
      });
  }

  deleteRefugio() {
    ReporteService.deleteRefugio(this.state.currentRefugio.id)
      .then(() => {
        this.props.router.navigate("/refugiosList");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  goBack = () => {
    this.props.router.navigate("/refugiosList");
  };

  render() {
    const { currentRefugio, error, showAdminBoard, showModeratorBoard } =
      this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                {this.state.cargandoRefugio ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Alert variant="refugio">
                    <div className="m-1 d-grid gap-2 d-md-block">
                      <Row>
                        <Col xl="11" sm="12">
                          <h3>
                            <b>{currentRefugio.nombre.toUpperCase()}</b>{" "}
                            {currentRefugio.estatus ? (
                              <Button
                                variant="success"
                                size="sm"
                                style={{ pointerEvents: "none" }}
                              >
                                <b>Activo</b>
                              </Button>
                            ) : (
                              <Button
                                variant="danger"
                                size="sm"
                                style={{ pointerEvents: "none" }}
                              >
                                <b>Desactivado</b>
                              </Button>
                            )}
                          </h3>
                          <h4 className="refugiotext">
                            <Spinner
                              animation="grow"
                              size="sm"
                              className="refugiotext"
                            />
                            <b> MODIFICAR REFUGIO </b>
                          </h4>
                        </Col>
                        <Col xl="1" sm="12">
                          <h2>
                            <ImHome />
                          </h2>
                        </Col>

                        <hr />

                        <Col xs="12">
                          <h5>
                            <i>
                              {"Por favor rellene los campos solicitados... "}
                            </i>
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Popover>
                                  <Popover.Header as="h3">
                                    <b>Ayuda </b> <FaQuestionCircle />
                                  </Popover.Header>
                                  <Popover.Body>
                                    {
                                      " - Únicamente serán actualizados los campos que fueron editados"
                                    }
                                    <br />
                                    {
                                      " - El botón (Actualizar) se desbloqueará al rellenar todos los "
                                    }
                                    <strong>{"*Campos obligatorios"}</strong>
                                    <br />
                                    {
                                      " - Click en el switch para cambiar el estatus del refugio"
                                    }
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <strong>
                                <FaQuestionCircle />
                              </strong>
                            </OverlayTrigger>
                          </h5>
                        </Col>
                        <Col xs="12">
                          <strong className="refugiotext">
                            *Campos obligatorios
                          </strong>
                        </Col>

                        <Col xs="12">
                          <Form.Check
                            type="switch"
                            value={currentRefugio.estatus ? true : false}
                            checked={currentRefugio.estatus ? true : false}
                            onChange={this.onChangeEstatus}
                            label={
                              currentRefugio.estatus ? (
                                <h5>
                                  <b className="text-success">Refugio Activo</b>
                                </h5>
                              ) : (
                                <h5>
                                  <b className="text-danger">
                                    Refugio Desactivado
                                  </b>
                                </h5>
                              )
                            }
                          />
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group className="mb-1" controlId="municipio">
                            <Form.Label>
                              <strong>Municipio*:</strong>
                            </Form.Label>
                            <Form.Select
                              onClick={this.getMunicipios}
                              onChange={this.onChangeMunicipio}
                              onMouseLeave={this.handleKeypress}
                            >
                              <option
                                defaultValue={
                                  currentRefugio.municipio == null
                                    ? ""
                                    : currentRefugio.municipio
                                }
                                hidden
                              >
                                {!currentRefugio.municipio
                                  ? "Sin municipio"
                                  : currentRefugio.municipio.municipio}
                              </option>
                              {this.state.municipios.map((municipio, index) => (
                                <option value={index} key={municipio.id}>
                                  {municipio.municipio}
                                </option>
                              ))}
                            </Form.Select>
                            <Alert
                              variant={"nocolor"}
                              show={
                                this.state.currentRefugio.municipio
                                  ? false
                                  : true
                              }
                            >
                              {badge}
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12">
                          <Form.Group className="mb-1" controlId="nombre">
                            <Form.Label>
                              <strong>Nombre*:</strong>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Ingrese el nombre del refugio"
                              maxLength="128"
                              value={
                                currentRefugio.nombre == null
                                  ? ""
                                  : currentRefugio.nombre
                              }
                              onChange={this.onChangeNombre}
                              onKeyUp={this.handleKeypress}
                              name="nombre"
                              autoComplete="off"
                            />
                            <Alert
                              variant={"nocolor"}
                              show={
                                this.state.currentRefugio.nombre ? false : true
                              }
                            >
                              {badge}
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12">
                          <Form.Group as={Row}>
                            <Col sm="6">
                              <Form.Group className="mb-1" controlId="latitud">
                                <Form.Label>
                                  <strong>
                                    {"Latitud: "}
                                    {!this.state.posicionLa ? null : (
                                      <FaCheckCircle />
                                    )}
                                  </strong>
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type="text"
                                    maxLength="14"
                                    autoComplete="off"
                                    placeholder={"0.0"}
                                    value={
                                      !currentRefugio.latitud
                                        ? ""
                                        : currentRefugio.latitud
                                    }
                                    onChange={this.onChangeLatitud}
                                    onKeyUp={this.handlePosicion}
                                  />
                                  <InputGroup.Text>
                                    <b>°</b>
                                  </InputGroup.Text>
                                </InputGroup>
                                <Alert
                                  variant={"nocolor"}
                                  show={
                                    currentRefugio.latitud &&
                                    this.state.posicionLa === false
                                      ? true
                                      : false
                                  }
                                >
                                  {
                                    <Badge bg={"refugio"}>
                                      Inserte una latitud de Veracruz{" "}
                                      <FaExclamationTriangle />
                                    </Badge>
                                  }
                                </Alert>
                              </Form.Group>
                            </Col>
                            <Col sm="6">
                              <Form.Group className="mb-1" controlId="longitud">
                                <Form.Label>
                                  <strong>
                                    {"Longitud: "}
                                    {!this.state.posicionLo ? null : (
                                      <FaCheckCircle />
                                    )}
                                  </strong>
                                </Form.Label>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <b>-</b>
                                  </InputGroup.Text>
                                  <FormControl
                                    type="text"
                                    maxLength="14"
                                    autoComplete="off"
                                    placeholder="0.0"
                                    value={
                                      !currentRefugio.longitud
                                        ? ""
                                        : currentRefugio.longitud < 0
                                        ? currentRefugio.longitud * -1
                                        : currentRefugio.longitud
                                    }
                                    onChange={this.onChangeLongitud}
                                    onKeyUp={this.handlePosicion}
                                  />
                                  <InputGroup.Text>
                                    <b>°</b>
                                  </InputGroup.Text>
                                </InputGroup>
                                <Alert
                                  variant={"nocolor"}
                                  show={
                                    currentRefugio.longitud &&
                                    this.state.posicionLo === false
                                      ? true
                                      : false
                                  }
                                >
                                  {
                                    <Badge bg={"refugio"}>
                                      Inserte una longitud de Veracruz{" "}
                                      <FaExclamationTriangle />
                                    </Badge>
                                  }
                                </Alert>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <b>
                            ¿Necesita la ubicación?{" "}
                            <a
                              href="https://www.google.com/maps/"
                              target="_blank"
                              rel="noreferrer"
                              className="linktext"
                            >
                              <Button
                                variant="whatsapp"
                                size="sm"
                                className="mb-1"
                              >
                                Google maps <FaMapMarkerAlt />
                              </Button>
                            </a>{" "}
                          </b>
                          <OverlayTrigger
                            placement="auto"
                            show={this.state.errPegado}
                            overlay={
                              <Tooltip>
                                Copie las coordenadas en grados decimales
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="whatsapp2"
                              size="sm"
                              className="mb-1"
                              onClick={this.pasteLatitudLongitud}
                            >
                              {"Pegar coordenadas "}
                              <FaPaste />
                            </Button>
                          </OverlayTrigger>
                        </Col>

                        <Col xl="6" sm="12">
                          <Form.Group className="mb-1" controlId="direccion">
                            <Form.Label>
                              <strong>Dirección*:</strong>
                            </Form.Label>
                            <Form.Control
                              rows={3}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese la dirección del refugio"
                              value={
                                currentRefugio.direccion == null
                                  ? ""
                                  : currentRefugio.direccion
                              }
                              onChange={this.onChangeDireccion}
                              onKeyUp={this.handleKeypress}
                            />
                            <Alert
                              variant={"nocolor"}
                              show={
                                this.state.currentRefugio.direccion
                                  ? false
                                  : true
                              }
                            >
                              {badge}
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group className="mb-1">
                            <Form.Label>
                              <strong>Capacidad*:</strong>
                            </Form.Label>
                            <InputGroup>
                              <FormControl
                                type="text"
                                maxLength="6"
                                placeholder={0}
                                value={
                                  currentRefugio.capacidad == null
                                    ? ""
                                    : currentRefugio.capacidad
                                }
                                onChange={this.onChangeCapacidad}
                                onKeyUp={this.handleKeypress}
                              />
                              <InputGroup.Text>
                                <strong>personas</strong>
                              </InputGroup.Text>
                            </InputGroup>
                            <Alert
                              variant={"nocolor"}
                              show={
                                this.state.currentRefugio.capacidad
                                  ? false
                                  : true
                              }
                            >
                              {badge}
                            </Alert>
                          </Form.Group>
                        </Col>
                      </Row>

                      <p></p>

                      <Stack direction="horizontal" gap={3}>
                        <div>
                          <Button
                            onClick={this.goBack}
                            variant="outline-refugio"
                            className="ms-auto"
                            size="lg"
                          >
                            <ImArrowLeft2 /> Regresar
                          </Button>
                        </div>
                        <div>
                          {(showAdminBoard || showModeratorBoard) && (
                            <Button
                              variant="error"
                              size="lg"
                              onClick={this.openModalBorrar}
                            >
                              <ImCross /> Borrar
                            </Button>
                          )}
                        </div>
                        <div className="ms-auto">
                          <Link to={"/refugio/" + this.props.router.params.id}>
                            <Button variant="gob" size="lg">
                              Visualizar <BsEyeFill />
                            </Button>
                          </Link>
                        </div>
                        <div onMouseOver={this.handleKeypress}>
                          <OverlayTrigger
                            placement="top"
                            show={this.state.camposFaltantes}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                Faltan campos por llenar / Datos Incorrectos
                              </Tooltip>
                            }
                          >
                            <Button
                              disabled={this.state.camposFaltantes}
                              onClick={this.openModalActualizar}
                              size="lg"
                              variant="gold"
                            >
                              <strong>Actualizar</strong> <BsCheckLg />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </Stack>
                    </div>
                  </Alert>
                )}

                {this.state.message ? (
                  <Fade in={this.state.open}>
                    <div>
                      <Alert
                        variant={error ? "error" : "correct"}
                        onClose={() => this.setClose()}
                        dismissible
                      >
                        <Alert.Heading className="text-center">
                          {this.state.message}
                        </Alert.Heading>
                      </Alert>
                    </div>
                  </Fade>
                ) : null}

                <Modal
                  show={this.state.isOpen}
                  onHide={this.closeModal}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header>
                    {this.state.isActualizar ? (
                      <Modal.Title>
                        <div className="text-center">
                          ¿Esta seguro/a de actualizar el refugio?
                          <b className="refugiotext">
                            {" " + currentRefugio.nombre.toUpperCase()}
                          </b>
                          <p className="nocolor smalltext">&zwnj;</p>
                          <Button
                            className="m-2"
                            variant="secondary"
                            onClick={this.closeModal}
                          >
                            Cancelar
                          </Button>
                          <Button
                            className="m-2"
                            variant="gold"
                            onClick={this.updateRefugio}
                          >
                            <b>Actualizar </b>
                            <BsCheckLg />
                          </Button>
                        </div>
                      </Modal.Title>
                    ) : (
                      <>
                        {this.state.isBorrar ? (
                          <Modal.Title>
                            <div className="text-center">
                              ¿Esta seguro/a de <strong>BORRAR</strong> el
                              refugio?
                              <b className="refugiotext">
                                {" " + currentRefugio.nombre.toUpperCase()}
                              </b>
                              <p className="nocolor smalltext">&zwnj;</p>
                              <h5>
                                <strong>
                                  {
                                    "(Puede estar siendo utilizado por reportes del sistema)"
                                  }
                                </strong>
                              </h5>
                              <p className="nocolor smalltext">&zwnj;</p>
                              <Button
                                className="m-2"
                                variant="secondary"
                                onClick={this.closeModal}
                              >
                                Cancelar
                              </Button>
                              <Button
                                className="m-2"
                                variant="error"
                                onClick={this.deleteRefugio}
                              >
                                <ImCross /> Borrar
                              </Button>
                            </div>
                          </Modal.Title>
                        ) : null}
                      </>
                    )}
                  </Modal.Header>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(Refugio);
