import axios from "axios";
import authHeader from "./AuthHeader";
const API_URL = "https://sicecom.veracruz.gob.mx/api/";
class UserService {
  getPublicContent() {
    return axios.get(API_URL + "test/all");
  }
  getUserBoard() {
    return axios.get(API_URL + "test/user", { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL + "test/mod", { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + "test/admin", { headers: authHeader() });
  }

  getUsers() {
    return axios.get(API_URL + "auth/users", { headers: authHeader() });
  }

  getUser(id) {
    return axios.get(API_URL + `auth/users/${id}`, { headers: authHeader() });
  }

  editUser(id, data) {
    return axios.put(API_URL + `auth/users/${id}`, data, {
      headers: authHeader(),
    });
  }

  updateUser(id, data) {
    return axios.put(API_URL + `auth/users/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteUser(id) {
    return axios.delete(API_URL + `auth/users/${id}`, {
      headers: authHeader(),
    });
  }
}
export default new UserService();
