import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import EventBus from "../../common/EventBus";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Popover,
} from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/AuthService";
import ReporteService from "../../services/ReporteService";
import MunicipioService from "../../services/MunicipioService";
import { AiFillEdit } from "react-icons/ai";
import {
  TiArrowUpThick,
  TiArrowUp,
  TiArrowDownThick,
  TiArrowDown,
} from "react-icons/ti";
import { FaQuestionCircle } from "react-icons/fa";
import { ImHome, ImCross } from "react-icons/im";
import { BsEyeFill } from "react-icons/bs";

export default class refugioList extends Component {
  constructor(props) {
    super(props);
    this.retrieveRefugios = this.retrieveRefugios.bind(this);
    this.changeRefugioEstatus = this.changeRefugioEstatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.orderBy = this.orderBy.bind(this);
    this.onChangeDirection = this.onChangeDirection.bind(this);
    this.onChangeEstatus = this.onChangeEstatus.bind(this);
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
    this.getMunicipios = this.getMunicipios.bind(this);

    this.resetMunicipio = this.resetMunicipio.bind(this);
    this.resetNombre = this.resetNombre.bind(this);
    this.resetFilters = this.resetFilters.bind(this);

    this.state = {
      refugios: [],
      municipios: [],

      currentRefugio: {
        id: null,
        nombre: null,
        direccion: null,
        capacidad: null,
        estatus: null,

        municipio: {
          id: null,
          municipio: null,
          region: {
            id: null,
            region: null,
          },
        },
      },

      mun: null,
      nom: "",
      est: false,

      refugiosTotales: 0,
      page: 1,
      count: 0,
      pageSize: 12,

      cargandoRefugios: true,

      variable: "",
      direction: false,
    };

    this.pageSizes = [8, 10, 12, 14, 16];
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Lista de refugios";

      this.setState({
        currentUser: currentUser,
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
      });
      this.retrieveRefugios();
      this.getMunicipios();
    }
  }

  onChangeDirection() {
    this.setState(
      {
        direction: !this.state.direction,
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  onChangeEstatus() {
    this.setState(
      {
        est: !this.state.est,
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  onChangeMunicipio(e) {
    this.setState(
      {
        page: 1,
        mun: e.target.value,
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  onChangeNombre(e) {
    this.setState(
      {
        page: 1,
        nom: e.target.value.toUpperCase(),
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  resetMunicipio() {
    this.setState(
      {
        municipios: [],
        mun: null,
        page: 1,
      },
      () => {
        this.getMunicipios();
        this.retrieveRefugios();
      }
    );
  }

  resetNombre() {
    this.setState(
      {
        nom: "",
        page: 1,
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  resetFilters() {
    this.setState(
      {
        municipios: [],

        mun: null,
        nom: "",
        est: false,

        page: 1,
        variable: "",
        direction: false,
      },
      () => {
        this.getMunicipios();
        this.retrieveRefugios();
      }
    );
  }

  getRequestParams(nom, est, mun, page, pageSize, variable, direction) {
    let params = {};

    if (nom) {
      params["nom"] = nom;
    }

    if (est) {
      params["est"] = est;
    }

    if (mun) {
      params["mun"] = mun;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (variable) {
      params["var"] = variable;
    }

    if (direction) {
      params["direction"] = direction;
    }

    return params;
  }

  retrieveRefugios() {
    const { nom, est, mun, page, pageSize, variable, direction } = this.state;

    const params = this.getRequestParams(
      nom,
      est,
      mun,
      page,
      pageSize,
      variable,
      direction ? "DESC" : "ASC"
    );

    ReporteService.getRefugios(params)
      .then((response) => {
        const { refugio, totalPages, totalItems } = response.data;
        this.setState({
          refugios: refugio,
          count: totalPages,
          refugiosTotales: totalItems,
          cargandoRefugios: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  changeRefugioEstatus(id) {
    ReporteService.getRefugio(id)
      .then((response) => {
        var data = {
          id: response.data.id,
          nombre: response.data.nombre,
          direccion: response.data.direccion,
          capacidad: response.data.capacidad,
          municipio: response.data.municipio,
          estatus: !response.data.estatus,
        };

        ReporteService.updateRefugio(id, data)
          .then(() => {
            this.retrieveRefugios();
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              EventBus.dispatch("expireToken");
            }
            console.log(err);
          });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/refugiosList");
          console.log(err);
        }
      });
  }

  getMunicipios() {
    MunicipioService.getMunicipios()
      .then((response) => {
        this.setState({
          municipios: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  orderBy(variable) {
    this.setState(
      {
        variable: variable,
        page: 1,
      },
      () => {
        this.retrieveRefugios();
      }
    );
  }

  render() {
    const {
      refugios,
      page,
      count,
      pageSize,
      refugiosTotales,
      cargandoRefugios,
    } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <Row>
              <Col md={12}>
                <p className="nocolor mediumtext">&zwnj;</p>
                <h3 className="text-center">
                  <b>
                    {"LISTA DE REFUGIOS TEMPORALES "}
                    <b
                      className={
                        this.state.est ? "concluidotext" : "refugiotext"
                      }
                    >
                      {this.state.est ? "ACTIVOS" : "TOTALES"}
                    </b>
                    {cargandoRefugios ? (
                      <Spinner animation="border" size="lg" />
                    ) : (
                      " (" + refugiosTotales + ")"
                    )}{" "}
                    <ImHome />
                  </b>
                </h3>

                {cargandoRefugios ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th scope="col">
                          <OverlayTrigger
                            overlay={<Tooltip>Cambiar orden</Tooltip>}
                          >
                            {this.state.direction ? (
                              <Button
                                onClick={this.onChangeDirection}
                                size="sm"
                                variant="dark"
                              >
                                <TiArrowUpThick />
                                <TiArrowDown />
                              </Button>
                            ) : (
                              <Button
                                onClick={this.onChangeDirection}
                                size="sm"
                                variant="dark"
                              >
                                <TiArrowUp />
                                <TiArrowDownThick />
                              </Button>
                            )}
                          </OverlayTrigger>
                        </th>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por nombre</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("nombre")}
                          >
                            Nombre
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por municipio</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("municipio")}
                          >
                            Municipio
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por dirección</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("direccion")}
                          >
                            Dirección
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por capacidad</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("capacidad")}
                          >
                            Capacidad
                          </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={<Tooltip>Ordenar por estatus</Tooltip>}
                        >
                          <th
                            scope="col"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.orderBy("estatus")}
                          >
                            Estatus
                          </th>
                        </OverlayTrigger>
                        <th scope="col" style={{ cursor: "default" }}>
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {refugios.map((refugio, index) => (
                        <tr key={refugio.id}>
                          <td>
                            <strong>{index + 1}</strong>
                          </td>

                          <td>
                            <b>{refugio.nombre ? refugio.nombre : ""}</b>
                          </td>

                          <td>
                            <strong>
                              {refugio.municipio
                                ? refugio.municipio.municipio
                                : ""}
                            </strong>
                          </td>

                          <td>
                            <strong>
                              {refugio.direccion ? refugio.direccion : ""}
                            </strong>
                          </td>

                          <td>
                            <strong>
                              {refugio.capacidad
                                ? refugio.capacidad + " personas"
                                : ""}
                            </strong>
                          </td>

                          <td>
                            <OverlayTrigger
                              overlay={<Tooltip>Cambiar estatus</Tooltip>}
                            >
                              {refugio.estatus ? (
                                <Button
                                  style={{ width: "100px" }}
                                  variant="correct"
                                  size="sm"
                                  onClick={() =>
                                    this.changeRefugioEstatus(refugio.id)
                                  }
                                >
                                  <strong>Activo</strong>
                                </Button>
                              ) : (
                                <Button
                                  style={{ width: "100px" }}
                                  variant="error"
                                  size="sm"
                                  onClick={() =>
                                    this.changeRefugioEstatus(refugio.id)
                                  }
                                >
                                  <strong>Desactivado</strong>
                                </Button>
                              )}
                            </OverlayTrigger>
                          </td>

                          <td>
                            <NavLink to={"/refugio/" + refugio.id}>
                              <OverlayTrigger overlay={<Tooltip>Ver</Tooltip>}>
                                <Button variant="gob" size="sm">
                                  <BsEyeFill />
                                </Button>
                              </OverlayTrigger>
                            </NavLink>
                            <Link to={"/refugios/" + refugio.id}>
                              <OverlayTrigger
                                overlay={<Tooltip>Editar</Tooltip>}
                              >
                                <Button variant="gold" size="sm">
                                  <AiFillEdit />
                                </Button>
                              </OverlayTrigger>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                <div className="footerp">
                  <Row>
                    <Col xs="4">
                      <strong>
                        &zwnj; Refugios por página:{" "}
                        <select
                          onChange={this.handlePageSizeChange}
                          value={pageSize}
                        >
                          {this.pageSizes.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                        <Button
                          variant="dark"
                          size="sm"
                          className="ms-2"
                          onClick={this.resetFilters}
                        >
                          Limpiar filtros
                        </Button>
                        <Link to={"/addRefugio"}>
                          <Button variant="refugio" size="sm" className="ms-2">
                            Agregar refugio
                          </Button>
                        </Link>
                      </strong>

                      <Pagination
                        className="my-3"
                        count={count}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={this.handlePageChange}
                      />
                    </Col>

                    <Col xs="1">
                      <Form.Group className="mb-1" controlId="estatus">
                        <Form.Label>
                          <strong>Mostrar:</strong>
                        </Form.Label>
                        <div className="d-grid">
                          {this.state.est ? (
                            <Button
                              variant="refugio"
                              onClick={this.onChangeEstatus}
                              size="sm"
                            >
                              <b>Todos</b>
                            </Button>
                          ) : (
                            <Button
                              variant="correct"
                              onClick={this.onChangeEstatus}
                              size="sm"
                            >
                              <b>Activos</b>
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs="1">
                      <Form.Group
                        className="mb-1 d-flex justify-content-center"
                        controlId="ayuda"
                      >
                        <Form.Label>
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Popover>
                                <Popover.Header as="h3">
                                  <b>Ayuda </b> <FaQuestionCircle />
                                </Popover.Header>
                                <Popover.Body>
                                  {" - Se pueden usar filtros múltiples"}
                                  <br />
                                  {
                                    " - Haga click en (X) sobre un filtro para limpiarlo individualmente"
                                  }

                                  <br />
                                  {
                                    " - Click en (Limpiar Filtros) para limpiar todos los filtros"
                                  }
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <h4>
                              <FaQuestionCircle />
                            </h4>
                          </OverlayTrigger>
                        </Form.Label>
                      </Form.Group>
                    </Col>

                    <Col xs="3">
                      <Form.Group className="mb-1" controlId="mun">
                        <Form.Label>
                          {this.state.mun ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetMunicipio}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{" Filtro por municipio:"}</strong>
                        </Form.Label>
                        <Form.Select
                          value={!this.state.mun ? "" : this.state.mun}
                          onChange={this.onChangeMunicipio}
                          onDoubleClick={this.resetMunicipio}
                          size="sm"
                        >
                          <option defaultValue={""} hidden>
                            Seleccione el municipio...
                          </option>
                          {this.state.municipios.map((municipio) => (
                            <option key={municipio.id} value={municipio.id}>
                              {municipio.municipio}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col xs="3">
                      <Form.Group className="mb-1" controlId="nombre">
                        <Form.Label>
                          {this.state.nom ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Limpiar filtro</Tooltip>}
                            >
                              <Button
                                variant="dark"
                                size="sm"
                                onClick={this.resetNombre}
                              >
                                <ImCross />
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <strong>{" Buscar por nombre:"}</strong>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={!this.state.nom ? "" : this.state.nom}
                          onChange={this.onChangeNombre}
                          onDoubleClick={this.resetNombre}
                          autoComplete="off"
                          maxLength="128"
                          name="folio"
                          size="sm"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
