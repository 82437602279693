import axios from "axios";
import authHeader from "./AuthHeader";
const API_URL = "https://sicecom.veracruz.gob.mx/api/auth";

class ReporteService {
  getReportes(params) {
    return axios.get(API_URL + `/reporte`, { params, headers: authHeader() });
  }

  getReportesCompletos(params) {
    return axios.get(API_URL + `/reporteCompleto`, {
      params,
      headers: authHeader(),
    });
  }

  getReportesRecientes(params) {
    return axios.get(API_URL + `/reporteReciente`, {
      
      params,
      headers: authHeader(),
    });
  }
  
  getFenomenos() {
    return axios.get(API_URL + `/fenomeno`, { headers: authHeader() });
  }

  getTipos() {
    return axios.get(API_URL + `/tipo`, { headers: authHeader() });
  }

  getTipo(fenomeno) {
    return axios.get(API_URL + `/tipo/${fenomeno}`, { headers: authHeader() });
  }

  getEstatusList() {
    return axios.get(API_URL + `/estatus`, { headers: authHeader() });
  }

  getFallecidosList() {
    return axios.get(API_URL + `/personasFallecidas`, { headers: authHeader() });
  }

  getNivelList() {
    return axios.get(API_URL + `/nivelEmergencia`, { headers: authHeader() });
  }

  getReporte(id) {
    return axios.get(API_URL + `/reporte/${id}`, { headers: authHeader() });
  }

  getReporteCompleto(id) {
    return axios.get(API_URL + `/reportec/${id}`, { headers: authHeader() });
  }

  getReporteByFolio(folio) {
    return axios.get(API_URL + `/reportef/${folio}`, { headers: authHeader() });
  }

  createReporte(data) {
    return axios.post(API_URL + `/reporte`, data, {
      headers: authHeader(),
    });
  }

  updateReporte(id, data) {
    return axios.put(API_URL + `/reporte/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteReporte(id) {
    return axios.delete(API_URL + `/reporte/${id}`, { headers: authHeader() });
  }

  getContadoresFenomeno() {
    return axios.get(API_URL + `/contador`, { headers: authHeader() });
  }

  getContadoresTipo(fenomenoId) {
    return axios.get(API_URL + `/contador/${fenomenoId}`, {
      headers: authHeader(),
    });
  }

  getHIDRO_ABCDEF(id) {
    return axios.get(API_URL + `/hidro_abcdef/${id}`, {
      headers: authHeader(),
    });
  }

  createHIDRO_ABCDEF(data) {
    return axios.post(API_URL + `/hidro_abcdef`, data, {
      headers: authHeader(),
    });
  }

  updateHIDRO_ABCDEF(id, data) {
    return axios.put(API_URL + `/hidro_abcdef/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteHIDRO_ABCDEF(id) {
    return axios.delete(API_URL + `/hidro_abcdef/${id}`, {
      headers: authHeader(),
    });
  }

  getHIDRO_GHI(id) {
    return axios.get(API_URL + `/hidro_ghi/${id}`, { headers: authHeader() });
  }

  createHIDRO_GHI(data) {
    return axios.post(API_URL + `/hidro_ghi`, data, {
      headers: authHeader(),
    });
  }

  updateHIDRO_GHI(id, data) {
    return axios.put(API_URL + `/hidro_ghi/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteHIDRO_GHI(id) {
    return axios.delete(API_URL + `/hidro_ghi/${id}`, {
      headers: authHeader(),
    });
  }

  getHIDRO_JKL(id) {
    return axios.get(API_URL + `/hidro_jkl/${id}`, { headers: authHeader() });
  }

  createHIDRO_JKL(data) {
    return axios.post(API_URL + `/hidro_jkl`, data, {
      headers: authHeader(),
    });
  }

  updateHIDRO_JKL(id, data) {
    return axios.put(API_URL + `/hidro_jkl/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteHIDRO_JKL(id) {
    return axios.delete(API_URL + `/hidro_jkl/${id}`, {
      headers: authHeader(),
    });
  }

  getGEO_ABCDE(id) {
    return axios.get(API_URL + `/geo_abcde/${id}`, { headers: authHeader() });
  }

  createGEO_ABCDE(data) {
    return axios.post(API_URL + `/geo_abcde`, data, {
      headers: authHeader(),
    });
  }

  updateGEO_ABCDE(id, data) {
    return axios.put(API_URL + `/geo_abcde/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteGEO_ABCDE(id) {
    return axios.delete(API_URL + `/geo_abcde/${id}`, {
      headers: authHeader(),
    });
  }

  getGEO_F(id) {
    return axios.get(API_URL + `/geo_f/${id}`, { headers: authHeader() });
  }

  createGEO_F(data) {
    return axios.post(API_URL + `/geo_f`, data, {
      headers: authHeader(),
    });
  }

  updateGEO_F(id, data) {
    return axios.put(API_URL + `/geo_f/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteGEO_F(id) {
    return axios.delete(API_URL + `/geo_f/${id}`, { headers: authHeader() });
  }

  getECO_AB(id) {
    return axios.get(API_URL + `/eco_ab/${id}`, { headers: authHeader() });
  }

  createECO_AB(data) {
    return axios.post(API_URL + `/eco_ab`, data, {
      headers: authHeader(),
    });
  }

  updateECO_AB(id, data) {
    return axios.put(API_URL + `/eco_ab/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteECO_AB(id) {
    return axios.delete(API_URL + `/eco_ab/${id}`, { headers: authHeader() });
  }

  getORG_ABC(id) {
    return axios.get(API_URL + `/org_abc/${id}`, { headers: authHeader() });
  }

  createORG_ABC(data) {
    return axios.post(API_URL + `/org_abc`, data, {
      headers: authHeader(),
    });
  }

  updateORG_ABC(id, data) {
    return axios.put(API_URL + `/org_abc/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteORG_ABC(id) {
    return axios.delete(API_URL + `/org_abc/${id}`, { headers: authHeader() });
  }

  getORG_DEFGHI(id) {
    return axios.get(API_URL + `/org_defghi/${id}`, { headers: authHeader() });
  }

  createORG_DEFGHI(data) {
    return axios.post(API_URL + `/org_defghi`, data, {
      headers: authHeader(),
    });
  }

  updateORG_DEFGHI(id, data) {
    return axios.put(API_URL + `/org_defghi/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteORG_DEFGHI(id) {
    return axios.delete(API_URL + `/org_defghi/${id}`, {
      headers: authHeader(),
    });
  }

  getORG_JKLMNO(id) {
    return axios.get(API_URL + `/org_jklmno/${id}`, { headers: authHeader() });
  }

  createORG_JKLMNO(data) {
    return axios.post(API_URL + `/org_jklmno`, data, {
      headers: authHeader(),
    });
  }

  updateORG_JKLMNO(id, data) {
    return axios.put(API_URL + `/org_jklmno/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteORG_JKLMNO(id) {
    return axios.delete(API_URL + `/org_jklmno/${id}`, {
      headers: authHeader(),
    });
  }

  getORG_P(id) {
    return axios.get(API_URL + `/org_p/${id}`, { headers: authHeader() });
  }

  createORG_P(data) {
    return axios.post(API_URL + `/org_p`, data, {
      headers: authHeader(),
    });
  }

  updateORG_P(id, data) {
    return axios.put(API_URL + `/org_p/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteORG_P(id) {
    return axios.delete(API_URL + `/org_p/${id}`, { headers: authHeader() });
  }

  getQUI_ABC(id) {
    return axios.get(API_URL + `/quimtec_abc/${id}`, { headers: authHeader() });
  }

  createQUI_ABC(data) {
    return axios.post(API_URL + `/quimtec_abc`, data, {
      headers: authHeader(),
    });
  }

  updateQUI_ABC(id, data) {
    return axios.put(API_URL + `/quimtec_abc/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteQUI_ABC(id) {
    return axios.delete(API_URL + `/quimtec_abc/${id}`, {
      headers: authHeader(),
    });
  }

  getQUI_DEFGHIJKL(id) {
    return axios.get(API_URL + `/quimtec_defghijkl/${id}`, {
      headers: authHeader(),
    });
  }

  createQUI_DEFGHIJKL(data) {
    return axios.post(API_URL + `/quimtec_defghijkl`, data, {
      headers: authHeader(),
    });
  }

  updateQUI_DEFGHIJKL(id, data) {
    return axios.put(API_URL + `/quimtec_defghijkl/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteQUI_DEFGHIJKL(id) {
    return axios.delete(API_URL + `/quimtec_defghijkl/${id}`, {
      headers: authHeader(),
    });
  }

  getEstatusFuga(id) {
    return axios.get(API_URL + `/estatusFuga/${id}`, {
      headers: authHeader(),
    });
  }

  getEstatusFugaList() {
    return axios.get(API_URL + `/estatusFuga`, { headers: authHeader() });
  }

  getEstatusControlList() {
    return axios.get(API_URL + `/estatusControl`, { headers: authHeader() });
  }

  getEstatusControl(id) {
    return axios.get(API_URL + `/estatusControl/${id}`, {
      headers: authHeader(),
    });
  }

  getEstatusLiquidacionList() {
    return axios.get(API_URL + `/estatusLiquidacion`, {
      headers: authHeader(),
    });
  }

  getEstatusLiquidacion(id) {
    return axios.get(API_URL + `/estatusLiquidacion/${id}`, {
      headers: authHeader(),
    });
  }

  deletePER_A(id) {
    return axios.delete(API_URL + `/per_a/${id}`, { headers: authHeader() });
  }

  getAST_ABC(id) {
    return axios.get(API_URL + `/astro_abc/${id}`, { headers: authHeader() });
  }

  createAST_ABC(data) {
    return axios.post(API_URL + `/astro_abc`, data, {
      headers: authHeader(),
    });
  }

  updateAST_ABC(id, data) {
    return axios.put(API_URL + `/astro_abc/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteAST_ABC(id) {
    return axios.delete(API_URL + `/astro_abc/${id}`, {
      headers: authHeader(),
    });
  }

  upload(file, onUploadProgress, folio) {
    let formData = new FormData();
    formData.append("files", file);
    formData.append("folio", folio);

    return axios.post(API_URL + `/upload`, formData, {
      headers: authHeader(),
      onUploadProgress,
    });
  }

  getFiles() {
    return axios.get(API_URL + `/files`, { headers: authHeader() });
  }

  getReporteFiles(id) {
    return axios.get(API_URL + `/reporte/${id}/files`, {
      headers: authHeader(),
    });
  }

  deleteFile(filename) {
    return axios.delete(API_URL + `/files/${filename}`, {
      headers: authHeader(),
    });
  }

  getReporteSeguimientos(id) {
    return axios.get(API_URL + `/reporte/${id}/seguimiento`, {
      headers: authHeader(),
    });
  }

  getSeguimiento(id) {
    return axios.get(API_URL + `/seguimiento/${id}`, { headers: authHeader() });
  }

  createSeguimiento(data) {
    return axios.post(API_URL + `/seguimiento`, data, {
      headers: authHeader(),
    });
  }

  updateSeguimiento(id, data) {
    return axios.put(API_URL + `/seguimiento/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteSeguimiento(id) {
    return axios.delete(API_URL + `/seguimiento/${id}`, {
      headers: authHeader(),
    });
  }

  getRefugios(params) {
    return axios.get(API_URL + `/refugio`, { params, headers: authHeader() });
  }

  getRefugio(id) {
    return axios.get(API_URL + `/refugio/${id}`, { headers: authHeader() });
  }

  createRefugio(data) {
    return axios.post(API_URL + `/refugio`, data, {
      headers: authHeader(),
    });
  }

  updateRefugio(id, data) {
    return axios.put(API_URL + `/refugio/${id}`, data, {
      headers: authHeader(),
    });
  }

  deleteRefugio(id) {
    return axios.delete(API_URL + `/refugio/${id}`, {
      headers: authHeader(),
    });
  }

  changeRefugioEstatus(id) {
    return axios.put(API_URL + `/refugio/${id}/switch`, {
      headers: authHeader(),
    });
  }

  getRefugiosActivos(municipio) {
    return axios.get(API_URL + `/refugioActivo/${municipio}`, {
      headers: authHeader(),
    });
  }

  getReportesRefugio(id) {
    return axios.get(API_URL + `/countReportesRefugio/${id}`, {
      headers: authHeader(),
    });
  }

  getInforme(params) {
    return axios.get(API_URL + `/pdf`, {
      params,
      headers: authHeader(),
      responseType: "blob",
    });
  }
}

export default new ReporteService();
