import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function withRouter(Children) {
  return (props) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Children {...props} router={{ location, navigate, params }} />;
  };
}
