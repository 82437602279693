import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBus from "../../common/EventBus";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import {
  Table,
  Row,
  Col,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import { AiFillEdit } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { BsFillPeopleFill } from "react-icons/bs";

export default class UserList extends Component {
  constructor(props) {
    super(props);
    this.retriveUsers = this.retriveUsers.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.openModalBorrar = this.openModalBorrar.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      users: [],

      currentUser: undefined,
      showAdminBoard: false,
      showModeratorBoard: false,

      userId: null,

      isOpen: false,
      isBorrar: false,
      messageError: "",

      cargandoUsers: true,
    };

    this.pageSizes = [8, 12, 16];
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Lista de usuarios";

      this.setState({
        currentUser: currentUser.username,
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
        showModeratorBoard: currentUser.roles.includes("ROLE_MODERATOR"),
      });
      this.retriveUsers();
    }
  }

  openModalBorrar(id) {
    this.setState({
      userId: id,
      isOpen: true,
      isBorrar: true,
      messageError: "",
    });
  }

  closeModal() {
    this.setState({
      userId: null,
      isOpen: false,
      isBorrar: false,
      messageError: "",
    });
  }

  retriveUsers() {
    UserService.getUsers()
      .then((response) => {
        const { totalItems, usuarios } = response.data;

        this.setState({
          users: usuarios,
          count: totalItems,
          cargandoUsers: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  deleteUser(id) {
    UserService.deleteUser(id)
      .then(() => {
        this.retriveUsers();
        this.closeModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            messageError: err.message,
          });
        }
      });
  }

  render() {
    const {
      users,
      currentUser,
      showAdminBoard,
      showModeratorBoard,
      count,
      cargandoUsers,
    } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          {showAdminBoard || showModeratorBoard ? (
            <div className="content w-100 cont">
              <Navbar />
              <Row>
                <Col xs={12}>
                  <p className="nocolor mediumtext">&zwnj;</p>
                  <h3 className="text-center">
                    <b>
                      {"LISTA DE USUARIOS "}
                      {cargandoUsers ? (
                        <Spinner animation="border" size="lg" />
                      ) : (
                        "(" + count + ")"
                      )}{" "}
                      <BsFillPeopleFill />
                    </b>
                  </h3>

                  {cargandoUsers ? (
                    <div className="d-flex justify-content-center">
                      <h4>
                        <Spinner animation="border" size="lg" />
                      </h4>
                    </div>
                  ) : (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Usuario</th>
                          <th scope="col">Nombre(s)</th>
                          <th scope="col">Apellido Paterno</th>
                          <th scope="col">Apellido Materno</th>
                          <th scope="col">Rol</th>
                          <th scope="col">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr key={user.id}>
                            <td>
                              <strong>{index + 1}</strong>
                            </td>

                            <td>
                              <b>{user.username}</b>
                            </td>

                            <td>
                              <strong>{user.nombre}</strong>
                            </td>

                            <td>
                              <strong>{user.apellidoP}</strong>
                            </td>

                            <td>
                              <strong>{user.apellidoM}</strong>
                            </td>

                            <td>
                              <strong>
                                {user.roles.map((role) => role.name.slice(5))}
                              </strong>
                            </td>

                            <td>
                              {showAdminBoard && (
                                <Button
                                  variant="error"
                                  size="sm"
                                  disabled={currentUser === user.username}
                                  onClick={() => this.openModalBorrar(user.id)}
                                >
                                  <ImCross />
                                </Button>
                              )}

                              <Link
                                to={showAdminBoard ? "/user/" + user.id : "#"}
                              >
                                <OverlayTrigger
                                  overlay={<Tooltip>Editar</Tooltip>}
                                >
                                  <Button
                                    variant="gob"
                                    size="sm"
                                    disabled={!showAdminBoard}
                                  >
                                    <AiFillEdit />
                                  </Button>
                                </OverlayTrigger>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}

                  <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      {this.state.isBorrar ? (
                        <Modal.Title>
                          <div className="text-center">
                            ¿Esta seguro/a de <strong>BORRAR</strong> este
                            usuario?
                            <p className="nocolor smalltext">&zwnj;</p>
                            <Button
                              className="m-2"
                              variant="secondary"
                              onClick={this.closeModal}
                            >
                              Cancelar
                            </Button>
                            <Button
                              className="m-2"
                              variant="error"
                              onClick={() => this.deleteUser(this.state.userId)}
                            >
                              <ImCross /> <strong>BORRAR</strong>
                            </Button>
                            {this.state.messageError ? (
                              <>
                                <p className="nocolor smalltext">&zwnj;</p>
                                <h5 className="redtext bolder centeralign">
                                  ERROR
                                </h5>
                                <p className="redtext bigtext">
                                  {this.state.messageError}
                                </p>
                              </>
                            ) : null}
                          </div>
                        </Modal.Title>
                      ) : null}
                    </Modal.Header>
                  </Modal>
                </Col>
              </Row>
            </div>
          ) : (
            <h3 className="content w-100 cont">
              <strong>USUARIO NO PERMITIDO</strong>
            </h3>
          )}
        </div>
      </>
    );
  }
}
