import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBus from "../../../../common/EventBus";
import Navbar from "../../../../bars/Navbar";
import Sidebar from "../../../../bars/Sidebar";
import WithRouter from "../../../../common/WithRouter";
import Dropzone from "react-dropzone";
import {
  Alert,
  Row,
  Col,
  Form,
  Stack,
  Button,
  Modal,
  Fade,
  Carousel,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  FormControl,
  Spinner,
  Badge,
  Popover,
} from "react-bootstrap";
import ReporteService from "../../../../services/ReporteService";
import AuthService from "../../../../services/AuthService";
import MunicipioService from "../../../../services/MunicipioService";
import UserService from "../../../../services/UserService";

import { ImArrowLeft2, ImCross, ImPlus, ImHome } from "react-icons/im";
import {
  MdDownload,
  MdDoubleArrow,
  MdEditNote,
  MdList,
  MdEdit,
  MdOutlineDoNotDisturbAlt,
} from "react-icons/md";
import {
  FaPaste,
  FaMapMarkerAlt,
  FaLeaf,
  FaUsers,
  FaQuestionCircle,
  FaExclamationTriangle,
  FaCheckCircle,
  FaCompressArrowsAlt,
} from "react-icons/fa";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsFillCloudDrizzleFill, BsCheckLg, BsEyeFill } from "react-icons/bs";
import { GiEarthCrack, GiSmallFire } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";

const variants = [
  "primary",
  "warning",
  "success",
  "purple",
  "danger",
  "secondary",
  "info",
];
const icons = [
  <BsFillCloudDrizzleFill />,
  <GiEarthCrack />,
  <FaLeaf />,
  <FaUsers />,
  <GiSmallFire />,
  <AiOutlinePaperClip />,
  <BiWorld />,
];
const colors = [
  "text-primary paragraph",
  "text-warning paragraph",
  "text-success paragraph",
  "purpletext paragraph",
  "text-danger paragraph",
  "text-secondary paragraph",
  "text-info paragraph",
];
const outlineButtons = [
  "outline-primary",
  "outline-warning",
  "outline-success",
  "outline-purple",
  "outline-danger",
  "outline-secondary",
  "outline-info",
];
const monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}
var nivelesEmergencia = range(1, 3);

const niveles = ["nivel1", "nivel2", "nivel3"];

const fechaActual = new Date();
let year = new Date(fechaActual).getFullYear();
let month = new Date(fechaActual).getMonth() + 1;
let day = new Date(fechaActual).getDate();

let mesActual;
let diaActual;

if (month < 10) {
  mesActual = "0" + month;
} else {
  mesActual = month;
}

if (day < 10) {
  diaActual = "0" + day;
} else {
  diaActual = day;
}

class HidGHI extends Component {
  constructor(props) {
    super(props);
    this.updateEspecifico = this.updateEspecifico.bind(this);
    this.onChangeUbicaciones = this.onChangeUbicaciones.bind(this);
    this.onChangeUbicacionesN = this.onChangeUbicacionesN.bind(this);
    this.onChangePersonasAtrapadas = this.onChangePersonasAtrapadas.bind(this);
    this.onChangePersonasAtrapadasN =
      this.onChangePersonasAtrapadasN.bind(this);
    this.onChangePersonasLesionadas =
      this.onChangePersonasLesionadas.bind(this);
    this.onChangePersonasLesionadasN =
      this.onChangePersonasLesionadasN.bind(this);
    this.onChangePersonasFallecidas =
      this.onChangePersonasFallecidas.bind(this);
    this.onChangePersonasFallecidasN =
      this.onChangePersonasFallecidasN.bind(this);
    this.onChangeFechaTermino = this.onChangeFechaTermino.bind(this);
    this.onChangeEstimado2 = this.onChangeEstimado2.bind(this);

    this.getSeguimientos = this.getSeguimientos.bind(this);
    this.saveSeguimiento = this.saveSeguimiento.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.editSeguimiento = this.editSeguimiento.bind(this);
    this.updateSeguimiento = this.updateSeguimiento.bind(this);
    this.deleteSeguimiento = this.deleteSeguimiento.bind(this);
    this.onChangeFechaSeguimiento = this.onChangeFechaSeguimiento.bind(this);
    this.onChangeSeguimiento = this.onChangeSeguimiento.bind(this);

    this.resetRefugio = this.resetRefugio.bind(this);

    this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
    this.onChangeLocalidad = this.onChangeLocalidad.bind(this);
    this.onChangeLocalidadList = this.onChangeLocalidadList.bind(this);
    this.onChangeEvento = this.onChangeEvento.bind(this);
    this.onChangeReporta = this.onChangeReporta.bind(this);
    this.onChangeFechaReporte = this.onChangeFechaReporte.bind(this);
    this.onChangeCargo = this.onChangeCargo.bind(this);
    this.onChangeFechaOcurrencia = this.onChangeFechaOcurrencia.bind(this);
    this.onChangeEstimado = this.onChangeEstimado.bind(this);
    this.onChangeEstatus = this.onChangeEstatus.bind(this);
    this.onChangeNivelEmergencia = this.onChangeNivelEmergencia.bind(this);
    this.onChangeLatitud = this.onChangeLatitud.bind(this);
    this.onChangeLongitud = this.onChangeLongitud.bind(this);
    this.pasteLatitudLongitud = this.pasteLatitudLongitud.bind(this);
    this.errPegado = this.errPegado.bind(this);
    this.getRefugios = this.getRefugios.bind(this);
    this.onChangeRefugio = this.onChangeRefugio.bind(this);
    this.onChangeFechaActivacion = this.onChangeFechaActivacion.bind(this);
    this.onChangeFechaDesactivacion =
      this.onChangeFechaDesactivacion.bind(this);

    this.onChangeAccionesImplementadas =
      this.onChangeAccionesImplementadas.bind(this);
    this.onChangeFuerzaTarea = this.onChangeFuerzaTarea.bind(this);
    this.onChangeNotas = this.onChangeNotas.bind(this);
    this.getReporte = this.getReporte.bind(this);
    this.getMunicipios = this.getMunicipios.bind(this);
    this.getLocalidades = this.getLocalidades.bind(this);
    this.updateReporte = this.updateReporte.bind(this);
    this.deleteReporte = this.deleteReporte.bind(this);
    this.openModalActualizar = this.openModalActualizar.bind(this);
    this.openModalBorrar = this.openModalBorrar.bind(this);
    this.openModalActualizarSeguimiento =
      this.openModalActualizarSeguimiento.bind(this);
    this.openModalBorrarSeguimiento =
      this.openModalBorrarSeguimiento.bind(this);
    this.openModalBorrarFile = this.openModalBorrarFile.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.getUser = this.getUser.bind(this);
    this.getEstatusList = this.getEstatusList.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      municipios: [],
      localidades: [],
      files: [],
      seguimientos: [],
      seguimientosN: null,
      refugios: [],
      estatusList: [],

      idEspecifico: null,

      currentEspecifico: {
        id: null,
        ubicaciones: null,
        ubicacionesN: null,
        personasAtrapadas: null,
        personasAtrapadasN: null,
        personasLesionadas: null,
        personasLesionadasN: null,
        personasFallecidas: null,
        personasFallecidasN: null,
        fechaTermino: null,
        estimado2: null,
      },

      currentReporte: {
        id: null,
        tipo: null,
        folio: null,
        evento: null,
        fechaReporte: null,
        estimado: null,
        reporta: null,
        cargo: null,
        fechaOcurrencia: null,
        latitud: null,
        longitud: null,
        estatus: {
          id: null,
          estatus: null,
        },
        nivelEmergencia: null,
        accionesImplementadas: null,
        fuerzaTarea: null,
        notas: null,
        evidencia: null,
        tipoR: {
          id: null,
          nombreT: null,
          fenomeno: {
            id: null,
            nombreF: null,
          },
        },
        municipio: {
          id: null,
          municipio: null,
          region: {
            id: null,
            region: null,
          },
        },
        localidad: null,
        refugio: {
          id: null,
          nombre: null,
          direccion: null,
          estatus: null,
          capacidad: null,
          municipio: {
            id: null,
            municipio: null,
            region: {
              id: null,
              region: null,
            },
          },
        },
        fechaActivacion: null,
        fechaDesactivacion: null,
      },

      localidadList: true,

      fechaMin: null,
      fechaMinReporte: null,

      fechaSeguimiento: year + "-" + mesActual + "-" + diaActual,
      seguimiento: null,

      fechaMostrar: null,

      editar: false,

      camposFaltantes: null,
      camposFaltantesSeguimiento: true,

      selectedFiles: undefined,
      progressInfos: [],
      message: [],
      message2: null,
      fileInfos: null,

      errPegado: false,
      posicionLa: false,
      posicionLo: false,
      cargandoReporte: true,

      cargando: false,
      error: false,

      open: false,
      isOpen: false,
      isBorrar: false,
      isActualizar: false,
      isBorrarSeguimiento: false,
      isActualizarSeguimiento: false,
      isBorrarFile: false,

      showAdminBoard: false,
      showModeratorBoard: false,

      index: 0,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      this.getUser(currentUser.id);

      this.setState({
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
        showModeratorBoard: currentUser.roles.includes("ROLE_MODERATOR"),
      });

      this.getReporte(this.props.router.params.id);
      this.getFiles(this.props.router.params.id);
      this.getSeguimientos(this.props.router.params.id);

      setTimeout(() => {
        this.handlePosicion();
      }, 50);
    }
  }

  onChangeSeguimiento(e) {
    this.setState({
      seguimiento: e.target.value,
    });
  }

  onChangeFechaSeguimiento(e) {
    this.setState({
      fechaSeguimiento: e.target.value,
    });
  }

  getSeguimientos(idSeguimiento) {
    ReporteService.getReporteSeguimientos(idSeguimiento)
      .then((response) => {
        this.setState({
          seguimientos: response.data.seguimiento,
          seguimientosN: response.data.totalItems,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
          err = "Usuario no identificado";
        } else {
          this.setState({
            message2: "Error en la carga de seguimientos: " + err,
            error: true,
            open: true,
            cargando: false,
          });
        }
      });
  }

  saveSeguimiento() {
    this.setState({
      isOpen: false,
      cargando: true,
    });

    var data = {
      fechaSeguimiento: this.state.seguimiento
        ? this.state.fechaSeguimiento + "T12:00:00"
        : null,
      seguimiento: this.state.seguimiento,

      reporte: this.state.currentReporte,
    };

    ReporteService.createSeguimiento(data)
      .then(() => {
        this.setState({
          error: false,
          message2: "Seguimiento creado correctamente",
          open: true,
          cargando: false,
          seguimiento: "",
          camposFaltantesSeguimiento: false,
          fechaSeguimiento: year + "-" + mesActual + "-" + diaActual,
        });

        this.getSeguimientos(this.props.router.params.id);

        setTimeout(() => {
          this.setState({
            camposFaltantesSeguimiento: true,
          });
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              message2: "Error de Seguimiento. Reintentando guardar...",
              error: true,
              open: true,
              cargando: false,
            },
            setTimeout(() => {
              this.saveSeguimiento();
            }, 1000)
          );
          console.log(err);
        }
      });
  }

  deleteSeguimiento(id) {
    ReporteService.deleteSeguimiento(id).then(() => {
      this.setState({
        error: false,
        message2: "Seguimiento borrado correctamente",
        open: true,
        camposFaltantesSeguimiento: !this.state.camposFaltantesSeguimiento,
      });
      this.getSeguimientos(this.props.router.params.id);
      this.closeModal();

      setTimeout(() => {
        this.setState({
          camposFaltantesSeguimiento: !this.state.camposFaltantesSeguimiento,
        });
        window.scrollTo(0, document.body.scrollHeight);
      }, 200);
    });
  }

  cancelEdit() {
    this.setState({
      message2: "",
      cargando: false,
      seguimiento: "",
      camposFaltantesSeguimiento: true,
      fechaSeguimiento: year + "-" + mesActual + "-" + diaActual,
      fechaMostrar: null,
      editar: false,
    });
    this.getSeguimientos(this.props.router.params.id);
    this.handleKeypressSeguimiento();

    setTimeout(() => {
      this.setState({
        camposFaltantesSeguimiento: true,
      });
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);
  }

  editSeguimiento(seguimientoId, fechaSeguimiento, seguimiento) {
    let year = new Date(fechaSeguimiento).getFullYear();
    let month = new Date(fechaSeguimiento).getMonth() + 1;
    let day = new Date(fechaSeguimiento).getDate();

    let mesActual;
    let diaActual;

    if (month < 10) {
      mesActual = "0" + month;
    } else {
      mesActual = month;
    }

    if (day < 10) {
      diaActual = "0" + day;
    } else {
      diaActual = day;
    }

    setTimeout(() => {
      this.setState({
        camposFaltantesSeguimiento: false,
      });
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);

    this.setState({
      seguimientoId: seguimientoId,
      fechaSeguimiento: year + "-" + mesActual + "-" + diaActual,
      fechaMostrar: fechaSeguimiento,
      seguimiento: seguimiento,
      editar: true,
      camposFaltantesSeguimiento: false,
    });
  }

  updateSeguimiento(id) {
    this.setState({
      cargando: true,
    });

    var data = {
      fechaSeguimiento: this.state.seguimiento
        ? this.state.fechaSeguimiento + "T12:00:00"
        : null,
      seguimiento: this.state.seguimiento,

      reporte: this.state.currentReporte,
    };

    ReporteService.updateSeguimiento(id, data)
      .then(() => {
        this.setState({
          error: false,
          message2: "Seguimiento editado correctamente",
          open: true,
          cargando: false,
          seguimiento: "",
          camposFaltantesSeguimiento: false,
          fechaSeguimiento: year + "-" + mesActual + "-" + diaActual,
          editar: false,
        });
        this.getSeguimientos(this.props.router.params.id);
        this.closeModal();

        setTimeout(() => {
          this.setState({
            camposFaltantesSeguimiento: true,
          });
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              message2: "Error de Seguimiento. Reintentando guardar...",
              error: true,
              open: true,
              cargando: false,
            },
            setTimeout(() => {
              this.updateSeguimiento(id);
            }, 1000)
          );
          console.log(err);
        }
      });
  }

  onChangeUbicaciones(e) {
    const ubicaciones = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          ubicaciones: ubicaciones,
        },
      };
    });
  }

  onChangeUbicacionesN(e) {
    const ubicacionesN = e.target.value.replace(/[^0-9]/gi, "");

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          ubicacionesN: ubicacionesN,
        },
      };
    });
  }

  onChangePersonasAtrapadas(e) {
    const personasAtrapadas = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          personasAtrapadas: personasAtrapadas,
        },
      };
    });
  }

  onChangePersonasAtrapadasN(e) {
    const personasAtrapadasN = e.target.value.replace(/[^0-9]/gi, "");

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          personasAtrapadasN: personasAtrapadasN,
        },
      };
    });
  }

  onChangePersonasLesionadas(e) {
    const personasLesionadas = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          personasLesionadas: personasLesionadas,
        },
      };
    });
  }

  onChangePersonasLesionadasN(e) {
    const personasLesionadasN = e.target.value.replace(/[^0-9]/gi, "");

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          personasLesionadasN: personasLesionadasN,
        },
      };
    });
  }

  onChangePersonasFallecidas(e) {
    const personasFallecidas = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          personasFallecidas: personasFallecidas,
        },
      };
    });
  }

  onChangePersonasFallecidasN(e) {
    const personasFallecidasN = e.target.value.replace(/[^0-9]/gi, "");

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          personasFallecidasN: personasFallecidasN,
        },
      };
    });
  }

  onChangeFechaTermino(e) {
    const fechaTermino = e.target.value;

    this.setState(function (prevState) {
      return {
        currentEspecifico: {
          ...prevState.currentEspecifico,
          fechaTermino: fechaTermino,
        },
      };
    });
  }

  onChangeEstimado2() {
    if (this.state.currentEspecifico.estimado2) {
      this.setState(function (prevState) {
        return {
          currentEspecifico: {
            ...prevState.currentEspecifico,
            estimado2: false,
          },
        };
      });
    } else {
      this.setState(function (prevState) {
        return {
          currentEspecifico: {
            ...prevState.currentEspecifico,
            estimado2: true,
          },
        };
      });
    }
  }

  onChangeMunicipio(e) {
    this.getLocalidades(e.target.value);
    this.getRefugios(e.target.value);

    MunicipioService.getMunicipio(e.target.value)
      .then((response) => {
        this.setState(function (prevState) {
          return {
            municipioN: e.target.value,

            refugioN: null,
            currentReporte: {
              ...prevState.currentReporte,
              municipio: response.data,
              localidad: null,
              refugio: null,
            },
          };
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            message2:
              "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            error: true,
            open: true,
            cargando: false,
          });
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 200);
          console.log(err);
        }
      });
  }

  onChangeLocalidad(e) {
    const localidad = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          localidad: localidad,
        },
      };
    });
  }

  onChangeLocalidadList() {
    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
        },
        localidadList: !this.state.localidadList,
      };
    });
  }

  onChangeEvento(e) {
    const evento = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          evento: evento,
        },
      };
    });
  }

  onChangeReporta(e) {
    const reporta = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          reporta: reporta,
        },
      };
    });
  }

  onChangeFechaReporte(e) {
    const fechaReporte = e.target.value;

    this.setState(function (prevState) {
      return {
        fechaMinReporte:
          new Date(fechaReporte).getFullYear() +
          "-" +
          (new Date(fechaReporte).getMonth() + 1 < 10
            ? "0" + (new Date(fechaReporte).getMonth() + 1)
            : new Date(fechaReporte).getMonth() + 1) +
          "-" +
          (new Date(fechaReporte).getDate() < 10
            ? "0" + new Date(fechaReporte).getDate()
            : new Date(fechaReporte).getDate()),
        currentReporte: {
          ...prevState.currentReporte,
          fechaReporte: fechaReporte,
        },
      };
    });
  }

  onChangeCargo(e) {
    const cargo = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          cargo: cargo,
        },
      };
    });
  }

  onChangeFechaOcurrencia(e) {
    const fechaOcurrencia = e.target.value;

    this.setState(function (prevState) {
      return {
        fechaMin:
          new Date(fechaOcurrencia).getFullYear() +
          "-" +
          (new Date(fechaOcurrencia).getMonth() + 1 < 10
            ? "0" + (new Date(fechaOcurrencia).getMonth() + 1)
            : new Date(fechaOcurrencia).getMonth() + 1) +
          "-" +
          (new Date(fechaOcurrencia).getDate() < 10
            ? "0" + new Date(fechaOcurrencia).getDate()
            : new Date(fechaOcurrencia).getDate()),
        currentReporte: {
          ...prevState.currentReporte,
          fechaOcurrencia: fechaOcurrencia,
        },
      };
    });
  }

  onChangeLatitud(e) {
    const latitud = e.target.value.replace(/[^\d.]/g, "");

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          latitud: latitud,
        },
      };
    });
  }

  onChangeLongitud(e) {
    const longitud = e.target.value.replace(/[^\d.]/g, "");

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          longitud: longitud,
        },
      };
    });
  }

  pasteLatitudLongitud() {
    navigator.clipboard
      .readText()
      .then((response) => {
        var paste = response.split(", -");

        if (paste.length > 1) {
          this.setState(function (prevState) {
            setTimeout(() => {
              this.handlePosicion();
            }, 20);
            return {
              currentReporte: {
                ...prevState.currentReporte,
                latitud: paste[0].slice(0, 13),
                longitud: paste[1].slice(0, 13),
              },
            };
          });
        } else {
          this.errPegado();
        }
      })
      .catch((err) => console.log(err));
  }

  errPegado() {
    this.setState({
      errPegado: true,
    });

    setTimeout(() => {
      this.setState({
        errPegado: false,
      });
    }, 1500);
  }

  onChangeEstimado() {
    if (this.state.currentReporte.estimado) {
      this.setState(function (prevState) {
        return {
          currentReporte: {
            ...prevState.currentReporte,
            estimado: false,
          },
        };
      });
    } else {
      this.setState(function (prevState) {
        return {
          currentReporte: {
            ...prevState.currentReporte,
            estimado: true,
          },
        };
      });
    }
  }

  onChangeAccionesImplementadas(e) {
    const accionesImplementadas = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          accionesImplementadas: accionesImplementadas,
        },
      };
    });
  }

  onChangeFuerzaTarea(e) {
    const fuerzaTarea = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          fuerzaTarea: fuerzaTarea,
        },
      };
    });
  }

  onChangeNotas(e) {
    const notas = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          notas: notas,
        },
      };
    });
  }

  onChangeEstatus(e) {
    const estatus = e.target.value - 1;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          estatus: this.state.estatusList[estatus],
        },
      };
    });
  }

  onChangeNivelEmergencia(e) {
    const nivelEmergencia = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          nivelEmergencia: nivelEmergencia,
        },
      };
    });
  }

  onChangeRefugio(e) {
    ReporteService.getRefugio(e.target.value)
      .then((response) => {
        this.setState(function (prevState) {
          return {
            refugioN: response.data.id,
            currentReporte: {
              ...prevState.currentReporte,
              refugio: response.data,
            },
          };
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            message2:
              "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            error: true,
            open: true,
            cargando: false,
          });
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 200);
          console.log(err);
        }
      });
  }

  onChangeFechaActivacion(e) {
    const fechaActivacion = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          fechaActivacion: fechaActivacion,
        },
      };
    });
  }

  onChangeFechaDesactivacion(e) {
    const fechaDesactivacion = e.target.value;

    this.setState(function (prevState) {
      return {
        currentReporte: {
          ...prevState.currentReporte,
          fechaDesactivacion: fechaDesactivacion,
        },
      };
    });
  }

  resetRefugio() {
    this.setState(function (prevState) {
      return {
        refugioN: null,
        currentReporte: {
          ...prevState.currentReporte,
          refugio: null,
        },
      };
    });
  }

  getRefugios(municipio) {
    ReporteService.getRefugiosActivos(municipio)
      .then((response) => {
        this.setState({
          refugios: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  getEstatusList() {
    ReporteService.getEstatusList()
      .then((response) => {
        this.setState({
          estatusList: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  getUser(id) {
    UserService.getUser(id)
      .then((response) => {
        this.setState({
          user: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/reportesList");
        } else {
          console.log(err);
        }
      });
  }

  getReporte(id) {
    ReporteService.getReporteCompleto(id)
      .then((response) => {
        switch (response.data.reporte.tipoR.id) {
          case 7:
          case 8:
          case 9:
            this.setState({
              cargandoReporte: false,
              idEspecifico: response.data.id,
              currentReporte: response.data.reporte,
              currentEspecifico: response.data,
              fechaMin:
                new Date(response.data.reporte.fechaOcurrencia).getFullYear() +
                "-" +
                (new Date(response.data.reporte.fechaOcurrencia).getMonth() +
                  1 <
                10
                  ? "0" +
                    (new Date(
                      response.data.reporte.fechaOcurrencia
                    ).getMonth() +
                      1)
                  : new Date(response.data.reporte.fechaOcurrencia).getMonth() +
                    1) +
                "-" +
                (new Date(response.data.reporte.fechaOcurrencia).getDate() < 10
                  ? "0" +
                    new Date(response.data.reporte.fechaOcurrencia).getDate()
                  : new Date(response.data.reporte.fechaOcurrencia).getDate()),
              fechaMinReporte:
                new Date(response.data.reporte.fechaReporte).getFullYear() +
                "-" +
                (new Date(response.data.reporte.fechaReporte).getMonth() + 1 <
                10
                  ? "0" +
                    (new Date(response.data.reporte.fechaReporte).getMonth() +
                      1)
                  : new Date(response.data.reporte.fechaReporte).getMonth() +
                    1) +
                "-" +
                (new Date(response.data.reporte.fechaReporte).getDate() < 10
                  ? "0" + new Date(response.data.reporte.fechaReporte).getDate()
                  : new Date(response.data.reporte.fechaReporte).getDate()),
            });
            this.getLocalidades(response.data.reporte.municipio.id);
            setTimeout(() => {
              this.handlePosicion();
            }, 20);
            document.title =
              "SICECOM - Editar reporte " + response.data.reporte.folio;
            break;

          default:
            this.props.router.navigate("/reportesList");
        }
      })
      .catch((err) => {
        document.title = "SICECOM - Reporte ERROR";

        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 404) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/reportesList");
        } else {
          this.setState({
            cargandoReporte: false,
          });
          console.log(err);
        }
      });
  }

  getFiles(id) {
    ReporteService.getReporteFiles(id)
      .then((response) => {
        this.setState({
          files: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getMunicipios() {
    MunicipioService.getMunicipios()
      .then((response) => {
        this.setState({
          municipios: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getLocalidades(municipio) {
    MunicipioService.getLocalidad(municipio)
      .then((response) => {
        this.setState({
          localidades: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
    }
  }

  uploadFiles() {
    const selectedFiles = this.state.selectedFiles;
    const folio = this.state.currentReporte.folio;

    let _progressInfos = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }
    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i], folio);
        }
      }
    );
  }

  upload(idx, file, folio) {
    let _progressInfos = [...this.state.progressInfos];
    ReporteService.upload(
      file,
      (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({
          _progressInfos,
        });
      },
      folio
    )
      .then((response) => {
        this.setState((prev) => {
          let nextMessage = [...prev.message, file.name + ", "];
          return {
            message: nextMessage,
          };
        });
        return ReporteService.getFiles();
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
        this.getFiles(this.props.router.params.id);
      })
      .catch((e) => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [...prev.message, e + ": " + file.name + " "];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  }

  handlePosicion = () => {
    if (
      this.state.currentReporte.latitud < 22.5 &&
      this.state.currentReporte.latitud > 17.12
    ) {
      this.setState({
        posicionLa: true,
      });
    } else {
      this.setState({
        posicionLa: false,
      });
    }
    if (
      this.state.currentReporte.longitud < 98.71 &&
      Math.abs(this.state.currentReporte.longitud) > 93.59
    ) {
      this.setState({
        posicionLo: true,
      });
    } else {
      this.setState({
        posicionLo: false,
      });
    }

    setTimeout(() => {
      this.handleKeypress();
    }, 50);
  };

  handleKeypressSeguimiento = () => {
    if (
      this.state.currentReporte &&
      this.state.fechaSeguimiento &&
      this.state.seguimiento
    ) {
      this.setState({
        camposFaltantesSeguimiento: false,
      });
    } else {
      this.setState({
        camposFaltantesSeguimiento: true,
      });
    }
  };

  handleKeypress = () => {
    if (
      this.state.currentReporte.estatus &&
      this.state.currentReporte.nivelEmergencia &&
      this.state.currentReporte.municipio &&
      this.state.currentReporte.localidad &&
      this.state.currentReporte.reporta &&
      this.state.currentReporte.fechaReporte &&
      this.state.currentReporte.fechaOcurrencia &&
      (this.state.posicionLa || !this.state.currentReporte.latitud) &&
      (this.state.posicionLo || !this.state.currentReporte.longitud) &&
      this.state.currentReporte.accionesImplementadas &&
      this.state.currentReporte.fuerzaTarea &&
      this.state.currentEspecifico.ubicaciones &&
      this.state.currentEspecifico.ubicacionesN
    ) {
      this.setState({
        camposFaltantes: false,
      });
    } else {
      this.setState({
        camposFaltantes: true,
      });
    }
  };

  openModalActualizar() {
    this.setState({
      isOpen: true,
      isActualizar: true,
      isBorrar: false,
      isBorrarSeguimiento: false,
      isActualizarSeguimiento: false,
      isBorrarFile: false,
    });
  }

  openModalBorrar() {
    this.setState({
      isOpen: true,
      isActualizar: false,
      isBorrar: true,
      isBorrarSeguimiento: false,
      isActualizarSeguimiento: false,
      isBorrarFile: false,
    });
  }

  openModalActualizarSeguimiento(seguimientoId, fechaSeguimientoId) {
    this.setState({
      seguimientoId: seguimientoId,
      fechaSeguimientoId: fechaSeguimientoId,
      isOpen: true,
      isActualizar: false,
      isBorrar: false,
      isBorrarSeguimiento: false,
      isActualizarSeguimiento: true,
      isBorrarFile: false,
    });
  }

  openModalBorrarSeguimiento(seguimientoId, fechaSeguimientoId) {
    this.setState({
      seguimientoId: seguimientoId,
      fechaSeguimientoId: fechaSeguimientoId,
      isOpen: true,
      isActualizar: false,
      isBorrar: false,
      isBorrarSeguimiento: true,
      isActualizarSeguimiento: false,
      isBorrarFile: false,
    });
  }

  openModalBorrarFile(fileId) {
    this.setState({
      fileId: fileId,
      isOpen: true,
      isActualizar: false,
      isBorrar: false,
      isBorrarSeguimiento: false,
      isActualizarSeguimiento: false,
      isBorrarFile: true,
    });
  }

  closeModal() {
    this.setState({
      isOpen: false,
      isActualizar: false,
      isBorrar: false,
      isBorrarSeguimiento: false,
      isActualizarSeguimiento: false,
      isBorrarFile: false,
    });
  }

  setOpen() {
    this.setState({
      open: true,
    });
  }

  setClose() {
    this.setState({
      open: false,
    });
  }

  updateEspecifico() {
    var data = {
      id: this.state.currentEspecifico.id,
      ubicaciones: this.state.currentEspecifico.ubicaciones,
      ubicacionesN: this.state.currentEspecifico.ubicacionesN || 0,
      personasAtrapadas: this.state.currentEspecifico.personasAtrapadas,
      personasAtrapadasN: this.state.currentEspecifico.personasAtrapadasN || 0,
      personasLesionadas: this.state.currentEspecifico.personasLesionadas,
      personasLesionadasN:
        this.state.currentEspecifico.personasLesionadasN || 0,
      personasFallecidas: this.state.currentEspecifico.personasFallecidas,
      personasFallecidasN:
        this.state.currentEspecifico.personasFallecidasN || 0,
      fechaTermino: this.state.currentEspecifico.fechaTermino,
      estimado2: this.state.currentEspecifico.estimado2,

      reporte: this.state.currentReporte,
    };

    ReporteService.updateHIDRO_GHI(this.state.idEspecifico, data)
      .then(() => {
        this.setState({
          error: false,
          message2: "Reporte actualizado correctamente",
          open: true,
          selectedFiles: null,
        });
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 20);
        this.getReporte(this.props.router.params.id);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            error: true,
            message2: "Error de guardado HidGHI: " + err,
            open: true,
          });
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 20);
        }
      });
  }

  updateReporte() {
    this.setState({
      message: "",
      message2: "",
      open: false,
      isOpen: false,
    });

    var data = {
      id: this.state.currentReporte.id,
      tipo: this.state.currentReporte.tipo,
      municipio: this.state.currentReporte.municipio,
      folio: this.state.currentReporte.folio,
      localidad: this.state.currentReporte.localidad
        ? this.state.currentReporte.localidad.charAt(0).toUpperCase() +
          this.state.currentReporte.localidad.slice(1)
        : null,
      evento: this.state.currentReporte.evento,
      fechaReporte: this.state.currentReporte.fechaReporte,
      estimado: this.state.currentReporte.estimado,
      reporta: this.state.currentReporte.reporta
        ? this.state.currentReporte.reporta.charAt(0).toUpperCase() +
          this.state.currentReporte.reporta.slice(1)
        : null,
      cargo: this.state.currentReporte.cargo,
      latitud: this.state.currentReporte.latitud,
      longitud: this.state.currentReporte.longitud
        ? this.state.currentReporte.longitud < 0
          ? this.state.currentReporte.longitud
          : "-" + this.state.currentReporte.longitud
        : null,
      fechaOcurrencia: this.state.currentReporte.fechaOcurrencia,
      accionesImplementadas: this.state.currentReporte.accionesImplementadas,
      fuerzaTarea: this.state.currentReporte.fuerzaTarea,
      notas: this.state.currentReporte.notas,
      evidencia: this.state.currentReporte.evidencia,
      tipoR: this.state.currentReporte.tipoR,

      estatus: this.state.currentReporte.estatus,
      nivelEmergencia: this.state.currentReporte.nivelEmergencia,

      user: this.state.user,
      refugio: this.state.currentReporte.refugio,
      fechaActivacion: this.state.currentReporte.fechaActivacion,
      fechaDesactivacion: this.state.currentReporte.fechaDesactivacion,
    };

    ReporteService.updateReporte(this.state.currentReporte.id, data)
      .then(() => {
        if (this.state.selectedFiles) {
          this.uploadFiles();
        }
        this.updateEspecifico();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            error: true,
            message2: "Error de guardado: " + err,
            open: true,
          });
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 200);
        }
      });
  }

  deleteReporte() {
    this.setState({
      cargando: true,
    });

    ReporteService.deleteReporte(this.state.currentReporte.id)
      .then(() => {
        this.props.router.navigate("/reportesList");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            cargando: false,
            error: true,
            message2: "Error en el borrado: " + err,
            open: true,
          });
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 200);
        }
      });
  }

  deleteFile(filename) {
    ReporteService.deleteFile(filename)
      .then(() => {
        this.setState({
          error: false,
          message2: "Imágen borrada correctamente",
          open: true,
          index:
            this.state.index === 0 ? this.state.index : this.state.index - 1,
        });
        this.getFiles(this.props.router.params.id);
        this.closeModal();
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 200);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            error: true,
            message2: err,
            open: true,
          });
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 200);
        }
      });
  }

  handleSelect(selectedIndex) {
    this.setState({
      index: selectedIndex,
    });
  }

  goBack = () => {
    this.props.router.navigate(-1);
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  render() {
    const {
      currentReporte,
      currentEspecifico,
      error,
      seguimientos,
      selectedFiles,
      showAdminBoard,
      showModeratorBoard,
    } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                {this.state.cargandoReporte ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Alert
                    variant={variants[currentReporte.tipoR.fenomeno.id - 1]}
                  >
                    <div className="m-1 d-grid gap-2 d-md-block">
                      <Row>
                        <Col xl="10" sm="12">
                          <h3>
                            <b>
                              {(!currentReporte.estatus
                                ? null
                                : currentReporte.estatus.id === 1
                                ? ""
                                : currentReporte.estatus.estatus + " A ") +
                                currentReporte.tipoR.nombreT.toUpperCase()}

                              {currentReporte.nivelEmergencia ? (
                                <>
                                  {" "}
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip>
                                        {"Nivel de emergencia " +
                                          currentReporte.nivelEmergencia}
                                      </Tooltip>
                                    }
                                  >
                                    <b>
                                      <Button
                                        variant="nocolor"
                                        className={
                                          niveles[
                                            currentReporte.nivelEmergencia - 1
                                          ]
                                        }
                                        size="sm"
                                        style={{ pointerEvents: "none" }}
                                      >
                                        {"NIVEL " +
                                          currentReporte.nivelEmergencia}
                                      </Button>
                                    </b>
                                  </OverlayTrigger>
                                </>
                              ) : null}
                            </b>
                          </h3>
                          <h4
                            className={
                              colors[currentReporte.tipoR.fenomeno.id - 1]
                            }
                          >
                            <Spinner
                              animation="grow"
                              size="sm"
                              className={
                                colors[currentReporte.tipoR.fenomeno.id - 1]
                              }
                            />
                            <b> ACTUALIZAR REPORTE </b>
                          </h4>
                        </Col>
                        <Col xl="2" sm="12">
                          <h3
                            className={
                              colors[currentReporte.tipoR.fenomeno.id - 1]
                            }
                          >
                            <b>{currentReporte.folio}</b>
                            {icons[currentReporte.tipoR.fenomeno.id - 1]}
                            {currentReporte.estatus ? (
                              <>
                                {" "}
                                <Button
                                  variant={
                                    variants[
                                      currentReporte.tipoR.fenomeno.id - 1
                                    ]
                                  }
                                  size="sm"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <b>
                                    {currentReporte.estatus.estatus}
                                    {currentReporte.estatus.id === 1 ||
                                    currentReporte.estatus.id === 4 ? (
                                      <>
                                        {" "}
                                        <BsCheckLg />
                                      </>
                                    ) : null}
                                  </b>
                                </Button>
                              </>
                            ) : null}
                          </h3>
                        </Col>
                        {currentReporte.user ? (
                          <Col xs="12">
                            <strong>
                              {"Última modificación: "}
                              <strong
                                className={
                                  colors[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {currentReporte.user.username
                                  ? currentReporte.user.nombre +
                                    " " +
                                    currentReporte.user.apellidoP +
                                    " " +
                                    currentReporte.user.apellidoM
                                  : null}
                              </strong>
                            </strong>
                          </Col>
                        ) : null}

                        <hr />

                        <Col xs="12">
                          <h5>
                            <i>
                              {
                                "Por favor rellene los campos que desee actualizar... "
                              }
                            </i>
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Popover>
                                  <Popover.Header as="h3">
                                    <b>Ayuda </b> <FaQuestionCircle />
                                  </Popover.Header>
                                  <Popover.Body>
                                    {
                                      " - Para realizar un seguimiento debe actualizar el estatus del reporte e ir a la sección 'Agregar seguimiento'"
                                    }
                                    <br />
                                    {
                                      " - Únicamente serán actualizados los campos que fueron editados"
                                    }
                                    <br />
                                    {
                                      " - El botón (Actualizar) se desbloqueará al rellenar todos los "
                                    }
                                    <strong>{"*Campos obligatorios"}</strong>
                                    <br />
                                    {
                                      " - Click en (Personalizado) para escribir manualmente la localidad"
                                    }
                                    <br />
                                    {
                                      " - Solo se mostarán los refugios del municipio que esté seleccionado"
                                    }
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <strong>
                                <FaQuestionCircle />
                              </strong>
                            </OverlayTrigger>
                          </h5>
                        </Col>
                        <Col xs="12">
                          <strong
                            className={
                              colors[currentReporte.tipoR.fenomeno.id - 1]
                            }
                          >
                            *Campos obligatorios
                          </strong>
                        </Col>

                        <Col xl="6" sm="12" className="mb-2">
                          <Form.Group controlId="estatus">
                            <Form.Label>
                              <strong>Estatus del reporte*:</strong>
                            </Form.Label>
                            <Form.Select
                              onClick={this.getEstatusList}
                              onChange={this.onChangeEstatus}
                              onMouseLeave={this.handleKeypress}
                            >
                              <option
                                defaultValue={
                                  currentReporte.estatus == null
                                    ? ""
                                    : currentReporte.estatus
                                }
                                hidden
                              >
                                {!currentReporte.estatus
                                  ? "Sin estatus"
                                  : currentReporte.estatus.estatus}
                              </option>
                              {this.state.estatusList.map((estatus) => (
                                <option value={estatus.id} key={estatus.id}>
                                  {estatus.estatus}
                                </option>
                              ))}
                            </Form.Select>
                            <Alert
                              variant={"nocolor"}
                              show={currentReporte.estatus ? false : true}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12" className="mb-2">
                          <Form.Group controlId="nivelEmergencia">
                            <Form.Label>
                              <strong>Nivel de emergencia*:</strong>
                            </Form.Label>
                            <Form.Select
                              className={
                                currentReporte.nivelEmergencia
                                  ? niveles[currentReporte.nivelEmergencia - 1]
                                  : ""
                              }
                              onChange={this.onChangeNivelEmergencia}
                              onMouseLeave={this.handleKeypress}
                            >
                              <option
                                defaultValue={
                                  currentReporte.nivelEmergencia == null
                                    ? ""
                                    : currentReporte.nivelEmergencia
                                }
                                hidden
                              >
                                {!currentReporte.nivelEmergencia
                                  ? "SIN NIVEL"
                                  : "NIVEL " + currentReporte.nivelEmergencia}
                              </option>
                              {nivelesEmergencia.map((nivelEmergencia) => (
                                <option
                                  className="text-bold"
                                  key={nivelEmergencia}
                                  value={nivelEmergencia}
                                >
                                  {"NIVEL " + nivelEmergencia}
                                </option>
                              ))}
                            </Form.Select>
                            <Alert
                              variant={"nocolor"}
                              show={
                                currentReporte.nivelEmergencia ? false : true
                              }
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="3" sm="12">
                          <Form.Group className="mb-2" controlId="fenomeno">
                            <Form.Label>
                              <strong>Fenómeno:</strong>
                            </Form.Label>
                            <Form.Select disabled>
                              <option
                                defaultValue={
                                  currentReporte.tipoR == null
                                    ? ""
                                    : currentReporte.tipoR
                                }
                              >
                                {!currentReporte.tipoR
                                  ? "Sin fenomeno"
                                  : currentReporte.tipoR.fenomeno.nombreF}
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl="6" sm="12">
                          <Form.Group className="mb-2" controlId="tipo">
                            <Form.Label>
                              <strong>Tipo de reporte:</strong>
                            </Form.Label>
                            <Form.Select disabled>
                              <option defaultValue={currentReporte.tipoR}>
                                {!currentReporte.tipoR
                                  ? "Sin tipo"
                                  : currentReporte.tipoR.nombreT}
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xl="3" sm="12">
                          <Form.Group className="mb-2" controlId="fechaReporte">
                            <Form.Label>
                              <strong>Fecha del reporte*:</strong>
                            </Form.Label>
                            <Form.Control
                              type="datetime-local"
                              value={
                                currentReporte.fechaReporte == null
                                  ? ""
                                  : currentReporte.fechaReporte
                              }
                              onChange={this.onChangeFechaReporte}
                              min={"2020-01-01T00:00"}
                              max={
                                year +
                                "-" +
                                mesActual +
                                "-" +
                                diaActual +
                                "T23:59"
                              }
                              onMouseLeave={this.handleKeypress}
                              disabled={
                                showAdminBoard || showModeratorBoard
                                  ? false
                                  : true
                              }
                            />
                            <Alert
                              variant={"nocolor"}
                              show={currentReporte.fechaReporte ? false : true}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="4" sm="12" className="mb-2">
                          <Form.Group controlId="municipio">
                            <Form.Label>
                              <strong>Municipio:</strong>
                            </Form.Label>
                            <Form.Select
                              value={
                                this.state.municipioN == null
                                  ? ""
                                  : this.state.municipioN
                              }
                              onClick={this.getMunicipios}
                              onChange={this.onChangeMunicipio}
                            >
                              <option
                                defaultValue={
                                  currentReporte.municipio == null
                                    ? ""
                                    : currentReporte.municipio
                                }
                                hidden
                              >
                                {!currentReporte.municipio
                                  ? "Sin municipio"
                                  : currentReporte.municipio.municipio}
                              </option>
                              {this.state.municipios.map((municipio) => (
                                <option value={municipio.id} key={municipio.id}>
                                  {municipio.municipio}
                                </option>
                              ))}
                            </Form.Select>
                            <Alert
                              variant={"nocolor"}
                              show={currentReporte.municipio ? false : true}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="4" sm="12" className="mb-2">
                          <Form.Group controlId="localidad">
                            <Form.Group as={Row} controlId="localidadList">
                              <Col sm="6">
                                <Form.Label>
                                  <strong>Localidad:</strong>
                                </Form.Label>
                              </Col>
                              <Col sm="6">
                                <div className="d-grid">
                                  {this.state.localidadList ? (
                                    <Button
                                      variant={
                                        variants[
                                          currentReporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                      onClick={this.onChangeLocalidadList}
                                      size="sm"
                                    >
                                      <b>Personalizado</b>
                                      <MdEditNote />
                                    </Button>
                                  ) : (
                                    <Button
                                      variant={
                                        outlineButtons[
                                          currentReporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                      onClick={this.onChangeLocalidadList}
                                      size="sm"
                                    >
                                      <b>Usar el listado</b>
                                      <MdList />
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            </Form.Group>

                            {this.state.localidadList ? (
                              <Form.Select
                                value={
                                  currentReporte.localidad == null
                                    ? ""
                                    : currentReporte.localidad
                                }
                                onChange={this.onChangeLocalidad}
                                onMouseLeave={this.handleKeypress}
                              >
                                <option
                                  defaultValue={
                                    currentReporte.localidad == null
                                      ? ""
                                      : currentReporte.localidad
                                  }
                                  hidden
                                >
                                  {!currentReporte.localidad
                                    ? "Sin localidad"
                                    : currentReporte.localidad}
                                </option>
                                {this.state.localidades.map((localidad) => (
                                  <option
                                    key={localidad.id}
                                    value={localidad.localidad}
                                  >
                                    {localidad.localidad}
                                  </option>
                                ))}
                              </Form.Select>
                            ) : (
                              <Form.Control
                                type="text"
                                placeholder="Ingrese el nombre de la localidad..."
                                maxLength="128"
                                value={
                                  currentReporte.localidad == null
                                    ? ""
                                    : currentReporte.localidad
                                }
                                onChange={this.onChangeLocalidad}
                                onKeyUp={this.handleKeypress}
                                name="localidad"
                              />
                            )}
                            <Alert
                              variant={"nocolor"}
                              show={currentReporte.localidad ? false : true}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="4" sm="12" className="mb-2">
                          <Form.Group controlId="evento">
                            <Form.Label>
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Popover>
                                    <Popover.Header as="h3">
                                      <b> Ejemplos de eventos:</b>
                                    </Popover.Header>
                                    <Popover.Body>
                                      Huracán "Karl", frente frío no. 9,
                                      temporada de suradas 2022, etc.
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <strong>
                                  {"Evento: "}
                                  <FaQuestionCircle />
                                </strong>
                              </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Ingrese el evento causante del reporte si existe alguno"
                              maxLength="255"
                              value={
                                currentReporte.evento == null
                                  ? ""
                                  : currentReporte.evento
                              }
                              onChange={this.onChangeEvento}
                              onKeyUp={this.handleKeypress}
                              name="evento"
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12">
                          <Form.Group as={Row}>
                            <Col sm="6">
                              <Form.Group className="mb-1" controlId="latitud">
                                <Form.Label>
                                  <strong>
                                    {"Latitud: "}
                                    {!this.state.posicionLa ? null : (
                                      <FaCheckCircle />
                                    )}
                                  </strong>
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type="text"
                                    maxLength="14"
                                    autoComplete="off"
                                    placeholder={"0.0"}
                                    value={
                                      !currentReporte.latitud
                                        ? ""
                                        : currentReporte.latitud
                                    }
                                    onChange={this.onChangeLatitud}
                                    onKeyUp={this.handlePosicion}
                                  />
                                  <InputGroup.Text>
                                    <b>°</b>
                                  </InputGroup.Text>
                                </InputGroup>
                                <Alert
                                  variant={"nocolor"}
                                  show={
                                    currentReporte.latitud &&
                                    this.state.posicionLa === false
                                      ? true
                                      : false
                                  }
                                >
                                  {
                                    <Badge
                                      bg={
                                        variants[
                                          currentReporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      Inserte una latitud de Veracruz{" "}
                                      <FaExclamationTriangle />
                                    </Badge>
                                  }
                                </Alert>
                              </Form.Group>
                            </Col>
                            <Col sm="6">
                              <Form.Group className="mb-1" controlId="longitud">
                                <Form.Label>
                                  <strong>
                                    {"Longitud: "}
                                    {!this.state.posicionLo ? null : (
                                      <FaCheckCircle />
                                    )}
                                  </strong>
                                </Form.Label>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <b>-</b>
                                  </InputGroup.Text>
                                  <FormControl
                                    type="text"
                                    maxLength="14"
                                    autoComplete="off"
                                    placeholder="0.0"
                                    value={
                                      !currentReporte.longitud
                                        ? ""
                                        : currentReporte.longitud < 0
                                        ? currentReporte.longitud * -1
                                        : currentReporte.longitud
                                    }
                                    onChange={this.onChangeLongitud}
                                    onKeyUp={this.handlePosicion}
                                  />
                                  <InputGroup.Text>
                                    <b>°</b>
                                  </InputGroup.Text>
                                </InputGroup>
                                <Alert
                                  variant={"nocolor"}
                                  show={
                                    currentReporte.longitud &&
                                    this.state.posicionLo === false
                                      ? true
                                      : false
                                  }
                                >
                                  {
                                    <Badge
                                      bg={
                                        variants[
                                          currentReporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      Inserte una longitud de Veracruz{" "}
                                      <FaExclamationTriangle />
                                    </Badge>
                                  }
                                </Alert>
                              </Form.Group>
                            </Col>
                          </Form.Group>
                          <b>
                            ¿Necesita la ubicación?{" "}
                            <a
                              href="https://www.google.com/maps/"
                              target="_blank"
                              rel="noreferrer"
                              className="linktext"
                            >
                              <Button
                                variant="whatsapp"
                                size="sm"
                                className="mb-1"
                              >
                                Google maps <FaMapMarkerAlt />
                              </Button>
                            </a>{" "}
                          </b>
                          <OverlayTrigger
                            placement="auto"
                            show={this.state.errPegado}
                            overlay={
                              <Tooltip>
                                Copie las coordenadas en grados decimales
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="whatsapp2"
                              size="sm"
                              className="mb-1"
                              onClick={this.pasteLatitudLongitud}
                            >
                              {"Pegar coordenadas "}
                              <FaPaste />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="5" sm="9">
                          <Form.Group
                            className="mb-1"
                            controlId="fechaOcurrencia"
                          >
                            <Form.Label>
                              <strong>Fecha y hora de ocurrencia*:</strong>
                            </Form.Label>
                            <Form.Control
                              type="datetime-local"
                              value={
                                currentReporte.fechaOcurrencia == null
                                  ? ""
                                  : currentReporte.fechaOcurrencia
                              }
                              onChange={this.onChangeFechaOcurrencia}
                              min={"2020-01-01T00:00"}
                              max={
                                year +
                                "-" +
                                mesActual +
                                "-" +
                                diaActual +
                                "T23:59"
                              }
                              onMouseLeave={this.handleKeypress}
                            />
                            <Alert
                              variant={"nocolor"}
                              show={
                                currentReporte.fechaOcurrencia ? false : true
                              }
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>
                        <Col xl="1" sm="3" className="mb-3 text-center">
                          <Form.Group className="mb-1" controlId="estimado">
                            <p></p>
                            <Form.Check
                              type="switch"
                              onChange={this.onChangeEstimado}
                              checked={currentReporte.estimado ? true : false}
                              value={currentReporte.estimado ? true : false}
                            />
                            <Form.Label>
                              <strong>
                                {currentReporte.estimado
                                  ? "Hora aprox."
                                  : "Hora exacta"}
                              </strong>
                            </Form.Label>
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group className="mb-1" controlId="reporta">
                            <Form.Label>
                              <strong>Reporta*:</strong>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Ingrese el nombre de quien reportó"
                              onInput={this.maxLengthCheck}
                              maxLength="255"
                              value={
                                currentReporte.reporta == null
                                  ? ""
                                  : currentReporte.reporta
                              }
                              onChange={this.onChangeReporta}
                              onKeyUp={this.handleKeypress}
                              name="reporta"
                            />
                            <Alert
                              variant={"nocolor"}
                              show={currentReporte.reporta ? false : true}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>
                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group className="mb-1" controlId="cargo">
                            <Form.Label>
                              <strong>Cargo:</strong>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Ingrese el cargo de quien reportó"
                              onInput={this.maxLengthCheck}
                              maxLength="255"
                              value={
                                currentReporte.cargo == null
                                  ? ""
                                  : currentReporte.cargo
                              }
                              onChange={this.onChangeCargo}
                              onKeyUp={this.handleKeypress}
                              name="cargo"
                            />
                          </Form.Group>
                        </Col>

                        <hr />

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group controlId="ubicaciones">
                            <Form.Group as={Row} controlId="ubicacionesN">
                              <Col sm="7">
                                <Form.Label>
                                  <strong>Ubicaciones*:</strong>
                                </Form.Label>
                              </Col>
                              <Col sm="5">
                                <InputGroup size="sm">
                                  <InputGroup.Text>
                                    <strong>Cantidad*:</strong>
                                  </InputGroup.Text>
                                  <FormControl
                                    type="text"
                                    maxLength="4"
                                    placeholder={0}
                                    autoComplete="off"
                                    value={
                                      currentEspecifico.ubicacionesN == null
                                        ? ""
                                        : currentEspecifico.ubicacionesN
                                    }
                                    onChange={this.onChangeUbicacionesN}
                                    onKeyUp={this.handleKeypress}
                                  />
                                </InputGroup>
                                <Alert
                                  variant={"nocolor"}
                                  show={
                                    currentEspecifico.ubicacionesN
                                      ? false
                                      : true
                                  }
                                >
                                  <Badge
                                    bg={
                                      variants[
                                        currentReporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  >
                                    {"Falta este campo por llenar "}
                                    <FaExclamationTriangle />
                                  </Badge>
                                </Alert>
                              </Col>
                            </Form.Group>
                            <Form.Control
                              rows={2}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese la(s) ubicación(es) de la ocurrencia"
                              value={
                                currentEspecifico.ubicaciones == null
                                  ? ""
                                  : currentEspecifico.ubicaciones
                              }
                              onChange={this.onChangeUbicaciones}
                              onKeyUp={this.handleKeypress}
                            />
                            <Alert
                              variant={"nocolor"}
                              show={
                                currentEspecifico.ubicaciones ? false : true
                              }
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group controlId="personasAtrapadas">
                            <Form.Group as={Row} controlId="personasAtrapadasN">
                              <Col sm="7">
                                <Form.Label>
                                  <strong>Personas atrapadas:</strong>
                                </Form.Label>
                              </Col>
                              <Col sm="5">
                                <InputGroup size="sm">
                                  <InputGroup.Text>
                                    <strong>Cantidad:</strong>
                                  </InputGroup.Text>
                                  <FormControl
                                    type="text"
                                    maxLength="4"
                                    placeholder={0}
                                    autoComplete="off"
                                    value={
                                      currentEspecifico.personasAtrapadasN ==
                                      null
                                        ? ""
                                        : currentEspecifico.personasAtrapadasN
                                    }
                                    onChange={this.onChangePersonasAtrapadasN}
                                  />
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Control
                              rows={2}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese los datos de las personas atrapadas"
                              value={
                                currentEspecifico.personasAtrapadas == null
                                  ? ""
                                  : currentEspecifico.personasAtrapadas
                              }
                              onChange={this.onChangePersonasAtrapadas}
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group controlId="personasLesionadas">
                            <Form.Group
                              as={Row}
                              controlId="personasLesionadasN"
                            >
                              <Col sm="7">
                                <Form.Label>
                                  <strong>Personas lesionadas:</strong>
                                </Form.Label>
                              </Col>
                              <Col sm="5">
                                <InputGroup size="sm">
                                  <InputGroup.Text>
                                    <strong>Cantidad:</strong>
                                  </InputGroup.Text>
                                  <FormControl
                                    type="text"
                                    maxLength="4"
                                    placeholder={0}
                                    autoComplete="off"
                                    value={
                                      currentEspecifico.personasLesionadasN ==
                                      null
                                        ? ""
                                        : currentEspecifico.personasLesionadasN
                                    }
                                    onChange={this.onChangePersonasLesionadasN}
                                  />
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Control
                              rows={2}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese los datos de las personas lesionadas"
                              value={
                                currentEspecifico.personasLesionadas == null
                                  ? ""
                                  : currentEspecifico.personasLesionadas
                              }
                              onChange={this.onChangePersonasLesionadas}
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group controlId="personasFallecidas">
                            <Form.Group
                              as={Row}
                              controlId="personasFallecidasN"
                            >
                              <Col sm="7">
                                <Form.Label>
                                  <strong>Personas fallecidas:</strong>
                                </Form.Label>
                              </Col>
                              <Col sm="5">
                                <InputGroup size="sm">
                                  <InputGroup.Text>
                                    <strong>Cantidad:</strong>
                                  </InputGroup.Text>
                                  <FormControl
                                    type="text"
                                    maxLength="4"
                                    placeholder={0}
                                    autoComplete="off"
                                    value={
                                      currentEspecifico.personasFallecidasN ==
                                      null
                                        ? ""
                                        : currentEspecifico.personasFallecidasN
                                    }
                                    onChange={this.onChangePersonasFallecidasN}
                                  />
                                </InputGroup>
                              </Col>
                            </Form.Group>
                            <Form.Control
                              rows={2}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese los datos de las personas fallecidas"
                              value={
                                currentEspecifico.personasFallecidas == null
                                  ? ""
                                  : currentEspecifico.personasFallecidas
                              }
                              onChange={this.onChangePersonasFallecidas}
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="5" sm="9" className="mb-3">
                          <Form.Group className="mb-1" controlId="fechaTermino">
                            <Form.Label>
                              <strong>Fecha y hora de término:</strong>
                            </Form.Label>
                            <Form.Control
                              type="datetime-local"
                              value={
                                currentEspecifico.fechaTermino == null
                                  ? ""
                                  : currentEspecifico.fechaTermino
                              }
                              onChange={this.onChangeFechaTermino}
                              min={this.state.fechaMin + "T00:00"}
                              max={
                                year +
                                "-" +
                                mesActual +
                                "-" +
                                diaActual +
                                "T23:59"
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="1" sm="3" className="mb-3 text-center">
                          <Form.Group className="mb-1" controlId="estimado2">
                            <p></p>
                            <Form.Check
                              type="switch"
                              value={currentEspecifico.estimado2}
                              onChange={this.onChangeEstimado2}
                            />
                            <Form.Label>
                              <strong>
                                {currentEspecifico.estimado2
                                  ? "Hora aprox."
                                  : "Hora exacta"}
                              </strong>
                            </Form.Label>
                          </Form.Group>
                        </Col>

                        <hr />

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group className="mb-1" controlId="refugio">
                            <Form.Label>
                              {currentReporte.refugio ? (
                                <OverlayTrigger
                                  overlay={<Tooltip>Quitar refugio</Tooltip>}
                                >
                                  <Button
                                    variant="dark"
                                    size="sm"
                                    onClick={this.resetRefugio}
                                  >
                                    <ImCross />
                                  </Button>
                                </OverlayTrigger>
                              ) : null}
                              <strong>
                                {" Refugio utilizado "}
                                <b>
                                  {currentReporte.municipio
                                    ? "(" +
                                      currentReporte.municipio.municipio +
                                      ")"
                                    : ""}
                                </b>
                                {": "}
                              </strong>
                            </Form.Label>
                            <Form.Select
                              value={
                                this.state.refugioN == null
                                  ? ""
                                  : this.state.refugioN
                              }
                              onClick={
                                currentReporte.municipio
                                  ? () =>
                                      this.getRefugios(
                                        currentReporte.municipio.id
                                      )
                                  : null
                              }
                              onChange={this.onChangeRefugio}
                              onMouseLeave={this.handleKeypress}
                            >
                              <option
                                defaultValue={
                                  currentReporte.refugio == null
                                    ? ""
                                    : currentReporte.refugio
                                }
                                hidden
                              >
                                {!currentReporte.refugio
                                  ? "Sin refugio"
                                  : currentReporte.refugio.nombre}
                              </option>
                              {this.state.refugios.map((refugio) => (
                                <option key={refugio.id} value={refugio.id}>
                                  {refugio.nombre && refugio.capacidad
                                    ? refugio.nombre
                                    : ""}
                                </option>
                              ))}
                            </Form.Select>

                            <p></p>
                            <b>
                              ¿No encuentra refugios activos?{" "}
                              <Link
                                to={"/refugiosList"}
                                target="_blank"
                                className="linktext"
                              >
                                <Button
                                  variant="refugio"
                                  size="sm"
                                  className="mb-1"
                                >
                                  Lista de refugios <ImHome />
                                </Button>
                              </Link>
                            </b>
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12" className="mb-3">
                          <Form.Group
                            className="mb-1"
                            controlId="fechaActivacion"
                          >
                            <Form.Label>
                              <strong>Fecha de activación del refugio:</strong>
                            </Form.Label>
                            <Form.Control
                              type="datetime-local"
                              value={
                                currentReporte.fechaActivacion == null
                                  ? ""
                                  : currentReporte.fechaActivacion
                              }
                              onChange={this.onChangeFechaActivacion}
                              min={"2020-01-01T00:00"}
                              max={
                                year +
                                "-" +
                                mesActual +
                                "-" +
                                diaActual +
                                "T23:59"
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-1"
                            controlId="fechaDesactivacion"
                          >
                            <Form.Label>
                              <strong>
                                Fecha de desativación del refugio:
                              </strong>
                            </Form.Label>
                            <Form.Control
                              type="datetime-local"
                              value={
                                currentReporte.fechaDesactivacion == null
                                  ? ""
                                  : currentReporte.fechaDesactivacion
                              }
                              onChange={this.onChangeFechaDesactivacion}
                              min={currentReporte.fechaActivacion}
                              max={
                                year +
                                "-" +
                                mesActual +
                                "-" +
                                diaActual +
                                "T23:59"
                              }
                            />
                          </Form.Group>
                        </Col>

                        <Col xl="6" sm="12">
                          <Form.Group
                            className="mb-1"
                            controlId="accionesImplementadas"
                          >
                            <Form.Label>
                              <strong>Acciones implementadas*:</strong>
                            </Form.Label>
                            <Form.Control
                              rows={3}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese las acciones que fueron implementadas"
                              value={
                                currentReporte.accionesImplementadas == null
                                  ? ""
                                  : currentReporte.accionesImplementadas
                              }
                              onChange={this.onChangeAccionesImplementadas}
                              onKeyUp={this.handleKeypress}
                            />
                            <Alert
                              variant={"nocolor"}
                              show={
                                currentReporte.accionesImplementadas
                                  ? false
                                  : true
                              }
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>
                        <Col xl="6" sm="12">
                          <Form.Group className="mb-1" controlId="notas">
                            <Form.Label>
                              <strong>Notas:</strong>
                            </Form.Label>
                            <Form.Control
                              rows={3}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese las notas del reporte"
                              value={
                                currentReporte.notas == null
                                  ? ""
                                  : currentReporte.notas
                              }
                              onChange={this.onChangeNotas}
                              onKeyUp={this.handleKeypress}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="6" sm="12">
                          <Form.Group className="mb-1" controlId="fuerzaTarea">
                            <Form.Label>
                              <strong>Fuerzas de tarea*:</strong>
                            </Form.Label>
                            <Form.Control
                              rows={3}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese las fuerzas de tarea involucradas"
                              value={
                                currentReporte.fuerzaTarea == null
                                  ? ""
                                  : currentReporte.fuerzaTarea
                              }
                              onChange={this.onChangeFuerzaTarea}
                              onKeyUp={this.handleKeypress}
                            />
                            <Alert
                              variant={"nocolor"}
                              show={currentReporte.fuerzaTarea ? false : true}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>
                        <Col xl="6" sm="12">
                          <Form.Group className="mb-1" controlId="evidencia">
                            <Form.Label>
                              <strong>
                                Evidencias: (Máximo 3 Archivos de 2MB cada uno)
                              </strong>
                            </Form.Label>

                            {this.state.files.length === 0 ? (
                              <div className="my-2">
                                <Dropzone
                                  onDrop={this.onDrop}
                                  accept="image/jpeg,image/png"
                                  maxSize={2200000}
                                  maxFiles={3}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        {selectedFiles &&
                                        Array.isArray(selectedFiles) &&
                                        selectedFiles.length ? (
                                          <div className="selected-file">
                                            {selectedFiles.length > 3
                                              ? `${selectedFiles.length} files`
                                              : selectedFiles
                                                  .map((file) => file.name)
                                                  .join(", ")}
                                          </div>
                                        ) : (
                                          `Arrastre sus imágenes aquí o haga click.`
                                        )}
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            ) : (
                              <Carousel
                                interval={8000}
                                className="alert-message"
                                activeIndex={this.state.index}
                                onSelect={this.handleSelect}
                              >
                                {this.state.files.map((file) => (
                                  <Carousel.Item key={file.id}>
                                    <img
                                      className="img fluid d-block objectcover w-100"
                                      src={
                                        "https://sicecom.veracruz.gob.mx/api/auth/files/" +
                                        file.name
                                      }
                                      alt={file.name}
                                      height={260}
                                    />
                                    <Carousel.Caption>
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip>Borrar imágen</Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="dark"
                                          onClick={() =>
                                            this.openModalBorrarFile(file.name)
                                          }
                                        >
                                          <ImCross />
                                        </Button>
                                      </OverlayTrigger>
                                      <a
                                        href={
                                          "https://sicecom.veracruz.gob.mx/api/auth/files/" +
                                          file.name
                                        }
                                      >
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>Descargar imágen</Tooltip>
                                          }
                                        >
                                          <Button
                                            variant="light"
                                            className="m-1"
                                          >
                                            <MdDownload />
                                          </Button>
                                        </OverlayTrigger>
                                      </a>

                                      <OverlayTrigger
                                        overlay={
                                          this.state.files.length > 2 ? null : (
                                            <Tooltip>
                                              Subir imágen (Máximo:{" "}
                                              {3 -
                                                parseInt(
                                                  this.state.files.length
                                                )}
                                              )
                                            </Tooltip>
                                          )
                                        }
                                      >
                                        <Button
                                          variant="dark"
                                          disabled={
                                            this.state.files.length > 2
                                              ? true
                                              : false
                                          }
                                        >
                                          <Dropzone
                                            onDrop={this.onDrop}
                                            accept="image/jpeg,image/png"
                                            maxSize={2200000}
                                            maxFiles={
                                              3 -
                                              parseInt(this.state.files.length)
                                            }
                                            disabled={
                                              this.state.files.length > 2
                                                ? true
                                                : false
                                            }
                                          >
                                            {({
                                              getRootProps,
                                              getInputProps,
                                            }) => (
                                              <section>
                                                <div {...getRootProps({})}>
                                                  <input {...getInputProps()} />
                                                  {selectedFiles &&
                                                  Array.isArray(
                                                    selectedFiles
                                                  ) &&
                                                  selectedFiles.length ? (
                                                    <div className="selected-file-white">
                                                      {selectedFiles.length > 3
                                                        ? `${selectedFiles.length} files`
                                                        : selectedFiles
                                                            .map(
                                                              (file) =>
                                                                file.name
                                                            )
                                                            .join(", ")}
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <ImPlus />
                                                      {3 -
                                                        parseInt(
                                                          this.state.files
                                                            .length
                                                        ) <=
                                                      0 ? (
                                                        ""
                                                      ) : (
                                                        <strong>
                                                          {" " +
                                                            (3 -
                                                              parseInt(
                                                                this.state.files
                                                                  .length
                                                              ))}
                                                        </strong>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              </section>
                                            )}
                                          </Dropzone>
                                        </Button>
                                      </OverlayTrigger>
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            )}
                            <p className="nocolor minitext">&zwnj;</p>
                            <b>
                              ¿Imágenes muy pesadas?{" "}
                              <a
                                href="https://www.iloveimg.com/es/comprimir-imagen"
                                target="_blank"
                                rel="noreferrer"
                                className="linktext"
                              >
                                <Button
                                  variant="light"
                                  size="sm"
                                  className="mb-1"
                                >
                                  Compresor de imágenes <FaCompressArrowsAlt />
                                </Button>
                              </a>
                            </b>
                          </Form.Group>
                        </Col>

                        <p></p>
                        <hr />
                        <Col xs="12">
                          <h4>
                            <MdDoubleArrow />
                            <b>
                              {" SEGUIMIENTOS (" +
                                (seguimientos ? seguimientos.length : 0) +
                                "):"}
                            </b>
                          </h4>
                        </Col>

                        {!seguimientos ? null : (
                          <Col xs="12">
                            {seguimientos.map((seguimiento, index) => (
                              <Form.Group as={Row} key={seguimiento.id}>
                                <Col xl="3" md="12">
                                  <h5>
                                    <strong>
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip>Borrar seguimiento</Tooltip>
                                        }
                                      >
                                        <Button
                                          disabled={this.state.editar}
                                          variant="dark"
                                          size="sm"
                                          onClick={() =>
                                            this.openModalBorrarSeguimiento(
                                              seguimiento.id,
                                              (new Date(
                                                seguimiento.fechaSeguimiento
                                              ).getDate() < 10
                                                ? "0"
                                                : "") +
                                                new Date(
                                                  seguimiento.fechaSeguimiento
                                                ).getDate() +
                                                "/" +
                                                monthNames[
                                                  new Date(
                                                    seguimiento.fechaSeguimiento
                                                  ).getMonth()
                                                ] +
                                                "/" +
                                                new Date(
                                                  seguimiento.fechaSeguimiento
                                                ).getUTCFullYear()
                                            )
                                          }
                                        >
                                          <ImCross />
                                        </Button>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip>Editar seguimiento</Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="light"
                                          size="sm"
                                          onClick={() =>
                                            this.editSeguimiento(
                                              seguimiento.id,
                                              seguimiento.fechaSeguimiento,
                                              seguimiento.seguimiento
                                            )
                                          }
                                        >
                                          <MdEdit />
                                        </Button>
                                      </OverlayTrigger>{" "}
                                      <b>{index + 1 + "."}</b>
                                      {" (" +
                                        (new Date(
                                          seguimiento.fechaSeguimiento
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          seguimiento.fechaSeguimiento
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            seguimiento.fechaSeguimiento
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          seguimiento.fechaSeguimiento
                                        ).getUTCFullYear() +
                                        "): "}
                                    </strong>
                                  </h5>
                                </Col>
                                <Col xl="9" md="12">
                                  <h5>
                                    <strong
                                      className={
                                        colors[
                                          currentReporte.tipoR.fenomeno.id - 1
                                        ]
                                      }
                                    >
                                      {seguimiento.seguimiento}
                                    </strong>
                                  </h5>
                                </Col>
                              </Form.Group>
                            ))}
                          </Col>
                        )}

                        <p></p>
                        <hr />

                        <Col xs="12">
                          <h5>
                            <strong>
                              {this.state.editar ? (
                                <Badge bg="light">
                                  <h5>
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      className="text-dark"
                                    />

                                    <b className="text-dark">
                                      {" EDITAR SEGUIMIENTO (" +
                                        (new Date(
                                          this.state.fechaMostrar
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                        new Date(
                                          this.state.fechaMostrar
                                        ).getDate() +
                                        "/" +
                                        monthNames[
                                          new Date(
                                            this.state.fechaMostrar
                                          ).getMonth()
                                        ] +
                                        "/" +
                                        new Date(
                                          this.state.fechaMostrar
                                        ).getUTCFullYear() +
                                        "):"}
                                    </b>
                                  </h5>
                                </Badge>
                              ) : (
                                <h5
                                  className={
                                    colors[currentReporte.tipoR.fenomeno.id - 1]
                                  }
                                >
                                  <Spinner
                                    animation="grow"
                                    size="sm"
                                    className={
                                      colors[
                                        currentReporte.tipoR.fenomeno.id - 1
                                      ]
                                    }
                                  />
                                  <b> AGREGAR SEGUIMIENTO:</b>
                                </h5>
                              )}
                            </strong>
                          </h5>
                        </Col>
                        <Col xs="12">
                          <i>
                            {this.state.editar
                              ? "Por favor rellene los campos que desee actualizar..."
                              : "Por favor rellene los campos para agregar un seguimiento..."}
                          </i>
                        </Col>
                        <Col xs="12">
                          <strong
                            className={
                              colors[currentReporte.tipoR.fenomeno.id - 1]
                            }
                          >
                            *Campos obligatorios
                          </strong>
                        </Col>
                        <Col xl="3" md="12">
                          <Form.Group
                            className="mb-2"
                            controlId="fechaSeguimiento"
                          >
                            <Form.Label>
                              <strong>Fecha del seguimiento*:</strong>
                            </Form.Label>
                            <Form.Control
                              type="date"
                              value={
                                this.state.fechaSeguimiento == null
                                  ? ""
                                  : this.state.fechaSeguimiento
                              }
                              onChange={this.onChangeFechaSeguimiento}
                              min={this.state.fechaMinReporte}
                              max={year + "-" + mesActual + "-" + diaActual}
                              onMouseLeave={this.handleKeypressSeguimiento}
                            />
                            <Alert
                              variant={"nocolor"}
                              show={!this.state.fechaSeguimiento ? true : false}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>
                        <Col xl="9" md="12">
                          <Form.Group className="mb-2" controlId="seguimiento">
                            <Form.Label>
                              <strong>Seguimiento*:</strong>
                            </Form.Label>
                            <Form.Control
                              rows={3}
                              maxLength={8192}
                              as="textarea"
                              placeholder="Ingrese las acciones realizadas en el seguimiento"
                              value={
                                this.state.seguimiento == null
                                  ? ""
                                  : this.state.seguimiento
                              }
                              onChange={this.onChangeSeguimiento}
                              onKeyUp={this.handleKeypressSeguimiento}
                            />
                            <Alert
                              variant={"nocolor"}
                              show={!this.state.seguimiento ? true : false}
                            >
                              <Badge
                                bg={
                                  variants[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {"Falta este campo por llenar "}
                                <FaExclamationTriangle />
                              </Badge>
                            </Alert>
                          </Form.Group>
                        </Col>
                        <Col xs="12">
                          <Stack direction="horizontal" gap={3}>
                            <div className="ms-auto">
                              {this.state.editar ? (
                                <Button
                                  variant="dark"
                                  onClick={this.cancelEdit}
                                >
                                  Cancelar <MdOutlineDoNotDisturbAlt />
                                </Button>
                              ) : null}
                            </div>
                            <div onMouseOver={this.handleKeypressSeguimiento}>
                              {this.state.cargando ? (
                                <Button variant={"gold"} disabled={true}>
                                  <b>Cargando</b>{" "}
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                </Button>
                              ) : this.state.editar ? (
                                <OverlayTrigger
                                  placement="top"
                                  show={this.state.camposFaltantesSeguimiento}
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {"Seguimiento incompleto"}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="light"
                                    disabled={
                                      this.state.camposFaltantesSeguimiento
                                    }
                                    onClick={() =>
                                      this.openModalActualizarSeguimiento(
                                        this.state.seguimientoId,
                                        (new Date(
                                          this.state.fechaMostrar
                                        ).getDate() < 10
                                          ? "0"
                                          : "") +
                                          new Date(
                                            this.state.fechaMostrar
                                          ).getDate() +
                                          "/" +
                                          monthNames[
                                            new Date(
                                              this.state.fechaMostrar
                                            ).getMonth()
                                          ] +
                                          "/" +
                                          new Date(
                                            this.state.fechaMostrar
                                          ).getUTCFullYear()
                                      )
                                    }
                                  >
                                    <b>Actualizar</b> <MdEdit />
                                  </Button>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  show={this.state.camposFaltantesSeguimiento}
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>
                                      {"Seguimiento incompleto"}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="gold"
                                    disabled={
                                      this.state.camposFaltantesSeguimiento
                                    }
                                    onClick={this.saveSeguimiento}
                                    onMouseOver={this.handleKeypressSeguimiento}
                                  >
                                    <b>Agregar</b> <MdDoubleArrow />
                                  </Button>
                                </OverlayTrigger>
                              )}
                            </div>
                          </Stack>
                        </Col>
                      </Row>

                      <hr />

                      <Stack direction="horizontal" gap={3}>
                        <div>
                          <Button
                            onClick={this.goBack}
                            variant={
                              outlineButtons[
                                currentReporte.tipoR.fenomeno.id - 1
                              ]
                            }
                            className="ms-auto"
                            size="lg"
                          >
                            <ImArrowLeft2 /> Regresar
                          </Button>
                        </div>
                        {(showAdminBoard || showModeratorBoard) && (
                          <div>
                            <Button
                              variant="error"
                              size="lg"
                              onClick={this.openModalBorrar}
                            >
                              <ImCross /> Borrar
                            </Button>
                          </div>
                        )}
                        <div className="ms-auto">
                          <Link to={"/reporte/" + this.props.router.params.id}>
                            <Button variant="gob" size="lg">
                              Visualizar <BsEyeFill />
                            </Button>
                          </Link>
                        </div>
                        <div onMouseOver={this.handleKeypress}>
                          <OverlayTrigger
                            placement="top"
                            show={this.state.camposFaltantes}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                Faltan campos por llenar / Datos Incorrectos
                              </Tooltip>
                            }
                          >
                            <Button
                              disabled={this.state.camposFaltantes}
                              onClick={this.openModalActualizar}
                              size="lg"
                              variant="brown"
                            >
                              <strong>Actualizar</strong> <BsCheckLg />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </Stack>
                    </div>
                  </Alert>
                )}

                {this.state.message2 ? (
                  <Fade in={this.state.open}>
                    <div>
                      <Alert
                        variant={error ? "error" : "correct"}
                        onClose={() => this.setClose()}
                        dismissible
                      >
                        <Alert.Heading className="text-center">
                          {this.state.message2}
                        </Alert.Heading>
                      </Alert>
                    </div>
                  </Fade>
                ) : null}

                <Modal
                  show={this.state.isOpen}
                  onHide={this.closeModal}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header>
                    {this.state.isActualizar ? (
                      <Modal.Title>
                        <div className="text-center">
                          {"¿Desea actualizar el reporte?"}
                          <b
                            className={
                              colors[currentReporte.tipoR.fenomeno.id - 1]
                            }
                          >
                            {" " + currentReporte.folio}
                            {icons[currentReporte.tipoR.fenomeno.id - 1]}
                          </b>
                          <p className="nocolor smalltext">&zwnj;</p>
                          <Button
                            className="m-2"
                            variant="secondary"
                            onClick={this.closeModal}
                          >
                            Cancelar
                          </Button>
                          <Button
                            className="m-2"
                            variant="brown"
                            onClick={this.updateReporte}
                          >
                            <b>Actualizar</b> <BsCheckLg />
                          </Button>
                        </div>
                      </Modal.Title>
                    ) : (
                      <>
                        {this.state.isBorrar ? (
                          <Modal.Title>
                            <div className="text-center">
                              ¿Desea <strong>BORRAR</strong> el reporte?
                              <b
                                className={
                                  colors[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {" " + currentReporte.folio}
                                {icons[currentReporte.tipoR.fenomeno.id - 1]}
                              </b>
                              <br />
                              <h5>
                                <strong>
                                  (Se borrarán sus imágenes y seguimientos)
                                </strong>
                              </h5>
                              <p className="nocolor smalltext">&zwnj;</p>
                              {this.state.cargando ? (
                                <Spinner animation="border" />
                              ) : (
                                <>
                                  <Button
                                    className="m-2"
                                    variant="secondary"
                                    onClick={this.closeModal}
                                  >
                                    Cancelar
                                  </Button>
                                  <Button
                                    className="m-2"
                                    variant="error"
                                    onClick={this.deleteReporte}
                                  >
                                    <strong>
                                      <ImCross /> BORRAR
                                    </strong>
                                  </Button>
                                </>
                              )}
                            </div>
                          </Modal.Title>
                        ) : this.state.isBorrarSeguimiento ? (
                          <Modal.Title>
                            <div className="text-center">
                              ¿Desea <strong>BORRAR</strong> el seguimiento del
                              <b
                                className={
                                  colors[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {" " + this.state.fechaSeguimientoId + "?"}
                              </b>
                              <p className="nocolor smalltext">&zwnj;</p>
                              <Button
                                className="m-2"
                                variant="secondary"
                                onClick={this.closeModal}
                              >
                                Cancelar
                              </Button>
                              <Button
                                className="m-2"
                                variant="dark"
                                onClick={() =>
                                  this.deleteSeguimiento(
                                    this.state.seguimientoId
                                  )
                                }
                              >
                                <strong>
                                  <ImCross /> BORRAR
                                </strong>
                              </Button>
                            </div>
                          </Modal.Title>
                        ) : this.state.isActualizarSeguimiento ? (
                          <Modal.Title>
                            <div className="text-center">
                              {"¿Desea actualizar el seguimiento? "}
                              <b
                                className={
                                  colors[currentReporte.tipoR.fenomeno.id - 1]
                                }
                              >
                                {this.state.fechaSeguimientoId}
                              </b>
                              <p className="nocolor smalltext">&zwnj;</p>
                              <Button
                                className="m-2"
                                variant="secondary"
                                onClick={this.closeModal}
                              >
                                Cancelar
                              </Button>
                              <Button
                                className="m-2"
                                variant="light"
                                onClick={() =>
                                  this.updateSeguimiento(
                                    this.state.seguimientoId
                                  )
                                }
                              >
                                <strong>
                                  Actualizar <MdEdit />
                                </strong>
                              </Button>
                            </div>
                          </Modal.Title>
                        ) : this.state.isBorrarFile ? (
                          <Modal.Title>
                            <div className="text-center">
                              ¿Desea <strong>BORRAR</strong> esta imágen del
                              reporte?
                              <p className="nocolor smalltext">&zwnj;</p>
                              <Button
                                className="m-2"
                                variant="secondary"
                                onClick={this.closeModal}
                              >
                                Cancelar
                              </Button>
                              <Button
                                className="m-2"
                                variant="dark"
                                onClick={() =>
                                  this.deleteFile(this.state.fileId)
                                }
                              >
                                <strong>
                                  <ImCross /> BORRAR
                                </strong>
                              </Button>
                            </div>
                          </Modal.Title>
                        ) : null}
                      </>
                    )}
                  </Modal.Header>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(HidGHI);
