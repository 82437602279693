import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { MdLogin } from "react-icons/md";
import AuthService from "../../services/AuthService";
import WithRouter from "../../common/WithRouter";
import { Container, Row, Col, Image } from "react-bootstrap";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-error" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

class LoginUser extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.logOut = this.logOut.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  componentDidMount() {
    document.title = "SICECOM - Iniciar sesión";

    this.logOut();
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();
    this.setState({
      message: "",
      loading: true,
    });
    this.form.validateAll();
    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.router.navigate("/home");
        },
        () => {
          const resMessage = "Usuario o contraseña incorrectos";

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    return (
      <>
        <div>
          <p className="gob text-center smalltext">&zwnj;</p>
          <p className="nocolor bigtext">&zwnj;</p>
          <div className="check row centered">
            <Container>
              <Row className="centeralign">
                <Col xs={4} md={3}>
                  <Image
                    src="https://sicecom.veracruz.gob.mx/veracruz.png"
                    height={120}
                    fluid
                    className="verticalalign"
                  />
                </Col>
                <Col xs={4} md={3}>
                  <Image
                    src="https://sicecom.veracruz.gob.mx/pc.png"
                    height={120}
                    fluid
                    className="verticalalign"
                  />
                </Col>
                <Col xs={4} md={3}>
                  <Image
                    src="https://sicecom.veracruz.gob.mx/logo.png"
                    height={120}
                    fluid
                    className="verticalalign"
                  />
                </Col>
              </Row>
            </Container>

            <div className="card card-size">
              <h2 className="text-center">
                <b>Iniciar sesión</b>
              </h2>

              <Form
                onSubmit={this.handleLogin}
                ref={(c) => {
                  this.form = c;
                }}
              >
                <div className="form-group">
                  <label htmlFor="username">Nombre de usuario</label>
                  <Input
                    type="text"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required]}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Contraseña</label>
                  <Input
                    type="password"
                    name="password"
                    autoComplete="on"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required]}
                    className="form-control"
                  />
                </div>
                <p className="nocolor bigtext">&zwnj;</p>

                <div className="form-group">
                  <button
                    className="btn btn-brown btn-block"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>
                      Iniciar sesión <MdLogin />
                    </span>
                  </button>
                </div>

                {this.state.message && (
                  <div className="form-group">
                    <p className="nocolor mediumtext">&zwnj;</p>

                    <div className="alert alert-error" role="alert">
                      {this.state.message}
                    </div>
                  </div>
                )}

                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </div>
          </div>

          <div className="footer black">
            <p className="gob text-center minitext">&zwnj;</p>
            <p className="whitetext text-center">
              <strong>
                Secretaría de Protección Civil {new Date().getFullYear()}
              </strong>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(LoginUser);
