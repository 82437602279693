import React, { Component } from "react";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";

export default class ErrorBoard extends Component {
  render() {
    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <h1 className="text-center">
              <b>ERROR: PÁGINA NO ENCONTRADA</b>
            </h1>
          </div>
        </div>
      </>
    );
  }
}
