import React, { Component } from "react";
import { Navigate, NavLink } from "react-router-dom";
import "./bars.scss";
import { MdLogout, MdSpaceDashboard } from "react-icons/md";
import {
  BsFillFolderFill,
  BsFillFileEarmarkPlusFill,
  BsFillPersonPlusFill,
  BsFillPeopleFill,
  BsFillFileTextFill,
} from "react-icons/bs";
import { FcClock } from "react-icons/fc";
import { ImHome } from "react-icons/im";

import { Image, Modal, Button } from "react-bootstrap";
import AuthService from "../services/AuthService";
import EventBus from "../common/EventBus";
import WithRouter from "../common/WithRouter";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.expireToken = this.expireToken.bind(this);

    this.state = {
      showUserBoard: false,
      showModeratorBoard: false,
      showAdminBoard: false,

      redirect: false,
      currentUser: undefined,
      currentStatus: undefined,
    };
  }

  componentDidMount() {
    let currentUser = AuthService.getCurrentUser();
    let currentStatus = AuthService.getCurrentStatus();

    if (!currentUser) {
      this.logOut();
    } else if (currentUser) {
      this.setState({
        currentUser: currentUser,
        currentStatus: currentStatus,
        userReady: true,
        showUserBoard: currentUser.roles.includes("ROLE_USER"),
        showModeratorBoard: currentUser.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("expireToken", () => {
      this.expireToken();
    });

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  expireToken() {
    AuthService.expireToken();
    setTimeout(() => {
      this.setState({
        currentStatus: AuthService.getCurrentStatus(),
      });
    }, 10);
  }

  logOut() {
    AuthService.logout();
    this.setState({
      redirect: true,

      showUserBoard: false,
      showModeratorBoard: false,
      showAdminBoard: false,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    }

    const {
      currentUser,
      currentStatus,
      showUserBoard,
      showModeratorBoard,
      showAdminBoard,
    } = this.state;

    return (
      <div className="sidebar bg-dark">
        <div className="w-100">
          <p className="nocolor minitext">&zwnj;</p>
          <Image
            src="https://sicecom.veracruz.gob.mx/logoSidebar.png"
            fluid
            className="verticalalign pt-1"
          />
          <p className="text-center whitetext mediumtext">
            <strong>V2.2</strong>
          </p>
        </div>

        {currentUser ? (
          <ul>
            <li>
              <NavLink
                to="/home"
                className="text-white rounded py-2 w-100 d-inline-block px-1"
              >
                <MdSpaceDashboard className="me-1" />
                Home
              </NavLink>
            </li>
            <li>
              <strong className="gobtext">Reportes:</strong>
            </li>
            <li>
              <NavLink
                to="/add"
                className="text-white rounded py-2 w-100 d-inline-block px-1"
              >
                <BsFillFileEarmarkPlusFill className="me-1" />
                Agregar
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reportesList"
                className="text-white rounded py-2 w-100 d-inline-block px-1"
              >
                <BsFillFolderFill className="me-1" />
                Consulta
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/informe"
                className="text-white rounded py-2 w-100 d-inline-block px-1"
              >
                <BsFillFileTextFill className="me-1" />
                Informe
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/refugiosList"
                className="text-white rounded py-2 w-100 d-inline-block px-1"
              >
                <ImHome className="me-1" />
                Refugios
              </NavLink>
            </li>

            {(showAdminBoard || showModeratorBoard) && (
              <div>
                <li>
                  <strong className="gobtext">Usuarios:</strong>
                </li>
                <li>
                  <NavLink
                    to="/users"
                    className="text-white rounded py-2 w-100 d-inline-block px-1"
                  >
                    <BsFillPeopleFill className="me-1" />
                    Lista
                  </NavLink>
                </li>

                {showAdminBoard && (
                  <li>
                    <NavLink
                      to="/register"
                      className="text-white rounded py-2 w-100 d-inline-block px-1"
                    >
                      <BsFillPersonPlusFill className="me-1" />
                      Registrar
                    </NavLink>
                  </li>
                )}
              </div>
            )}

            <strong
              className={
                showAdminBoard
                  ? "text-warning"
                  : showModeratorBoard
                  ? "goldtext"
                  : showUserBoard
                  ? "gobtext"
                  : "whitetext"
              }
            >
              {currentUser.username}
            </strong>

            <li>
              <NavLink
                to="/"
                className="text-white rounded py-2 w-100 d-inline-block px-1"
                onClick={this.logOut}
              >
                <MdLogout className="me-1" />
                Cerrar sesión
              </NavLink>
            </li>
          </ul>
        ) : null}

        <Modal show={currentStatus} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>
              <div className="text-center">
                <h1 className="text-danger">
                  <FcClock />
                </h1>
                <h2>
                  <b> Su sesión ha caducado.</b>
                </h2>
                <h4>Se le redireccionará a la pantalla de inicio de sesión.</h4>
                <p className="nocolor smalltext">&zwnj;</p>
                <Button
                  variant="brown"
                  className="m-2"
                  size="lg"
                  onClick={this.logOut}
                >
                  Aceptar
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
        </Modal>
      </div>
    );
  }
}

export default WithRouter(Sidebar);
