import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBus from "../../common/EventBus";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import {
  Alert,
  Row,
  Col,
  Stack,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { ImArrowLeft2, ImHome } from "react-icons/im";
import ReporteService from "../../services/ReporteService";
import AuthService from "../../services/AuthService";
import { AiFillEdit } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";

class RefugioView extends Component {
  constructor(props) {
    super(props);
    this.getRefugio = this.getRefugio.bind(this);

    this.state = {
      refugio: {
        id: null,
        nombre: null,
        latitud: null,
        longitud: null,
        direccion: null,
        estatus: null,
        nivelEmergencia: null,
        capacidad: null,
        municipio: {
          id: null,
          municipio: null,
          region: {
            id: null,
            region: null,
          },
        },
      },

      link: null,
      reportesCount: null,

      messageError: null,
      cargandoRefugio: true,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      this.getRefugio();
    }
  }

  getRefugio() {
    ReporteService.getRefugio(this.props.router.params.id)
      .then((response) => {
        this.setState({
          refugio: response.data,
        });

        ReporteService.getReportesRefugio(this.props.router.params.id)
          .then((response) => {
            this.setState({
              reportesCount: response.data,
              cargandoRefugio: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        document.title = "SICECOM - refugio ERROR";

        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
          err = "Usuario no identificado";

          this.setState({
            messageError: "Error en la autentificación del usuario: " + err,
          });
        } else if (
          (err.response && err.response.status === 400) ||
          (err.response && err.response.status === 404) ||
          (err.response && err.response.status === 500)
        ) {
          this.props.router.navigate("/refugiosList");
        } else {
          this.setState({
            cargandoRefugio: false,
            messageError: "Error en la carga del refugio: " + err,
          });
        }
      });
  }

  goBack = () => {
    this.props.router.navigate(-1);
  };

  render() {
    const { refugio, reportesCount } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                {this.state.cargandoRefugio ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Alert variant={"refugio"}>
                    <div className="m-1 d-grid gap-2 d-md-block">
                      <Row>
                        <Col xl="11" sm="12">
                          <h3>
                            <b>
                              {refugio.nombre
                                ? refugio.nombre.toUpperCase()
                                : null}
                            </b>
                            <p>
                              {refugio.estatus ? (
                                <Button
                                  variant="success"
                                  size="sm"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <b>Activo</b>
                                </Button>
                              ) : (
                                <Button
                                  variant="danger"
                                  size="sm"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <b>Desactivado</b>
                                </Button>
                              )}
                            </p>
                          </h3>
                        </Col>
                        <Col xl="1" sm="12">
                          <h2 className={"refugiotext"}>
                            <ImHome />
                          </h2>
                        </Col>

                        <Col xs="12">
                          {refugio.municipio ? (
                            <h5>
                              <strong>
                                {"Municipio: "}
                                <strong className={"refugiotext"}>
                                  {refugio.municipio.municipio
                                    ? refugio.municipio.municipio
                                    : null}
                                </strong>
                              </strong>
                            </h5>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          <strong>
                            {"Se utiliza en: "}
                            <strong className={"refugiotext"}>
                              {reportesCount}
                              {reportesCount === 1 ? " reporte" : " reportes"}
                            </strong>
                          </strong>
                        </Col>

                        <hr />

                        <Col xs="12">
                          {refugio.latitud && refugio.longitud ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Latitud y longitud: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <a
                                    href={
                                      "https://www.google.com/maps/search/" +
                                      refugio.latitud +
                                      ",+" +
                                      refugio.longitud +
                                      ""
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    className="linktext"
                                  >
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>Ver en Google maps</Tooltip>
                                      }
                                    >
                                      <Button
                                        size="sm"
                                        variant={"refugio"}
                                        className="mb-1"
                                      >
                                        <b>
                                          {refugio.latitud +
                                            "°, " +
                                            refugio.longitud +
                                            "° "}
                                          <FaMapMarkerAlt />
                                        </b>
                                      </Button>
                                    </OverlayTrigger>
                                    <strong></strong>
                                  </a>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {refugio.direccion ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Dirección: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong className={"refugiotext"}>
                                    {refugio.direccion}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>

                        <Col xs="12">
                          {refugio.capacidad ? (
                            <Form.Group as={Row}>
                              <Col xl="3" md="12">
                                <h5>
                                  <strong>{"Capacidad: "}</strong>
                                </h5>
                              </Col>
                              <Col xl="9" md="12">
                                <h5>
                                  <strong className={"refugiotext"}>
                                    {refugio.capacidad + " personas"}
                                  </strong>
                                </h5>
                              </Col>
                            </Form.Group>
                          ) : null}
                        </Col>
                      </Row>

                      <p className="nocolor mediumtext">&zwnj;</p>

                      <Stack direction="horizontal" gap={3}>
                        <div>
                          <Button
                            variant={"outline-refugio"}
                            className="ms-auto"
                            size="lg"
                            onClick={this.goBack}
                          >
                            <ImArrowLeft2 /> Regresar
                          </Button>
                        </div>

                        <div className="ms-auto">
                          <Link to={"/refugios/" + this.props.router.params.id}>
                            <Button variant={"gold"} size="lg">
                              Editar <AiFillEdit />
                            </Button>
                          </Link>
                        </div>

                        {refugio.latitud && refugio.longitud ? (
                          <div>
                            <a
                              href={
                                "https://www.google.com/maps/search/" +
                                refugio.latitud +
                                ",+" +
                                refugio.longitud +
                                ""
                              }
                              target="_blank"
                              rel="noreferrer"
                              className="linktext"
                            >
                              <Button size="lg" variant={"whatsapp2"}>
                                <b>
                                  {"Ver en Google maps "}
                                  <FaMapMarkerAlt />
                                </b>
                              </Button>
                              <strong></strong>
                            </a>
                          </div>
                        ) : null}
                      </Stack>
                    </div>
                  </Alert>
                )}

                {this.state.messageError ? (
                  <Alert variant={"error"} className="text-center">
                    <Alert.Heading>
                      <strong>ERROR: </strong>
                    </Alert.Heading>
                    <div className="text-center">{this.state.messageError}</div>
                  </Alert>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(RefugioView);
