import React, { Component } from "react";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import {
  Alert,
  Row,
  Col,
  Form,
  Stack,
  Button,
  Spinner,
  Badge,
} from "react-bootstrap";
import { ImArrowLeft2 } from "react-icons/im";
import { MdPictureAsPdf } from "react-icons/md";
import { BsFillFileTextFill } from "react-icons/bs";
import { FaExclamationTriangle } from "react-icons/fa";
import AuthService from "../../services/AuthService";
import ReporteService from "../../services/ReporteService";
import EventBus from "../../common/EventBus";

var fechaActual = new Date() - -86400000;
var fechaActualSemana = new Date() - 777600000;
var year = new Date(fechaActual).getFullYear();
var month = new Date(fechaActual).getMonth() + 1;
var day = new Date(fechaActual).getDate();
var mesActual;
var semActual;
var diaActual;

var firstDay = new Date(year, 0, 1);
var numberOfDays = Math.abs(fechaActualSemana - firstDay) / (1000 * 3600 * 24);
var week = Math.ceil(numberOfDays / 7);

if (month < 10) {
  mesActual = "0" + month;
} else {
  mesActual = month;
}

if (day < 10) {
  diaActual = "0" + day;
} else {
  diaActual = day;
}

if (week < 1) {
  semActual = year + "-W01";
} else if (week < 10) {
  semActual = year + "-W0" + week;
} else {
  semActual = year + "-W" + week;
}

const modalidades = [
  "Reporte Diario de mesas (RDM)",
  "Reporte Concentrado de Fin de Semana (RCFSM)",
];

const monthNames = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];

const badge = (
  <Badge bg="dark">
    Falta este campo por llenar <FaExclamationTriangle />
  </Badge>
);

class ReporteInforme extends Component {
  constructor(props) {
    super(props);
    this.onChangeModalidad = this.onChangeModalidad.bind(this);
    this.onChangeFechaDiario = this.onChangeFechaDiario.bind(this);
    this.onChangeFechaSemana = this.onChangeFechaSemana.bind(this);
    this.getInforme = this.getInforme.bind(this);

    this.state = {
      modalidad: "0",
      fechaIni: null,
      fechaFin: null,

      informe: null,

      prefijoNombre: null,
      sufijoNombre: null,

      link: null,

      camposFaltantes: true,
      cargando: false,
      messageError: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Generar Informe";

      this.handleKeypress();
    }
  }

  getRequestParams(fechaIni, fechaFin) {
    let params = {};
    if (fechaIni) {
      switch (new Date(fechaIni).getDay()) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          params["fechaIni"] = fechaIni + "T07:00";
          break;

        case 6:
        case 0:
          params["fechaIni"] = fechaIni + "T10:00";
          break;

        default:
          params["fechaIni"] = fechaIni + "T07:00";
          break;
      }
    }

    if (fechaFin) {
      switch (new Date(fechaFin).getDay()) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 0:
          params["fechaFin"] = fechaFin + "T06:59";
          break;

        case 5:
        case 6:
          params["fechaFin"] = fechaFin + "T09:59";
          break;

        default:
          params["fechaFin"] = fechaFin + "T06:59";
          break;
      }
    }

    return params;
  }

  getInforme() {
    const { fechaIni, fechaFin, prefijoNombre, sufijoNombre } = this.state;
    const params = this.getRequestParams(fechaIni, fechaFin);
    document.title = "SICECOM - Generar Informe";

    this.setState({
      cargando: true,
      messageError: null,
    });
    ReporteService.getInforme(params)
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download =
          prefijoNombre +
          "MESAS DE COORDINACION PARA LA CONSTRUCCION DE LA PAZ" +
          sufijoNombre +
          ".pdf";
        fileLink.click();
        this.setState({
          cargando: false,
        });
      })
      .catch((err) => {
        document.title = "SICECOM - Informe ERROR";

        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              cargando: false,
              messageError: "Error en la generación del reporte: \n" + err,
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  onChangeModalidad(e) {
    this.setState({
      modalidad: e.target.value,
      prefijoNombre: null,
      sufijoNombre: null,
      fechaIni: null,
      fechaFin: null,
    });
  }

  onChangeFechaSemana(e) {
    const fechaInicio = this.getFridayOfWeek(
      e.target.value.slice(0, 4),
      e.target.value.slice(6)
    );
    const fechaFinal = this.getMondayOfWeek(
      e.target.value.slice(0, 4),
      e.target.value.slice(6)
    );

    if (
      new Date(fechaInicio + "T00:00").getMonth() !==
      new Date(fechaFinal + "T00:00").getMonth()
    ) {
      this.setState({
        sufijoNombre:
          " DEL " +
          new Date(fechaInicio + "T00:00")
            .getDate()
            .toString()
            .padStart(2, "0") +
          " DE " +
          monthNames[new Date(fechaInicio + "T00:00").getMonth()] +
          " AL " +
          new Date(fechaFinal + "T00:00")
            .getDate()
            .toString()
            .padStart(2, "0") +
          " DE " +
          monthNames[new Date(fechaFinal + "T00:00").getMonth()],
      });
    } else {
      this.setState({
        sufijoNombre:
          " DEL " +
          new Date(fechaInicio + "T00:00")
            .getDate()
            .toString()
            .padStart(2, "0") +
          " AL " +
          new Date(fechaFinal + "T00:00")
            .getDate()
            .toString()
            .padStart(2, "0") +
          " DE " +
          monthNames[new Date(fechaFinal + "T00:00").getMonth()],
      });
    }

    this.setState({
      prefijoNombre: e.target.value.slice(6).padStart(3, "0") + " - RCFSM - ",
      fechaIni: fechaInicio,
      fechaFin: fechaFinal,
    });
    this.handleKeypress();
  }

  onChangeFechaDiario(e) {
    const fechaInicioFinal = e.target.value;

    let year = new Date(fechaInicioFinal + "T00:00").getFullYear();
    let firstDay = new Date(year, 0, 1, 0, 0);
    let numberOfDays = Math.round(
      Math.abs(new Date(fechaInicioFinal + "T00:00") - firstDay) /
        (1000 * 3600 * 24)
    );

    switch (new Date(fechaInicioFinal + "T00:00").getDay()) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        this.setState({
          sufijoNombre: " 0700",
        });
        break;

      case 0:
      case 6:
        this.setState({
          sufijoNombre: " 1000",
        });
        break;

      default:
        this.setState({
          sufijoNombre: " 0700",
        });
        break;
    }

    this.setState({
      prefijoNombre:
        numberOfDays.toString().padStart(4, "0") +
        ". " +
        fechaInicioFinal.slice(8, 10) +
        fechaInicioFinal.slice(5, 7) +
        fechaInicioFinal.slice(2, 4) +
        " REPORTE DE ",
      fechaIni: fechaInicioFinal,
      fechaFin: fechaInicioFinal,
    });
    this.handleKeypress();
  }

  getFridayOfWeek = (y, w) => {
    var d = w * 7;
    var dayNumber = new Date(y, 0, d).getDay();

    switch (dayNumber) {
      case 0:
        d = d - 2;
        break;

      case 1:
        d = d - 3;
        break;

      case 2:
        d = d - 4;
        break;

      case 3:
        d = d - 5;
        break;

      case 4:
        d = d + 1;
        break;

      case 5:
        d = d + 0;
        break;

      case 6:
        d = d - 1;
        break;

      default:
        break;
    }

    let year = new Date(y, 0, d).getFullYear();
    let month = new Date(y, 0, d).getMonth() + 1;
    let day = new Date(y, 0, d).getDate();
    let mesActual;
    let diaActual;

    if (month < 10) {
      mesActual = "0" + month;
    } else {
      mesActual = month;
    }
    if (day < 10) {
      diaActual = "0" + day;
    } else {
      diaActual = day;
    }

    return year + "-" + mesActual + "-" + diaActual;
  };

  getMondayOfWeek = (y, w) => {
    var d = w * 7;
    var dayNumber = new Date(y, 0, d).getDay();

    switch (dayNumber) {
      case 0:
        d = d + 1;
        break;

      case 1:
        d = d + 0;
        break;

      case 2:
        d = d - 1;
        break;

      case 3:
        d = d - 2;
        break;

      case 4:
        d = d + 4;
        break;

      case 5:
        d = d + 3;
        break;

      case 6:
        d = d + 2;
        break;

      default:
        break;
    }

    let year = new Date(y, 0, d).getFullYear();
    let month = new Date(y, 0, d).getMonth() + 1;
    let day = new Date(y, 0, d).getDate();
    let mesActual;
    let diaActual;

    if (month < 10) {
      mesActual = "0" + month;
    } else {
      mesActual = month;
    }
    if (day < 10) {
      diaActual = "0" + day;
    } else {
      diaActual = day;
    }

    return year + "-" + mesActual + "-" + diaActual;
  };

  handleKeypress = () => {
    if (this.state.fechaIni && this.state.fechaFin) {
      this.setState({
        camposFaltantes: false,
      });
    } else {
      this.setState({
        camposFaltantes: true,
      });
    }
  };

  goBack = () => {
    this.props.router.navigate(-1);
  };

  render() {
    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                <p className="nocolor bigtext">&zwnj;</p>

                <Alert variant="dark" onMouseMove={this.handleKeypress}>
                  <div className="m-1 d-grid gap-2 d-md-block">
                    <h2 className="text-center">
                      <b>GENERAR INFORME </b> <BsFillFileTextFill />
                    </h2>

                    {this.state.fechaIni && this.state.fechaFin ? (
                      <h2 className="text-center">
                        <Button
                          variant="dark"
                          size="lg"
                          style={{ pointerEvents: "none" }}
                        >
                          <b>
                            {new Date(
                              this.state.fechaIni + "T00:00"
                            ).getDate() ===
                            new Date(this.state.fechaFin + "T00:00").getDate()
                              ? "DEL " +
                                new Date(
                                  this.state.fechaIni + "T00:00"
                                ).getDate() +
                                " DE " +
                                monthNames[
                                  new Date(
                                    this.state.fechaIni + "T00:00"
                                  ).getMonth()
                                ]
                              : new Date(
                                  this.state.fechaIni + "T00:00"
                                ).getMonth() !==
                                new Date(
                                  this.state.fechaFin + "T00:00"
                                ).getMonth()
                              ? "DEL " +
                                new Date(
                                  this.state.fechaIni + "T00:00"
                                ).getDate() +
                                " DE " +
                                monthNames[
                                  new Date(
                                    this.state.fechaIni + "T00:00"
                                  ).getMonth()
                                ] +
                                " AL " +
                                new Date(
                                  this.state.fechaFin + "T00:00"
                                ).getDate() +
                                " DE " +
                                monthNames[
                                  new Date(
                                    this.state.fechaFin + "T00:00"
                                  ).getMonth()
                                ]
                              : "DEL " +
                                new Date(
                                  this.state.fechaIni + "T00:00"
                                ).getDate() +
                                " AL " +
                                new Date(
                                  this.state.fechaFin + "T00:00"
                                ).getDate() +
                                " DE " +
                                monthNames[
                                  new Date(
                                    this.state.fechaFin + "T00:00"
                                  ).getMonth()
                                ]}
                          </b>
                        </Button>
                      </h2>
                    ) : null}

                    <Row>
                      <p className="nocolor mediumtext">&zwnj;</p>

                      <Col xs="12">
                        <Form.Group as={Row} className="mb-1">
                          <Col sm="1"></Col>
                          <Col sm="10">
                            <h4>
                              <strong className="text-dark">
                                <i>
                                  Seleccione la modalidad y la fecha del
                                  informe:
                                </i>
                              </strong>
                            </h4>
                          </Col>
                          <Col sm="1"></Col>
                        </Form.Group>
                      </Col>

                      <Col xl="6" sm="12">
                        <Form.Group
                          as={Row}
                          className="mb-1"
                          controlId="modalidad"
                        >
                          <Col sm="1"></Col>
                          <Col sm="10">
                            <Form.Label>
                              <h4>
                                <strong>Modalidad:</strong>
                              </h4>
                            </Form.Label>
                            <Form.Select
                              onChange={this.onChangeModalidad}
                              onMouseLeave={this.handleKeypress}
                              size="lg"
                              disabled={this.state.cargando}
                            >
                              {modalidades.map((modalidad, index) => (
                                <option key={index} value={index}>
                                  {modalidad}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col sm="1"></Col>
                        </Form.Group>
                      </Col>

                      {this.state.modalidad === "0" ? (
                        <Col xl="6" sm="12">
                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId="fecha"
                          >
                            <Col sm="1"></Col>
                            <Col sm="10">
                              <Form.Label>
                                <h4>
                                  <strong>Fecha del informe:</strong>
                                </h4>
                              </Form.Label>
                              <Form.Group className="mb-1" controlId="fechaIni">
                                <Form.Control
                                  type="date"
                                  size="lg"
                                  onChange={this.onChangeFechaDiario}
                                  min={"2020-01-01"}
                                  max={year + "-" + mesActual + "-" + diaActual}
                                  disabled={this.state.cargando}
                                />
                                <Alert
                                  variant={"nocolor"}
                                  show={this.state.fechaIni ? false : true}
                                >
                                  {badge}
                                </Alert>
                              </Form.Group>
                            </Col>
                            <Col sm="1"></Col>
                          </Form.Group>
                        </Col>
                      ) : null}

                      {this.state.modalidad === "1" ? (
                        <Col xl="6" sm="12">
                          <Form.Group
                            as={Row}
                            className="mb-1"
                            controlId="fecha"
                          >
                            <Col sm="1"></Col>
                            <Col sm="10">
                              <Form.Label>
                                <h4>
                                  <strong>Fin de semana del informe:</strong>
                                </h4>
                              </Form.Label>
                              <Form.Group className="mb-1" controlId="fechaIni">
                                <Form.Control
                                  type="week"
                                  size="lg"
                                  onChange={this.onChangeFechaSemana}
                                  min={"2020-W01"}
                                  max={semActual}
                                  disabled={this.state.cargando}
                                />
                                <Alert
                                  variant={"nocolor"}
                                  show={this.state.fechaIni ? false : true}
                                >
                                  {badge}
                                </Alert>
                              </Form.Group>
                            </Col>
                            <Col sm="1"></Col>
                          </Form.Group>
                        </Col>
                      ) : null}
                    </Row>

                    <p className="nocolor bigtext">&zwnj;</p>

                    <Stack direction="horizontal" gap={3}>
                      <div>
                        <Button
                          variant="outline-dark"
                          className="ms-auto"
                          size="lg"
                          onClick={this.goBack}
                        >
                          <ImArrowLeft2 /> Regresar
                        </Button>
                      </div>

                      <div
                        className="ms-auto"
                        onMouseOver={this.handleKeypress}
                      >
                        {this.state.cargando ? (
                          <Button
                            variant="dark"
                            disabled={true}
                            className="m-2"
                            size="lg"
                          >
                            <b>Cargando</b>{" "}
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          </Button>
                        ) : (
                          <Button
                            variant="dark"
                            disabled={this.state.camposFaltantes}
                            className="m-2"
                            size="lg"
                            onClick={this.getInforme}
                          >
                            <b>Generar</b> <MdPictureAsPdf />
                          </Button>
                        )}
                      </div>
                    </Stack>
                  </div>
                </Alert>

                {this.state.messageError ? (
                  <Alert variant={"error"} className="text-center">
                    <Alert.Heading>
                      <strong>ERROR </strong>
                    </Alert.Heading>
                    <div className="text-center">{this.state.messageError}</div>
                  </Alert>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(ReporteInforme);
