import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/AuthService";
import Sidebar from "../../bars/Sidebar";
import Navbar from "../../bars/Navbar";
import { BsFillPersonPlusFill } from "react-icons/bs";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-error" role="alert">
        Este campo es requerido
      </div>
    );
  }
};

const vnombre = (value) => {
  if (value.length < 2 || value.length > 32) {
    return (
      <div className="alert alert-error" role="alert">
        El nombre debe tener mínimo 2 caracteres.
      </div>
    );
  }
};

const vapellidoP = (value) => {
  if (value.length < 3 || value.length > 32) {
    return (
      <div className="alert alert-error" role="alert">
        El apellido paterno debe tener mínimo 3 caracteres.
      </div>
    );
  }
};

const vapellidoM = (value) => {
  if (value.length < 3 || value.length > 32) {
    return (
      <div className="alert alert-error" role="alert">
        El apellido materno debe tener mínimo 3 caracteres.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 64) {
    return (
      <div className="alert alert-error" role="alert">
        La contraseña debe tener mínimo 6 caracteres.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleUser = this.handleUser.bind(this);
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeApellidoP = this.onChangeApellidoP.bind(this);
    this.onChangeApellidoM = this.onChangeApellidoM.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);

    this.state = {
      showAdminBoard: false,

      nombre: "",
      apellidoP: "",
      apellidoM: "",
      user: "",
      password: "",
      successful: false,
      message: "",
      customUser: false,

      userReady: false,
      currentUser: {
        username: "",
      },
    };
  }

  onChangeNombre(e) {
    this.setState({
      nombre: e.target.value,
    });
  }

  onChangeApellidoP(e) {
    this.setState({
      apellidoP: e.target.value,
    });
  }

  onChangeApellidoM(e) {
    this.setState({
      apellidoM: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeUser(e) {
    this.setState({
      user: e.target.value,
    });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Registrar usuario";

      this.setState({
        currentUser: currentUser.username,
        showAdminBoard: currentUser.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.nombre.charAt(0).toUpperCase() + this.state.nombre.slice(1),
        this.state.apellidoP.charAt(0).toUpperCase() +
          this.state.apellidoP.slice(1),
        this.state.apellidoM.charAt(0).toUpperCase() +
          this.state.apellidoM.slice(1),
        (
          this.state.nombre.charAt(0) +
          this.state.apellidoP +
          this.state.apellidoM.charAt(0)
        )
          .replace(/ /g, "")
          .toLowerCase(),
        this.state.password
      ).then(
        (response) => {
          this.setState({
            message: response.data.message,
            successful: true,
            user: (
              this.state.nombre.charAt(0) +
              this.state.apellidoP +
              this.state.apellidoM.charAt(0)
            )
              .replace(/ /g, "")
              .toLowerCase(),
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            customUser: true,
            message: "ERROR: " + resMessage,
            user: (
              this.state.nombre.charAt(0) +
              this.state.apellidoP +
              this.state.apellidoM.charAt(0)
            )
              .replace(/ /g, "")
              .toLowerCase(),
          });
        }
      );
    }
  }

  handleUser(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.nombre.charAt(0).toUpperCase() + this.state.nombre.slice(1),
        this.state.apellidoP.charAt(0).toUpperCase() +
          this.state.apellidoP.slice(1),
        this.state.apellidoM.charAt(0).toUpperCase() +
          this.state.apellidoM.slice(1),
        this.state.user.replace(/ /g, "").toLowerCase(),
        this.state.password
      ).then(
        (response) => {
          this.setState({
            message: response.data.message,
            successful: true,
            user: this.state.user.replace(/ /g, "").toLowerCase(),
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            customUser: true,
            message: "ERROR: " + resMessage,
            user: this.state.user.replace(/ /g, "").toLowerCase(),
          });
        }
      );
    }
  }

  newUser = () => {
    this.setState({
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      user: "",
      password: "",
      successful: false,
      message: "",
      customUser: false,
    });
  };

  render() {
    const { showAdminBoard } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />

          {showAdminBoard ? (
            <div className="content w-100 cont">
              <Navbar />
              <div className="col-md-12">
                <div className="card card-size">
                  <h3 className="text-center">
                    <b>REGISTRAR USUARIO</b>
                  </h3>
                  <h2 className={"text-center"}>
                    <BsFillPersonPlusFill />
                  </h2>

                  <Form
                    onSubmit={
                      this.state.customUser
                        ? this.handleUser
                        : this.handleRegister
                    }
                    ref={(c) => {
                      this.form = c;
                    }}
                  >
                    {!this.state.successful && (
                      <div>
                        <div className="form-group">
                          <label htmlFor="nombre">
                            <strong>Nombre:</strong>
                          </label>
                          <Input
                            type="text"
                            name="nombre"
                            value={this.state.nombre}
                            onChange={this.onChangeNombre}
                            validations={[required, vnombre]}
                            maxLength="32"
                            className="form-control"
                            autoFocus
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="apellidoP">
                            <strong>Apellido paterno:</strong>
                          </label>
                          <Input
                            type="text"
                            name="apellidoP"
                            value={this.state.apellidoP}
                            onChange={this.onChangeApellidoP}
                            validations={[required, vapellidoP]}
                            maxLength="32"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="apellidoM">
                            <strong>Apellido materno:</strong>
                          </label>
                          <Input
                            type="text"
                            name="apellidoP"
                            value={this.state.apellidoM}
                            onChange={this.onChangeApellidoM}
                            validations={[required, vapellidoM]}
                            maxLength="32"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="password">
                            <strong>Contraseña:</strong>
                          </label>
                          <Input
                            type="password"
                            name="password"
                            autoComplete="off"
                            value={this.state.password}
                            onChange={this.onChangePassword}
                            validations={[required, vpassword]}
                            maxLength="64"
                            className="form-control"
                          />
                        </div>
                        <br />
                        <div className="form-group centered">
                          <button className="btn btn-dark btn-block">
                            Registrar
                          </button>
                        </div>
                        <p className="nocolor mediumtext">&zwnj;</p>
                      </div>
                    )}

                    <div className="centered">
                      <CheckButton
                        className="centered invisible"
                        style={{ display: "" }}
                        ref={(c) => {
                          this.checkBtn = c;
                        }}
                      />
                    </div>

                    {this.state.message && (
                      <>
                        {this.state.successful ? (
                          <div className="form-group">
                            <div className="alert alert-success" role="alert">
                              <div className="text-center">
                                <h4>
                                  <b>{this.state.message}</b>
                                </h4>
                                <hr />
                                <strong>Usuario: </strong>
                                {this.state.user}
                                <br />
                                <strong>Contraseña: </strong>
                                {this.state.password}
                                <hr />
                                <h5>
                                  ¡Asegurese de guardar sus datos en un lugar
                                  seguro!
                                </h5>
                              </div>
                            </div>

                            <div className="centered">
                              <button
                                className="btn btn-dark"
                                onClick={this.newUser}
                              >
                                Agregar otro usuario
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              <div className="text-center">
                                <b>{this.state.message}</b>
                                <br />
                                <strong>Ingrese un usuario distinto: </strong>

                                <Input
                                  type="text"
                                  className="form-control text-center"
                                  name="user"
                                  value={this.state.user}
                                  onChange={this.onChangeUser}
                                  maxLength="34"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          ) : (
            <h3 className="content w-100 cont">
              <strong>USUARIO NO PERMITIDO</strong>
            </h3>
          )}
        </div>
      </>
    );
  }
}
