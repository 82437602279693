import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventBus from "../../../../common/EventBus";
import Navbar from "../../../../bars/Navbar";
import Sidebar from "../../../../bars/Sidebar";
import WithRouter from "../../../../common/WithRouter";
import Dropzone from "react-dropzone";
import {
  Alert,
  Row,
  Col,
  Form,
  Stack,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Popover,
  Badge,
} from "react-bootstrap";
import { BsCheckLg, BsEyeFill } from "react-icons/bs";
import {
  FaPaste,
  FaMapMarkerAlt,
  FaQuestionCircle,
  FaExclamationTriangle,
  FaCheckCircle,
  FaCompressArrowsAlt,
} from "react-icons/fa";
import { ImArrowLeft2, ImCross, ImPlus, ImHome } from "react-icons/im";
import { BiWorld } from "react-icons/bi";
import { MdEditNote, MdList } from "react-icons/md";
import ReporteService from "../../../../services/ReporteService";
import MunicipioService from "../../../../services/MunicipioService";
import AuthService from "../../../../services/AuthService";
import UserService from "../../../../services/UserService";

let fechaActual = new Date();
let year = new Date(fechaActual).getFullYear();
let month = new Date(fechaActual).getMonth() + 1;
let day = new Date(fechaActual).getDate();
let hour = new Date(fechaActual).getHours();
let minute = new Date(fechaActual).getMinutes();
let mesActual;
let diaActual;
let horaActual;
let minActual;

if (month < 10) {
  mesActual = "0" + month;
} else {
  mesActual = month;
}

if (day < 10) {
  diaActual = "0" + day;
} else {
  diaActual = day;
}

if (hour < 10) {
  horaActual = "0" + hour;
} else {
  horaActual = hour;
}

if (minute < 10) {
  minActual = "0" + minute;
} else {
  minActual = minute;
}

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}
var nivelesEmergencia = range(1, 3);

const niveles = ["nivel1", "nivel2", "nivel3"];

const badge = (
  <Badge bg="info">
    Falta este campo por llenar <FaExclamationTriangle />
  </Badge>
);

class AstABCAdd extends Component {
  constructor(props) {
    super(props);
    this.onChangeTipo = this.onChangeTipo.bind(this);
    this.onChangeEvento = this.onChangeEvento.bind(this);
    this.onChangeEstimado = this.onChangeEstimado.bind(this);
    this.onChangeReporta = this.onChangeReporta.bind(this);
    this.onChangeCargo = this.onChangeCargo.bind(this);
    this.onChangeFechaOcurrencia = this.onChangeFechaOcurrencia.bind(this);
    this.onChangeAccionesImplementadas =
      this.onChangeAccionesImplementadas.bind(this);
    this.onChangeFuerzaTarea = this.onChangeFuerzaTarea.bind(this);
    this.onChangeNotas = this.onChangeNotas.bind(this);
    this.onChangeEvidencia = this.onChangeEvidencia.bind(this);
    this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
    this.onChangeLocalidad = this.onChangeLocalidad.bind(this);
    this.onChangeLocalidadList = this.onChangeLocalidadList.bind(this);
    this.onChangeEstatus = this.onChangeEstatus.bind(this);
    this.onChangeNivelEmergencia = this.onChangeNivelEmergencia.bind(this);
    this.onChangeLatitud = this.onChangeLatitud.bind(this);
    this.onChangeLongitud = this.onChangeLongitud.bind(this);
    this.pasteLatitudLongitud = this.pasteLatitudLongitud.bind(this);
    this.resetRefugio = this.resetRefugio.bind(this);
    this.getRefugios = this.getRefugios.bind(this);
    this.onChangeRefugio = this.onChangeRefugio.bind(this);
    this.onChangeFechaActivacion = this.onChangeFechaActivacion.bind(this);
    this.onChangeFechaDesactivacion =
      this.onChangeFechaDesactivacion.bind(this);
    this.onChangeUbicaciones = this.onChangeUbicaciones.bind(this);
    this.onChangeUbicacionesN = this.onChangeUbicacionesN.bind(this);
    this.onChangePersonasEvacuadas = this.onChangePersonasEvacuadas.bind(this);
    this.onChangePersonasEvacuadasN =
      this.onChangePersonasEvacuadasN.bind(this);
    this.onChangePersonasLocalizadas =
      this.onChangePersonasLocalizadas.bind(this);
    this.onChangePersonasLocalizadasN =
      this.onChangePersonasLocalizadasN.bind(this);
    this.onChangePersonasNoLocalizadas =
      this.onChangePersonasNoLocalizadas.bind(this);
    this.onChangePersonasNoLocalizadasN =
      this.onChangePersonasNoLocalizadasN.bind(this);
    this.onChangePersonasLesionadas =
      this.onChangePersonasLesionadas.bind(this);
    this.onChangePersonasLesionadasN =
      this.onChangePersonasLesionadasN.bind(this);
    this.onChangePersonasFallecidas =
      this.onChangePersonasFallecidas.bind(this);
    this.onChangePersonasFallecidasN =
      this.onChangePersonasFallecidasN.bind(this);
    this.onChangeFallecidos = this.onChangeFallecidos.bind(this);


    this.getUser = this.getUser.bind(this);
    this.getEstatusList = this.getEstatusList.bind(this);
    this.getTipo = this.getTipo.bind(this);
    this.getTipos = this.getTipos.bind(this);
    this.getMunicipios = this.getMunicipios.bind(this);
    this.getLocalidades = this.getLocalidades.bind(this);
    this.saveAST_ABC = this.saveAST_ABC.bind(this);
    this.saveReporte = this.saveReporte.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.state = {
      tipos: [],
      municipios: [],
      localidades: [],
      refugios: [],
      estatusList: [],

      id: null,

      fenomeno: null,
      tipoN: null,
      tipo: {
        id: null,
        nombreT: null,
        prefijo: null,
        contador: null,
        fenomeno: {
          id: null,
          nombreF: null,
        },
      },
      folio: null,
      municipio: null,
      municipioCompleto: null,
      localidad: null,
      localidadList: true,

      evento: null,
      fechaReporte:
        year +
        "-" +
        mesActual +
        "-" +
        diaActual +
        "T" +
        horaActual +
        ":" +
        minActual,
      estimado: false,
      reporta: null,
      cargo: null,
      fechaOcurrencia: null,
      latitud: null,
      longitud: null,
      accionesImplementadas: null,
      fuerzaTarea: null,
      notas: null,
      evidencia: null,
      tipoR: null,
      estatus: null,
      nivelEmergencia: null,
      refugio: null,
      fechaActivacion: null,
      fechaDesactivacion: null,

      ubicaciones: null,
      ubicacionesN: null,
      personasEvacuadas: null,
      personasEvacuadasN: null,
      personasLocalizadas: null,
      personasLocalizadasN: null,
      personasNoLocalizadas: null,
      personasNoLocalizadasN: null,
      personasLesionadas: null,
      personasLesionadasN: null,
      personasFallecidas: null,
      personasFallecidasN: null,
      fallecidos: 1,

      camposFaltantes: true,
      subido: false,
      cargando: false,
      errPegado: false,
      posicionLa: false,
      posicionLo: false,
      cargandoReporte: true,

      selectedFiles: undefined,
      progressInfos: [],
      message: null,
      messageError: null,
      fileInfos: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      setInterval(() => {
        this.getDate();
      }, 1000);
      this.getUser(currentUser.id);

      ReporteService.getFiles().then((response) => {
        this.setState({
          fileInfos: response.data,
        });
      });
      this.getEstatusList();
      this.getMunicipios();
      this.getTipo();
      this.getTipos();
    }
  }

  getDate = () => {
    fechaActual = new Date();

    year = new Date(fechaActual).getFullYear();
    month = new Date(fechaActual).getMonth() + 1;
    day = new Date(fechaActual).getDate();
    hour = new Date(fechaActual).getHours();
    minute = new Date(fechaActual).getMinutes();

    if (month < 10) {
      mesActual = "0" + month;
    } else {
      mesActual = month;
    }

    if (day < 10) {
      diaActual = "0" + day;
    } else {
      diaActual = day;
    }

    if (hour < 10) {
      horaActual = "0" + hour;
    } else {
      horaActual = hour;
    }

    if (minute < 10) {
      minActual = "0" + minute;
    } else {
      minActual = minute;
    }

    this.setState({
      fechaReporte:
        year +
        "-" +
        mesActual +
        "-" +
        diaActual +
        "T" +
        horaActual +
        ":" +
        minActual,
    });
  };

  onChangeTipo(e) {
    this.getTipos();
    this.setState({
      tipoN: e.target.value,
    });
    document.title =
      "Agregar reporte " + this.state.tipos[e.target.value].prefijo;
  }

  onChangeEvento(e) {
    this.setState({
      evento: e.target.value,
    });
  }

  onChangeMunicipio(e) {
    this.getLocalidades(e.target.value);
    this.getRefugios(e.target.value);

    MunicipioService.getMunicipio(e.target.value)
      .then((response) => {
        this.setState({
          municipioCompleto: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });

    this.setState({
      localidad: null,

      refugio: null,
      municipio: e.target.value,
    });
  }

  onChangeLocalidad(e) {
    this.setState({
      localidad: e.target.value,
    });
  }

  onChangeLocalidadList() {
    this.setState({
      localidad: null,
      localidadList: !this.state.localidadList,
    });
  }

  onChangeEstimado() {
    if (this.state.estimado) {
      this.setState({
        estimado: false,
      });
    } else {
      this.setState({
        estimado: true,
      });
    }
  }

  onChangeReporta(e) {
    this.setState({
      reporta: e.target.value,
    });
  }

  onChangeCargo(e) {
    this.setState({
      cargo: e.target.value,
    });
  }

  onChangeFechaOcurrencia(e) {
    this.setState({
      fechaOcurrencia: e.target.value,
    });
  }

  onChangeLatitud(e) {
    this.setState({
      latitud: e.target.value.replace(/[^\d.]/g, ""),
    });
  }

  onChangeLongitud(e) {
    this.setState({
      longitud: e.target.value.replace(/[^\d.]/g, ""),
    });
  }

  pasteLatitudLongitud() {
    navigator.clipboard
      .readText()
      .then((response) => {
        var paste = response.split(", -");
        this.setState({
          latitud: paste[0].slice(0, 13),
          longitud: paste[1].slice(0, 13),
        });
        this.handlePosicion();
      })
      .catch(() => this.errPegado());
  }

  errPegado() {
    this.setState({
      errPegado: true,
    });
    setTimeout(() => {
      this.setState({
        errPegado: false,
      });
    }, 1500);
  }

  onChangeUbicaciones(e) {
    this.setState({
      ubicaciones: e.target.value,
    });
  }

  onChangeUbicacionesN(e) {
    this.setState({
      ubicacionesN: e.target.value.replace(/[^0-9]/gi, ""),
    });
  }

  onChangePersonasEvacuadas(e) {
    this.setState({
      personasEvacuadas: e.target.value,
    });
  }

  onChangePersonasEvacuadasN(e) {
    this.setState({
      personasEvacuadasN: e.target.value.replace(/[^0-9]/gi, ""),
    });
  }

  onChangePersonasLocalizadas(e) {
    this.setState({
      personasLocalizadas: e.target.value,
    });
  }

  onChangePersonasLocalizadasN(e) {
    this.setState({
      personasLocalizadasN: e.target.value.replace(/[^0-9]/gi, ""),
    });
  }

  onChangePersonasNoLocalizadas(e) {
    this.setState({
      personasNoLocalizadas: e.target.value,
    });
  }

  onChangePersonasNoLocalizadasN(e) {
    this.setState({
      personasNoLocalizadasN: e.target.value.replace(/[^0-9]/gi, ""),
    });
  }

  onChangePersonasLesionadas(e) {
    this.setState({
      personasLesionadas: e.target.value,
    });
  }

  onChangePersonasLesionadasN(e) {
    this.setState({
      personasLesionadasN: e.target.value.replace(/[^0-9]/gi, ""),
    });
  }

  onChangePersonasFallecidas(e) {
    this.setState({
      personasFallecidas: e.target.value,
    });
  }

  onChangePersonasFallecidasN(e) {
    this.setState({
      personasFallecidasN: e.target.value.replace(/[^0-9]/gi, ""),
    });
  }

  onChangeAccionesImplementadas(e) {
    this.setState({
      accionesImplementadas: e.target.value,
    });
  }

  onChangeFuerzaTarea(e) {
    this.setState({
      fuerzaTarea: e.target.value,
    });
  }

  onChangeNotas(e) {
    this.setState({
      notas: e.target.value,
    });
  }

  onChangeEvidencia(e) {
    this.setState({
      evidencia: e.target.value,
    });
  }

  onChangeEstatus(e) {
    this.setState({
      estatus: this.state.estatusList[e.target.value - 1],
    });
  }

  onChangeNivelEmergencia(e) {
    this.setState({
      nivelEmergencia: e.target.value,
    });
  }


  onChangeFallecidos() {
    if (this.state.fallecidos) {
      this.setState({
        fallecidos: 2,
      });
    } else {
      this.setState({
        fallecidos: 1 ,
      });
    }
  }


  onChangeRefugio(e) {
    this.setState({
      refugio: e.target.value,
    });
  }

  onChangeFechaActivacion(e) {
    this.setState({
      fechaActivacion: e.target.value,
    });
  }

  onChangeFechaDesactivacion(e) {
    this.setState({
      fechaDesactivacion: e.target.value,
    });
  }

  resetRefugio() {
    this.setState({
      refugio: null,
    });
  }

  getRefugios(municipio) {
    ReporteService.getRefugiosActivos(municipio)
      .then((response) => {
        this.setState({
          refugios: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  getEstatusList() {
    ReporteService.getEstatusList()
      .then((response) => {
        this.setState({
          estatusList: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  getTipo() {
    ReporteService.getTipos()
      .then((response) => {
        switch (this.props.router.params.tipo) {
          case "47":
          case "48":
          case "49":
            this.setState({
              cargandoReporte: false,
              tipo: response.data[this.props.router.params.tipo - 1],
            });
            document.title =
              "Agregar reporte " +
              response.data[this.props.router.params.tipo - 1].prefijo;
            break;

          default:
            this.props.router.navigate("/add");
        }
      })
      .catch((err) => {
        document.title = "SICECOM - Agregar reporte ERROR";

        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  getTipos() {
    ReporteService.getTipo(7)
      .then((response) => {
        this.setState({
          tipos: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  getMunicipios() {
    MunicipioService.getMunicipios()
      .then((response) => {
        this.setState({
          municipios: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  getLocalidades(municipio) {
    MunicipioService.getLocalidad(municipio)
      .then((response) => {
        this.setState({
          localidades: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error en la carga de datos. Por favor revise su conexión a internet y recargue la página. Si el error persiste contacte al administrador de la página.",
            },
            window.scrollTo(0, document.body.scrollHeight)
          );
          console.log(err);
        }
      });
  }

  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFiles: files });
    }
  }

  uploadFiles() {
    const selectedFiles = this.state.selectedFiles;
    const folio = this.state.folio;

    let _progressInfos = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }
    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i], folio);
        }
      }
    );
  }

  upload(idx, file, folio) {
    let _progressInfos = [...this.state.progressInfos];
    ReporteService.upload(
      file,
      (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({
          _progressInfos,
        });
      },
      folio
    )
      .then((response) => {
        this.setState((prev) => {
          let nextMessage = [...prev.message, file.name + ", "];
          return {
            message: nextMessage,
          };
        });
        return ReporteService.getFiles();
      })
      .then((files) => {
        this.setState({
          fileInfos: files.data,
        });
      })
      .catch((e) => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [...prev.message, e + ": " + file.name + " "];
          return {
            progressInfos: _progressInfos,
            message: nextMessage,
          };
        });
      });
  }

  handlePosicion = () => {
    if (this.state.latitud < 22.5 && this.state.latitud > 17.12) {
      this.setState({
        posicionLa: true,
      });
    } else {
      this.setState({
        posicionLa: false,
      });
    }
    if (this.state.longitud < 98.71 && this.state.longitud > 93.59) {
      this.setState({
        posicionLo: true,
      });
    } else {
      this.setState({
        posicionLo: false,
      });
    }

    setTimeout(() => {
      this.handleKeypress();
    }, 10);
  };

  handleKeypress = () => {
    if (
      this.state.estatus &&
      this.state.nivelEmergencia &&
      (this.state.tipo || this.state.tipoN) &&
      this.state.municipio &&
      this.state.localidad &&
      this.state.reporta &&
      this.state.fechaOcurrencia &&
      (this.state.posicionLa || !this.state.latitud) &&
      (this.state.posicionLo || !this.state.longitud) &&
      this.state.accionesImplementadas &&
      this.state.fuerzaTarea &&
      this.state.ubicaciones &&
      this.state.ubicacionesN
    ) {
      this.setState({
        camposFaltantes: false,
      });
    } else {
      this.setState({
        camposFaltantes: true,
      });
    }
  };

  getUser(id) {
    UserService.getUser(id)
      .then((response) => {
        this.setState({
          user: response.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            messageError: "Error en la carga del usuario.",
          });
          console.log(err);
        }
      });
  }

  saveAST_ABC(reporte) {
    var data = {
      ubicaciones: this.state.ubicaciones,
      ubicacionesN: this.state.ubicacionesN,
      personasEvacuadas: this.state.personasEvacuadas,
      personasEvacuadasN: this.state.personasEvacuadasN || 0,
      personasLocalizadas: this.state.personasLocalizadas,
      personasLocalizadasN: this.state.personasLocalizadasN || 0,
      personasNoLocalizadas: this.state.personasNoLocalizadas,
      personasNoLocalizadasN: this.state.personasNoLocalizadasN || 0,
      personasLesionadas: this.state.personasLesionadas,
      personasLesionadasN: this.state.personasLesionadasN || 0,
      personasFallecidas: this.state.personasFallecidas,
      personasFallecidasN: this.state.personasFallecidasN || 0,

      reporte: reporte,
    };

    ReporteService.createAST_ABC(data)
      .then(() => {
        this.setState({
          messageError: "",
          subido: true,
          cargando: false,
        });
        if (this.state.selectedFiles) {
          this.uploadFiles();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError:
                "Error de guardado AST ABC. Reintentando guardar...",
            },
            setTimeout(() => {
              this.saveAST_ABC(reporte);
            }, 1000)
          );
          console.log(err);
        }
      });
  }

  saveReporte() {
    this.getTipo();
    this.getTipos();

    this.setState({
      cargando: true,
    });

    var data = {
      localidad:
        this.state.localidad.charAt(0).toUpperCase() +
        this.state.localidad.slice(1),
      evento: this.state.evento,
      fechaReporte: this.state.fechaReporte,
      estimado: this.state.estimado,
      reporta:
        this.state.reporta.charAt(0).toUpperCase() +
        this.state.reporta.slice(1),
      cargo: this.state.cargo,
      latitud: this.state.latitud,
      longitud: this.state.longitud
        ? "-" + this.state.longitud
        : this.state.longitud,
      fechaOcurrencia: this.state.fechaOcurrencia,
      fuerzaTarea: this.state.fuerzaTarea,
      accionesImplementadas: this.state.accionesImplementadas,
      notas: this.state.notas,
      evidencia: this.state.evidencia,
      municipio: this.state.municipioCompleto,
      estatus: this.state.estatus,
      nivelEmergencia: this.state.nivelEmergencia,
      fallecidos: this.state.fallecidos,

      user: this.state.user,
      refugio: this.state.refugios[this.state.refugio],
      fechaActivacion: this.state.fechaActivacion
        ? this.state.fechaActivacion
        : null,
      fechaDesactivacion: this.state.fechaDesactivacion
        ? this.state.fechaDesactivacion
        : null,
    };
    if (this.state.tipoN) {
      data.tipoR = this.state.tipos[this.state.tipoN];
    } else {
      data.tipoR = this.state.tipo;
    }

    ReporteService.createReporte(data)
      .then((response) => {
        this.setState(
          {
            id: response.data.id,
            folio: response.data.folio,
            messageError: "",
          },
          this.saveAST_ABC(response.data)
        );
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState(
            {
              messageError: "Error de guardado. Reintentando guardar...",
            },
            setTimeout(() => {
              this.saveReporte();
            }, 1000)
          );
          console.log(err);
        }
      });
  }

  goBack = () => {
    this.props.router.navigate("/add");
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  render() {
    const { selectedFiles, progressInfos, tipo } = this.state;

    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                {this.state.cargandoReporte ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Alert variant="info">
                    {this.state.subido ? (
                      <div>
                        <Alert.Heading className="text-center">
                          <h3>
                            <b>REPORTE GENERADO CORRECTAMENTE</b>
                          </h3>
                          <h3>
                            <strong>
                              Folio:{" "}
                              <b className="text-info">{this.state.folio}</b>
                            </strong>
                          </h3>
                        </Alert.Heading>

                        {this.state.message ? (
                          <Alert
                            variant={"message"}
                            className="text-center edit-form"
                          >
                            <Alert.Heading>
                              <strong>Archivos subidos: </strong>
                            </Alert.Heading>
                            <div className="text-center">
                              {progressInfos &&
                                progressInfos.map((progressInfo, index) => (
                                  <div key={progressInfo.fileName}>
                                    <div className="mb-2" key={index}>
                                      <span>{progressInfo.fileName}</span>
                                      <div className="progress">
                                        <div
                                          className="progress-bar progress-bar-striped bg-dark progress-bar-animated"
                                          role="progressbar"
                                          aria-valuenow={
                                            progressInfo.percentage
                                          }
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          style={{
                                            width:
                                              progressInfo.percentage + "%",
                                          }}
                                        >
                                          {progressInfo.percentage}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </Alert>
                        ) : null}

                        <p className="nocolor mediumtext">&zwnj;</p>

                        <Stack direction="horizontal" gap={3}>
                          <div>
                            <Link to={"/reportesList"}>
                              <Button variant="outline-info" size="lg">
                                <ImArrowLeft2 /> Ir a la lista
                              </Button>
                            </Link>
                          </div>
                          <div className="ms-auto">
                            <Link to={"/reporte/" + this.state.id}>
                              <Button variant="gob" size="lg">
                                <BsEyeFill /> Ver reporte
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Link to={"/add"}>
                              <Button variant="brown" size="lg">
                                <ImPlus /> Agregar otro reporte
                              </Button>
                            </Link>
                          </div>
                        </Stack>
                      </div>
                    ) : (
                      <div className="m-1 d-grid gap-2 d-md-block">
                        <Row>
                          <Col xl="10" sm="12">
                            <h3>
                              <b>
                                {(!this.state.estatus
                                  ? ""
                                  : this.state.estatus.id === 2
                                  ? this.state.estatus.estatus + " A "
                                  : "") +
                                  (this.state.tipoN
                                    ? this.state.tipos[
                                        this.state.tipoN
                                      ].nombreT.toUpperCase()
                                    : tipo
                                    ? tipo.nombreT.toUpperCase()
                                    : null)}
                              </b>
                            </h3>
                            <h4 className="text-info">
                              <Spinner
                                animation="grow"
                                size="sm"
                                className="text-info"
                              />
                              <b> NUEVO REPORTE </b>
                            </h4>
                          </Col>
                          <Col xl="2" sm="12">
                            <h2 className="text-info">
                              <b>
                                {this.state.tipoN
                                  ? this.state.tipos[this.state.tipoN].prefijo
                                  : tipo
                                  ? tipo.prefijo
                                  : null}
                              </b>
                              <BiWorld />
                              {this.state.estatus ? (
                                <>
                                  {" "}
                                  <Button
                                    variant={"info"}
                                    size="sm"
                                    style={{ pointerEvents: "none" }}
                                  >
                                    <b>
                                      {this.state.estatus.estatus}
                                      {this.state.estatus.id === 1 ||
                                      this.state.estatus.id === 4 ? (
                                        <>
                                          {" "}
                                          <BsCheckLg />
                                        </>
                                      ) : null}
                                    </b>
                                  </Button>
                                </>
                              ) : null}
                            </h2>
                          </Col>

                          <hr />

                          <Col xs="12">
                            <h5>
                              <i>
                                {"Por favor rellene los campos solicitados... "}
                              </i>
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Popover>
                                    <Popover.Header as="h3">
                                      <b>Ayuda </b> <FaQuestionCircle />
                                    </Popover.Header>
                                    <Popover.Body>
                                      {
                                        " - El botón (Guardar) se desbloqueará al rellenar todos los "
                                      }
                                      <strong>{"*Campos obligatorios"}</strong>
                                      <br />
                                      {
                                        " - Click en (Personalizado) para escribir manualmente la localidad"
                                      }
                                      <br />
                                      {
                                        " - Solo se mostarán los refugios del municipio que esté seleccionado"
                                      }
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <strong>
                                  <FaQuestionCircle />
                                </strong>
                              </OverlayTrigger>
                            </h5>
                          </Col>
                          <Col xs="12">
                            <strong className="text-info">
                              *Campos obligatorios
                            </strong>
                          </Col>

                          <Col xl="6" sm="12" className="mb-2">
                            <Form.Group controlId="estatus">
                              <Form.Label>
                                <strong>Estatus del reporte*:</strong>
                              </Form.Label>
                              <Form.Select
                                value={
                                  this.state.estatus == null
                                    ? ""
                                    : this.state.estatus.id
                                }
                                onChange={this.onChangeEstatus}
                                onMouseLeave={this.handleKeypress}
                              >
                                <option defaultValue="" hidden>
                                  Seleccione el estatus del reporte...
                                </option>

                                {this.state.estatusList
                                  .slice(0, 2)
                                  .map((estatus) => (
                                    <option key={estatus.id} value={estatus.id}>
                                      {estatus.estatus}
                                    </option>
                                  ))}
                              </Form.Select>
                              <Alert
                                variant={"nocolor"}
                                show={this.state.estatus ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12" className="mb-2">
                            <Form.Group controlId="nivelEmergencia">
                              <Form.Label>
                                <strong>Nivel de emergencia*:</strong>
                              </Form.Label>
                              <Form.Select
                                className={
                                  this.state.nivelEmergencia
                                    ? niveles[this.state.nivelEmergencia - 1]
                                    : ""
                                }
                                value={
                                  this.state.nivelEmergencia == null
                                    ? ""
                                    : this.state.nivelEmergencia
                                }
                                onChange={this.onChangeNivelEmergencia}
                                onMouseLeave={this.handleKeypress}
                              >
                                <option defaultValue="" hidden>
                                  Seleccione el nivel de emergencia...
                                </option>
                                {nivelesEmergencia.map((nivelEmergencia) => (
                                  <option
                                    className="text-bold"
                                    key={nivelEmergencia}
                                    value={nivelEmergencia}
                                  >
                                    {"NIVEL " + nivelEmergencia}
                                  </option>
                                ))}
                              </Form.Select>
                              <Alert
                                variant={"nocolor"}
                                show={this.state.nivelEmergencia ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="3" sm="12">
                            <Form.Group className="mb-2" controlId="fenomeno">
                              <Form.Label>
                                <strong>Fenómeno:</strong>
                              </Form.Label>
                              <Form.Select disabled>
                                <option defaultValue={tipo}>
                                  {!tipo
                                    ? "Sin fenomeno"
                                    : tipo.fenomeno.nombreF}
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xl="6" sm="12">
                            <Form.Group className="mb-2" controlId="tipo">
                              <Form.Label>
                                <strong>Tipo de reporte*:</strong>
                              </Form.Label>
                              <Form.Select
                                value={
                                  this.state.tipoN == null
                                    ? ""
                                    : this.state.tipoN
                                }
                                onChange={this.onChangeTipo}
                                onMouseLeave={this.handleKeypress}
                              >
                                <option defaultValue="" hidden>
                                  {!tipo ? "Sin tipo" : tipo.nombreT}
                                </option>
                                {this.state.tipos.map((tipo, index) => (
                                  <option key={tipo.id} value={index}>
                                    {tipo.nombreT}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xl="3" sm="12">
                            <Form.Group
                              className="mb-2"
                              controlId="fechaReporte"
                            >
                              <Form.Label>
                                <strong>Fecha del reporte:</strong>
                              </Form.Label>
                              <Form.Control
                                type="datetime-local"
                                value={
                                  this.state.fechaReporte == null
                                    ? ""
                                    : this.state.fechaReporte
                                }
                                disabled
                              />
                              <Alert
                                variant={"nocolor"}
                                show={this.state.fechaReporte ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="4" sm="12" className="mb-2">
                            <Form.Group controlId="municipio">
                              <Form.Label>
                                <strong>Municipio*:</strong>
                              </Form.Label>
                              <Form.Select
                                value={
                                  this.state.municipio == null
                                    ? ""
                                    : this.state.municipio
                                }
                                onChange={this.onChangeMunicipio}
                                onMouseLeave={this.handleKeypress}
                              >
                                <option defaultValue="" hidden>
                                  Seleccione el municipio...
                                </option>
                                {this.state.municipios.map((municipio) => (
                                  <option
                                    key={municipio.id}
                                    value={municipio.id}
                                  >
                                    {municipio.municipio}
                                  </option>
                                ))}
                              </Form.Select>
                              <Alert
                                variant={"nocolor"}
                                show={this.state.municipio ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="4" sm="12" className="mb-2">
                            <Form.Group controlId="localidad">
                              <Form.Group as={Row} controlId="localidadList">
                                <Col sm="6">
                                  <Form.Label>
                                    <strong>Localidad*:</strong>
                                  </Form.Label>
                                </Col>
                                <Col sm="6">
                                  <div className="d-grid">
                                    {this.state.localidadList ? (
                                      <Button
                                        variant="info"
                                        onClick={this.onChangeLocalidadList}
                                        size="sm"
                                      >
                                        <b>Personalizado</b>
                                        <MdEditNote />
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="outline-info"
                                        onClick={this.onChangeLocalidadList}
                                        size="sm"
                                      >
                                        <b>Usar el listado</b>
                                        <MdList />
                                      </Button>
                                    )}
                                  </div>
                                </Col>
                              </Form.Group>

                              {this.state.localidadList ? (
                                <Form.Select
                                  value={
                                    this.state.localidad == null
                                      ? ""
                                      : this.state.localidad
                                  }
                                  onChange={this.onChangeLocalidad}
                                  onMouseLeave={this.handleKeypress}
                                >
                                  <option defaultValue="" hidden>
                                    Seleccione la localidad...
                                  </option>
                                  {this.state.localidades.map((localidad) => (
                                    <option
                                      key={localidad.id}
                                      value={localidad.localidad}
                                    >
                                      {localidad.localidad}
                                    </option>
                                  ))}
                                </Form.Select>
                              ) : (
                                <Form.Control
                                  type="text"
                                  placeholder="Ingrese el nombre de la localidad..."
                                  maxLength="128"
                                  value={
                                    this.state.localidad == null
                                      ? ""
                                      : this.state.localidad
                                  }
                                  onChange={this.onChangeLocalidad}
                                  onKeyUp={this.handleKeypress}
                                  name="localidad"
                                />
                              )}
                              <Alert
                                variant={"nocolor"}
                                show={this.state.localidad ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="4" sm="12" className="mb-2">
                            <Form.Group controlId="evento">
                              <Form.Label>
                                <OverlayTrigger
                                  placement="auto"
                                  overlay={
                                    <Popover>
                                      <Popover.Header as="h3">
                                        <b> Ejemplos de eventos:</b>
                                      </Popover.Header>
                                      <Popover.Body>
                                        Huracán "Karl", frente frío no. 9,
                                        temporada de suradas 2022, etc.
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <strong>
                                    {"Evento: "}
                                    <FaQuestionCircle />
                                  </strong>
                                </OverlayTrigger>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ingrese el evento causante del reporte si existe alguno"
                                maxLength="255"
                                value={
                                  this.state.evento == null
                                    ? ""
                                    : this.state.evento
                                }
                                onChange={this.onChangeEvento}
                                name="evento"
                              />
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12">
                            <Form.Group as={Row}>
                              <Col sm="6">
                                <Form.Group
                                  className="mb-1"
                                  controlId="latitud"
                                >
                                  <Form.Label>
                                    <strong>
                                      {"Latitud: "}
                                      {!this.state.posicionLa ? null : (
                                        <FaCheckCircle />
                                      )}
                                    </strong>
                                  </Form.Label>
                                  <InputGroup>
                                    <FormControl
                                      type="text"
                                      maxLength="14"
                                      autoComplete="off"
                                      placeholder={"0.0"}
                                      value={
                                        this.state.latitud == null
                                          ? ""
                                          : this.state.latitud
                                      }
                                      onChange={this.onChangeLatitud}
                                      onKeyUp={this.handlePosicion}
                                    />
                                    <InputGroup.Text>
                                      <b>°</b>
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <Alert
                                    variant={"nocolor"}
                                    show={
                                      this.state.latitud &&
                                      this.state.posicionLa === false
                                        ? true
                                        : false
                                    }
                                  >
                                    {
                                      <Badge bg="info">
                                        Inserte una latitud de Veracruz{" "}
                                        <FaExclamationTriangle />
                                      </Badge>
                                    }
                                  </Alert>
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group
                                  className="mb-1"
                                  controlId="longitud"
                                >
                                  <Form.Label>
                                    <strong>
                                      {"Longitud: "}
                                      {!this.state.posicionLo ? null : (
                                        <FaCheckCircle />
                                      )}
                                    </strong>
                                  </Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <b>-</b>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="14"
                                      autoComplete="off"
                                      placeholder="0.0"
                                      value={
                                        this.state.longitud == null
                                          ? ""
                                          : this.state.longitud
                                      }
                                      onChange={this.onChangeLongitud}
                                      onKeyUp={this.handlePosicion}
                                    />
                                    <InputGroup.Text>
                                      <b>°</b>
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <Alert
                                    variant={"nocolor"}
                                    show={
                                      this.state.longitud &&
                                      this.state.posicionLo === false
                                        ? true
                                        : false
                                    }
                                  >
                                    {
                                      <Badge bg="info">
                                        Inserte una longitud de Veracruz{" "}
                                        <FaExclamationTriangle />
                                      </Badge>
                                    }
                                  </Alert>
                                </Form.Group>
                              </Col>
                            </Form.Group>
                            <b>
                              ¿Necesita la ubicación?{" "}
                              <a
                                href="https://www.google.com/maps/"
                                target="_blank"
                                rel="noreferrer"
                                className="linktext"
                              >
                                <Button
                                  variant="whatsapp"
                                  size="sm"
                                  className="mb-1"
                                >
                                  Google maps <FaMapMarkerAlt />
                                </Button>
                              </a>{" "}
                            </b>
                            <OverlayTrigger
                              placement="auto"
                              show={this.state.errPegado}
                              overlay={
                                <Tooltip>
                                  Copie las coordenadas en grados decimales
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="whatsapp2"
                                size="sm"
                                className="mb-1"
                                onClick={this.pasteLatitudLongitud}
                              >
                                {"Pegar coordenadas "}
                                <FaPaste />
                              </Button>
                            </OverlayTrigger>
                          </Col>
                          <Col xl="5" sm="9" className="mb-3">
                            <Form.Group
                              className="mb-1"
                              controlId="fechaOcurrencia"
                            >
                              <Form.Label>
                                <strong>Fecha y hora de ocurrencia*:</strong>
                              </Form.Label>
                              <Form.Control
                                type="datetime-local"
                                value={
                                  this.state.fechaOcurrencia == null
                                    ? ""
                                    : this.state.fechaOcurrencia
                                }
                                onChange={this.onChangeFechaOcurrencia}
                                min={"2020-01-01T00:00"}
                                max={
                                  year +
                                  "-" +
                                  mesActual +
                                  "-" +
                                  diaActual +
                                  "T23:59"
                                }
                                onMouseLeave={this.handleKeypress}
                              />
                              <Alert
                                variant={"nocolor"}
                                show={this.state.fechaOcurrencia ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>
                          <Col xl="1" sm="3" className="mb-3 text-center">
                            <Form.Group className="mb-1" controlId="estimado">
                              <p></p>
                              <Form.Check
                                type="switch"
                                value={this.state.estimado}
                                onChange={this.onChangeEstimado}
                              />
                              <Form.Label>
                                <strong>
                                  {this.state.estimado
                                    ? "Hora aprox."
                                    : "Hora exacta"}
                                </strong>
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl="6" sm="12">
                            <Form.Group className="mb-1" controlId="reporta">
                              <Form.Label>
                                <strong>Reporta*:</strong>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ingrese el nombre de quien reportó"
                                onInput={this.maxLengthCheck}
                                maxLength="255"
                                value={
                                  this.state.reporta == null
                                    ? ""
                                    : this.state.reporta
                                }
                                onChange={this.onChangeReporta}
                                name="reporta"
                                onKeyUp={this.handleKeypress}
                              />
                              <Alert
                                variant={"nocolor"}
                                show={this.state.reporta ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>
                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group className="mb-1" controlId="cargo">
                              <Form.Label>
                                <strong>Cargo:</strong>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ingrese el cargo de quien reportó"
                                onInput={this.maxLengthCheck}
                                maxLength="255"
                                value={
                                  this.state.cargo == null
                                    ? ""
                                    : this.state.cargo
                                }
                                onChange={this.onChangeCargo}
                                name="cargo"
                              />
                            </Form.Group>
                          </Col>

                          <hr />

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group controlId="ubicaciones">
                              <Form.Group as={Row} controlId="ubicacionesN">
                                <Col sm="7">
                                  <Form.Label>
                                    <strong>Ubicaciones*:</strong>
                                  </Form.Label>
                                </Col>
                                <Col sm="5">
                                  <InputGroup size="sm">
                                    <InputGroup.Text>
                                      <strong>Cantidad*:</strong>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="4"
                                      placeholder={0}
                                      autoComplete="off"
                                      value={
                                        this.state.ubicacionesN == null
                                          ? ""
                                          : this.state.ubicacionesN
                                      }
                                      onChange={this.onChangeUbicacionesN}
                                      onKeyUp={this.handleKeypress}
                                    />
                                  </InputGroup>
                                  <Alert
                                    variant={"nocolor"}
                                    show={
                                      this.state.ubicacionesN ? false : true
                                    }
                                  >
                                    {badge}
                                  </Alert>
                                </Col>
                              </Form.Group>
                              <Form.Control
                                rows={2}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese la(s) ubicación(es) de la ocurrencia"
                                value={
                                  this.state.ubicaciones == null
                                    ? ""
                                    : this.state.ubicaciones
                                }
                                onChange={this.onChangeUbicaciones}
                                onKeyUp={this.handleKeypress}
                              />
                              <Alert
                                variant={"nocolor"}
                                show={this.state.ubicaciones ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group controlId="personasEvacuadas">
                              <Form.Group
                                as={Row}
                                controlId="personasEvacuadasN"
                              >
                                <Col sm="7">
                                  <Form.Label>
                                    <strong>Personas evacuadas:</strong>
                                  </Form.Label>
                                </Col>
                                <Col sm="5">
                                  <InputGroup size="sm">
                                    <InputGroup.Text>
                                      <strong>Cantidad:</strong>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="4"
                                      placeholder={0}
                                      autoComplete="off"
                                      value={
                                        this.state.personasEvacuadasN == null
                                          ? ""
                                          : this.state.personasEvacuadasN
                                      }
                                      onChange={this.onChangePersonasEvacuadasN}
                                    />
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Control
                                rows={2}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese los datos de las personas evacuadas"
                                value={
                                  this.state.personasEvacuadas == null
                                    ? ""
                                    : this.state.personasEvacuadas
                                }
                                onChange={this.onChangePersonasEvacuadas}
                              />
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group controlId="personasLocalizadas">
                              <Form.Group
                                as={Row}
                                controlId="personasLocalizadasN"
                              >
                                <Col sm="7">
                                  <Form.Label>
                                    <strong>Personas localizadas:</strong>
                                  </Form.Label>
                                </Col>
                                <Col sm="5">
                                  <InputGroup size="sm">
                                    <InputGroup.Text>
                                      <strong>Cantidad:</strong>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="4"
                                      placeholder={0}
                                      autoComplete="off"
                                      value={
                                        this.state.personasLocalizadasN == null
                                          ? ""
                                          : this.state.personasLocalizadasN
                                      }
                                      onChange={
                                        this.onChangePersonasLocalizadasN
                                      }
                                    />
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Control
                                rows={2}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese los datos de las personas localizadas"
                                value={
                                  this.state.personasLocalizadas == null
                                    ? ""
                                    : this.state.personasLocalizadas
                                }
                                onChange={this.onChangePersonasLocalizadas}
                              />
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group controlId="personasNoLocalizadas">
                              <Form.Group
                                as={Row}
                                controlId="personasNoLocalizadasN"
                              >
                                <Col sm="7">
                                  <Form.Label>
                                    <strong>Personas no localizadas:</strong>
                                  </Form.Label>
                                </Col>
                                <Col sm="5">
                                  <InputGroup size="sm">
                                    <InputGroup.Text>
                                      <strong>Cantidad:</strong>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="4"
                                      placeholder={0}
                                      autoComplete="off"
                                      value={
                                        this.state.personasNoLocalizadasN ==
                                        null
                                          ? ""
                                          : this.state.personasNoLocalizadasN
                                      }
                                      onChange={
                                        this.onChangePersonasNoLocalizadasN
                                      }
                                    />
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Control
                                rows={2}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese los datos de las personas no localizadas"
                                value={
                                  this.state.personasNoLocalizadas == null
                                    ? ""
                                    : this.state.personasNoLocalizadas
                                }
                                onChange={this.onChangePersonasNoLocalizadas}
                              />
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group controlId="personasLesionadas">
                              <Form.Group
                                as={Row}
                                controlId="personasLesionadasN"
                              >
                                <Col sm="7">
                                  <Form.Label>
                                    <strong>Personas lesionadas:</strong>
                                  </Form.Label>
                                </Col>
                                <Col sm="5">
                                  <InputGroup size="sm">
                                    <InputGroup.Text>
                                      <strong>Cantidad:</strong>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="4"
                                      placeholder={0}
                                      autoComplete="off"
                                      value={
                                        this.state.personasLesionadasN == null
                                          ? ""
                                          : this.state.personasLesionadasN
                                      }
                                      onChange={
                                        this.onChangePersonasLesionadasN
                                      }
                                    />
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Control
                                rows={2}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese los datos de las personas lesionadas"
                                value={
                                  this.state.personasLesionadas == null
                                    ? ""
                                    : this.state.personasLesionadas
                                }
                                onChange={this.onChangePersonasLesionadas}
                              />
                            </Form.Group>
                          </Col>


                          
                          <Col xl="1" sm="12" className="mb-3 text-center">
                            <Form.Group className="mb-1" controlId="fallecidos">
                              <p></p>



                              <Form.Check
                                type="switch"
                                value={this.state.fallecidos }
                                onChange={this.onChangeFallecidos}
                              />
                              <Form.Label>
                      
                                <strong>
                                  {this.state.fallecidos
                                    ? "Fallecidos"
                                    : "No fallecidos"}
                                </strong>
                                
                              </Form.Label>
                            </Form.Group>
                          </Col>

                          <Col xl="5" sm="9" className="mb-3">
                            <Form.Group controlId="personasFallecidas">
                              <Form.Group
                                as={Row}
                                controlId="personasFallecidasN"
                              >
                                <Col sm="7">
                                  <Form.Label>
                                    <strong>Personas fallecidas:</strong>
                                  </Form.Label>
                                </Col>
                                <Col sm="5">
                                  <InputGroup size="sm">
                                    <InputGroup.Text>
                                      <strong>Cantidad:</strong>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="4"
                                      placeholder={0}
                                      autoComplete="off"
                                      value={
                                        this.state.personasFallecidasN == null
                                          ? ""
                                          : this.state.personasFallecidasN
                                      }
                                      onChange={
                                        this.onChangePersonasFallecidasN
                                      }
                                    />
                                  </InputGroup>
                                </Col>
                              </Form.Group>
                              <Form.Control
                                rows={2}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese los datos de las personas lesionadas"
                                value={
                                  this.state.personasFallecidas == null
                                    ? ""
                                    : this.state.personasFallecidas
                                }
                                onChange={this.onChangePersonasFallecidas}
                              />
                            </Form.Group>
                          </Col>

                          <hr />

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group className="mb-1" controlId="refugio">
                              <Form.Label>
                                {this.state.refugio ? (
                                  <OverlayTrigger
                                    overlay={<Tooltip>Quitar refugio</Tooltip>}
                                  >
                                    <Button
                                      variant="dark"
                                      size="sm"
                                      onClick={this.resetRefugio}
                                    >
                                      <ImCross />
                                    </Button>
                                  </OverlayTrigger>
                                ) : null}
                                <strong>
                                  {" Refugio utilizado "}
                                  <b>
                                    {this.state.municipioCompleto
                                      ? "(" +
                                        this.state.municipioCompleto.municipio +
                                        ")"
                                      : ""}
                                  </b>
                                  {": "}
                                </strong>
                              </Form.Label>
                              <Form.Select
                                value={
                                  this.state.refugio == null
                                    ? ""
                                    : this.state.refugio
                                }
                                onClick={
                                  this.state.municipio
                                    ? () =>
                                        this.getRefugios(this.state.municipio)
                                    : null
                                }
                                onChange={this.onChangeRefugio}
                                onMouseLeave={this.handleKeypress}
                              >
                                <option defaultValue="" hidden>
                                  Seleccione el refugio...
                                </option>
                                {this.state.refugios.map((refugio, index) => (
                                  <option key={refugio.id} value={index}>
                                    {refugio.nombre && refugio.capacidad
                                      ? refugio.nombre
                                      : ""}
                                  </option>
                                ))}
                              </Form.Select>

                              <p></p>
                              <b>
                                ¿No encuentra refugios activos?{" "}
                                <Link
                                  to={"/refugiosList"}
                                  target="_blank"
                                  className="linktext"
                                >
                                  <Button
                                    variant="refugio"
                                    size="sm"
                                    className="mb-1"
                                  >
                                    Lista de refugios <ImHome />
                                  </Button>
                                </Link>
                              </b>
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group
                              className="mb-1"
                              controlId="fechaActivacion"
                            >
                              <Form.Label>
                                <strong>
                                  Fecha de activación del refugio:
                                </strong>
                              </Form.Label>
                              <Form.Control
                                type="datetime-local"
                                value={
                                  this.state.fechaActivacion == null
                                    ? ""
                                    : this.state.fechaActivacion
                                }
                                onChange={this.onChangeFechaActivacion}
                                min={"2020-01-01T00:00"}
                                max={
                                  year +
                                  "-" +
                                  mesActual +
                                  "-" +
                                  diaActual +
                                  "T23:59"
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-1"
                              controlId="fechaDesactivacion"
                            >
                              <Form.Label>
                                <strong>
                                  Fecha de desactivación del refugio:
                                </strong>
                              </Form.Label>
                              <Form.Control
                                type="datetime-local"
                                value={
                                  this.state.fechaDesactivacion == null
                                    ? ""
                                    : this.state.fechaDesactivacion
                                }
                                onChange={this.onChangeFechaDesactivacion}
                                min={this.state.fechaActivacion}
                                max={
                                  year +
                                  "-" +
                                  mesActual +
                                  "-" +
                                  diaActual +
                                  "T23:59"
                                }
                              />
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12">
                            <Form.Group
                              className="mb-1"
                              controlId="accionesImplementadas"
                            >
                              <Form.Label>
                                <strong>Acciones implementadas*:</strong>
                              </Form.Label>
                              <Form.Control
                                rows={3}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese las acciones que fueron implementadas"
                                value={
                                  this.state.accionesImplementadas == null
                                    ? ""
                                    : this.state.accionesImplementadas
                                }
                                onChange={this.onChangeAccionesImplementadas}
                                onKeyUp={this.handleKeypress}
                              />
                              <Alert
                                variant={"nocolor"}
                                show={
                                  this.state.accionesImplementadas
                                    ? false
                                    : true
                                }
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>
                          <Col xl="6" sm="12">
                            <Form.Group className="mb-1" controlId="notas">
                              <Form.Label>
                                <strong>Notas:</strong>
                              </Form.Label>
                              <Form.Control
                                rows={3}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese las notas del reporte"
                                value={
                                  this.state.notas == null
                                    ? ""
                                    : this.state.notas
                                }
                                onChange={this.onChangeNotas}
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="6" sm="12">
                            <Form.Group
                              className="mb-1"
                              controlId="fuerzaTarea"
                            >
                              <Form.Label>
                                <strong>Fuerzas de tarea*:</strong>
                              </Form.Label>
                              <Form.Control
                                rows={3}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese las fuerzas de tarea involucradas"
                                value={
                                  this.state.fuerzaTarea == null
                                    ? ""
                                    : this.state.fuerzaTarea
                                }
                                onChange={this.onChangeFuerzaTarea}
                                onKeyUp={this.handleKeypress}
                              />
                              <Alert
                                variant={"nocolor"}
                                show={this.state.fuerzaTarea ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>
                          <Col xl="6" sm="12">
                            <Form.Group
                              className="mb-1"
                              controlId="evidencia"
                              onMouseOver={this.handleKeypress}
                            >
                              <Form.Label>
                                <strong>
                                  Evidencias: (Máximo 3 Archivos de 2MB cada
                                  uno)
                                </strong>
                              </Form.Label>
                              <div className="my-2">
                                <Dropzone
                                  onDrop={this.onDrop}
                                  accept="image/jpeg,image/png"
                                  maxSize={2200000}
                                  maxFiles={3}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        {selectedFiles &&
                                        Array.isArray(selectedFiles) &&
                                        selectedFiles.length ? (
                                          <div className="selected-file">
                                            {selectedFiles.length > 3
                                              ? `${selectedFiles.length} files`
                                              : selectedFiles
                                                  .map((file) => file.name)
                                                  .join(", ")}
                                          </div>
                                        ) : (
                                          `Arrastre sus imágenes aquí o haga click.`
                                        )}
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                              <b>
                                ¿Imágenes muy pesadas?{" "}
                                <a
                                  href="https://www.iloveimg.com/es/comprimir-imagen"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="linktext"
                                >
                                  <Button
                                    variant="light"
                                    size="sm"
                                    className="mb-1"
                                  >
                                    Compresor de imágenes{" "}
                                    <FaCompressArrowsAlt />
                                  </Button>
                                </a>
                              </b>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Stack direction="horizontal" gap={3}>
                          <div>
                            <Button
                              variant="outline-info"
                              className="ms-auto"
                              size="lg"
                              onClick={this.goBack}
                            >
                              <ImArrowLeft2 /> Regresar
                            </Button>
                          </div>

                          <div
                            className="ms-auto"
                            onMouseOver={this.handleKeypress}
                          >
                            {this.state.cargando ? (
                              <Button
                                variant="brown"
                                className="m-2"
                                disabled={true}
                                size="lg"
                              >
                                <b>Cargando</b>{" "}
                                <Spinner
                                  animation="border"
                                  variant="light"
                                  size="sm"
                                />
                              </Button>
                            ) : (
                              <OverlayTrigger
                                placement="left"
                                show={this.state.camposFaltantes}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip>
                                    Faltan campos por llenar / Datos Incorrectos
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="brown"
                                  className="m-2"
                                  disabled={this.state.camposFaltantes}
                                  size="lg"
                                  onClick={this.saveReporte}
                                >
                                  <BsCheckLg /> <b>Guardar</b>
                                </Button>
                              </OverlayTrigger>
                            )}
                          </div>
                        </Stack>
                      </div>
                    )}
                  </Alert>
                )}

                {this.state.messageError ? (
                  <Alert variant={"error"} className="text-center edit-form">
                    <Alert.Heading>
                      <strong>ERROR: </strong>
                    </Alert.Heading>
                    <div className="text-center">{this.state.messageError}</div>
                  </Alert>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(AstABCAdd);
