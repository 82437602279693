import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import { Link } from "react-router-dom";
import Navbar from "../../bars/Navbar";
import Sidebar from "../../bars/Sidebar";
import WithRouter from "../../common/WithRouter";
import {
  Alert,
  Row,
  Col,
  Form,
  Stack,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  FormControl,
  Badge,
  Popover,
} from "react-bootstrap";
import { ImArrowLeft2, ImHome, ImPlus } from "react-icons/im";
import { BsCheckLg } from "react-icons/bs";
import {
  FaPaste,
  FaMapMarkerAlt,
  FaQuestionCircle,
  FaExclamationTriangle,
  FaCheckCircle,
} from "react-icons/fa";

import ReporteService from "../../services/ReporteService";
import MunicipioService from "../../services/MunicipioService";
import AuthService from "../../services/AuthService";

const badge = (
  <Badge bg="refugio">
    Falta este campo por llenar <FaExclamationTriangle />
  </Badge>
);

class RefugioAdd extends Component {
  constructor(props) {
    super(props);
    this.onChangeNombre = this.onChangeNombre.bind(this);
    this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
    this.onChangeDireccion = this.onChangeDireccion.bind(this);
    this.onChangeCapacidad = this.onChangeCapacidad.bind(this);
    this.onChangeEstatus = this.onChangeEstatus.bind(this);
    this.saveRefugio = this.saveRefugio.bind(this);
    this.newRefugio = this.newRefugio.bind(this);
    this.getMunicipios = this.getMunicipios.bind(this);
    this.onChangeLatitud = this.onChangeLatitud.bind(this);
    this.onChangeLongitud = this.onChangeLongitud.bind(this);
    this.pasteLatitudLongitud = this.pasteLatitudLongitud.bind(this);

    this.state = {
      municipios: [],

      id: null,
      nombre: null,
      latitud: null,
      longitud: null,
      direccion: null,
      capacidad: null,
      estatus: true,

      municipioN: null,

      camposFaltantes: true,
      subido: false,
      cargando: false,
      errPegado: false,
      posicionLa: false,
      posicionLo: false,
      cargandoRefugio: true,

      message: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      document.title = "SICECOM - Agregar refugio";

      this.getMunicipios();
    }
  }

  onChangeNombre(e) {
    this.setState({
      nombre: e.target.value,
    });
  }

  onChangeLatitud(e) {
    this.setState({
      latitud: e.target.value.replace(/[^\d.]/g, ""),
    });
  }

  onChangeLongitud(e) {
    this.setState({
      longitud: e.target.value.replace(/[^\d.]/g, ""),
    });
  }

  pasteLatitudLongitud() {
    navigator.clipboard
      .readText()
      .then((response) => {
        var paste = response.split(", -");
        this.setState({
          latitud: paste[0].slice(0, 13),
          longitud: paste[1].slice(0, 13),
        });
        this.handlePosicion();
      })
      .catch(() => this.errPegado());
  }

  errPegado() {
    this.setState({
      errPegado: true,
    });
    setTimeout(() => {
      this.setState({
        errPegado: false,
      });
    }, 1500);
  }

  onChangeMunicipio(e) {
    this.setState({
      municipioN: e.target.value,
    });
  }

  onChangeDireccion(e) {
    this.setState({
      direccion: e.target.value,
    });
  }

  onChangeCapacidad(e) {
    this.setState({
      capacidad: e.target.value.replace(/[^0-9]/gi, ""),
    });
  }

  onChangeEstatus() {
    if (this.state.estatus) {
      this.setState({
        estatus: false,
      });
    } else {
      this.setState({
        estatus: true,
      });
    }
  }

  getMunicipios() {
    MunicipioService.getMunicipios()
      .then((response) => {
        this.setState({
          municipios: response.data,
          cargandoRefugio: false,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          console.log(err);
        }
      });
  }

  handlePosicion = () => {
    if (this.state.latitud < 22.5 && this.state.latitud > 17.12) {
      this.setState({
        posicionLa: true,
      });
    } else {
      this.setState({
        posicionLa: false,
      });
    }
    if (this.state.longitud < 98.71 && this.state.longitud > 93.59) {
      this.setState({
        posicionLo: true,
      });
    } else {
      this.setState({
        posicionLo: false,
      });
    }

    setTimeout(() => {
      this.handleKeypress();
    }, 10);
  };

  handleKeypress = () => {
    if (
      this.state.nombre &&
      (this.state.posicionLa || !this.state.latitud) &&
      (this.state.posicionLo || !this.state.longitud) &&
      this.state.municipioN &&
      this.state.direccion &&
      this.state.capacidad
    ) {
      this.setState({
        camposFaltantes: false,
      });
    } else {
      this.setState({
        camposFaltantes: true,
      });
    }
  };

  saveRefugio() {
    var data = {
      nombre:
        this.state.nombre.charAt(0).toUpperCase() + this.state.nombre.slice(1),
      latitud: this.state.latitud,
      longitud: this.state.longitud
        ? "-" + this.state.longitud
        : this.state.longitud,
      direccion: this.state.direccion,
      capacidad: this.state.capacidad,
      estatus: this.state.estatus,

      municipio: this.state.municipios[this.state.municipioN],
    };

    ReporteService.createRefugio(data)
      .then(() => {
        this.setState({
          message: "",
          subido: true,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          EventBus.dispatch("expireToken");
        } else {
          this.setState({
            message: err,
          });
        }
      });
  }

  newRefugio() {
    this.setState({
      municipios: [],

      id: null,
      nombre: null,
      latitud: null,
      longitud: null,
      direccion: null,
      capacidad: null,
      estatus: true,

      municipioN: null,

      camposFaltantes: true,
      subido: false,
      cargando: false,
      errPegado: false,
      posicionLa: false,
      posicionLo: false,

      message: null,
    });
  }

  goBack = () => {
    this.props.router.navigate("/refugiosList");
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  render() {
    return (
      <>
        <div className="flex">
          <Sidebar />
          <div className="content w-100 cont">
            <Navbar />
            <div className="list row">
              <div className="col-md-12">
                {this.state.cargandoRefugio ? (
                  <div className="d-flex justify-content-center">
                    <h4>
                      <Spinner animation="border" size="lg" />
                    </h4>
                  </div>
                ) : (
                  <Alert variant="refugio">
                    {this.state.subido ? (
                      <div>
                        <Alert.Heading className="text-center">
                          <h3>
                            <b>Refugio generado correctamente</b>
                          </h3>
                          <h4>
                            <strong className="refugiotext">
                              {(this.state.nombre == null
                                ? ""
                                : this.state.nombre) +
                                " (" +
                                this.state.municipios[this.state.municipioN]
                                  .municipio +
                                ")"}
                            </strong>
                          </h4>
                        </Alert.Heading>

                        <p className="nocolor mediumtext">&zwnj;</p>

                        <Stack direction="horizontal" gap={3}>
                          <div>
                            <Link to={"/refugiosList"}>
                              <Button
                                variant="outline-refugio"
                                className="ms-auto"
                                size="lg"
                              >
                                <ImArrowLeft2 /> Ir a la lista
                              </Button>
                            </Link>
                          </div>
                          <div className="ms-auto">
                            <Button
                              variant="brown"
                              className="m-2"
                              size="lg"
                              onClick={this.newRefugio}
                            >
                              <ImPlus /> Agregar otro refugio
                            </Button>
                          </div>
                        </Stack>
                      </div>
                    ) : (
                      <div className="m-1 d-grid gap-2 d-md-block">
                        <Row>
                          <Col xl="11" sm="12">
                            <h3>
                              <b>REFUGIO TEMPORAL</b>{" "}
                              {this.state.estatus ? (
                                <Button
                                  variant="success"
                                  size="sm"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <b>Activo</b>
                                </Button>
                              ) : (
                                <Button
                                  variant="danger"
                                  size="sm"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <b>Desactivado</b>
                                </Button>
                              )}
                            </h3>
                            <h4 className="refugiotext">
                              <Spinner
                                animation="grow"
                                size="sm"
                                className="refugiotext"
                              />
                              <b> NUEVO REFUGIO </b>
                            </h4>
                          </Col>
                          <Col xl="1" sm="12">
                            <h2 className="refugiotext">
                              <ImHome />
                            </h2>
                          </Col>

                          <hr />

                          <Col xs="12">
                            <h5>
                              <i>
                                {"Por favor rellene los campos solicitados... "}
                              </i>
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Popover>
                                    <Popover.Header as="h3">
                                      <b>Ayuda </b> <FaQuestionCircle />
                                    </Popover.Header>
                                    <Popover.Body>
                                      {
                                        " - El botón (Guardar) se desbloqueará al rellenar todos los "
                                      }
                                      <strong>{"*Campos obligatorios"}</strong>
                                      <br />
                                      {
                                        " - Click en el switch para cambiar el estatus del refugio"
                                      }
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <strong>
                                  <FaQuestionCircle />
                                </strong>
                              </OverlayTrigger>
                            </h5>
                          </Col>
                          <Col xs="12">
                            <strong className="refugiotext">
                              *Campos obligatorios
                            </strong>
                          </Col>

                          <Col xs="12">
                            <Form.Check
                              type="switch"
                              value={this.state.estatus}
                              checked={this.state.estatus}
                              onChange={this.onChangeEstatus}
                              label={
                                this.state.estatus ? (
                                  <h5>
                                    <b className="text-success">
                                      Refugio Activo
                                    </b>
                                  </h5>
                                ) : (
                                  <h5>
                                    <b className="text-danger">
                                      Refugio Desactivado
                                    </b>
                                  </h5>
                                )
                              }
                            />
                          </Col>

                          <Col xl="6" sm="12">
                            <Form.Group className="mb-1" controlId="municipio">
                              <Form.Label>
                                <strong>Municipio*:</strong>
                              </Form.Label>
                              <Form.Select
                                value={
                                  this.state.municipioN == null
                                    ? ""
                                    : this.state.municipioN
                                }
                                onClick={this.getMunicipios}
                                onChange={this.onChangeMunicipio}
                                onMouseLeave={this.handleKeypress}
                              >
                                <option defaultValue="" hidden>
                                  Seleccione el municipio donde se ubica el
                                  refugio...
                                </option>
                                {this.state.municipios.map(
                                  (municipio, index) => (
                                    <option key={municipio.id} value={index}>
                                      {municipio.municipio}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                              <Alert
                                variant={"nocolor"}
                                show={this.state.municipioN ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12">
                            <Form.Group className="mb-1" controlId="nombre">
                              <Form.Label>
                                <strong>Nombre*:</strong>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ingrese el nombre del refugio"
                                maxLength="128"
                                value={
                                  this.state.nombre == null
                                    ? ""
                                    : this.state.nombre
                                }
                                onChange={this.onChangeNombre}
                                onKeyUp={this.handleKeypress}
                                name="nombre"
                                autoComplete="off"
                              />
                              <Alert
                                variant={"nocolor"}
                                show={this.state.nombre ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12">
                            <Form.Group as={Row}>
                              <Col sm="6">
                                <Form.Group
                                  className="mb-1"
                                  controlId="latitud"
                                >
                                  <Form.Label>
                                    <strong>
                                      {"Latitud: "}
                                      {!this.state.posicionLa ? null : (
                                        <FaCheckCircle />
                                      )}
                                    </strong>
                                  </Form.Label>
                                  <InputGroup>
                                    <FormControl
                                      type="text"
                                      maxLength="14"
                                      autoComplete="off"
                                      placeholder={"0.0"}
                                      value={
                                        this.state.latitud == null
                                          ? ""
                                          : this.state.latitud
                                      }
                                      onChange={this.onChangeLatitud}
                                      onKeyUp={this.handlePosicion}
                                    />
                                    <InputGroup.Text>
                                      <b>°</b>
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <Alert
                                    variant={"nocolor"}
                                    show={
                                      this.state.latitud &&
                                      this.state.posicionLa === false
                                        ? true
                                        : false
                                    }
                                  >
                                    {
                                      <Badge bg="primary">
                                        Inserte una latitud de Veracruz{" "}
                                        <FaExclamationTriangle />
                                      </Badge>
                                    }
                                  </Alert>
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group
                                  className="mb-1"
                                  controlId="longitud"
                                >
                                  <Form.Label>
                                    <strong>
                                      {"Longitud: "}
                                      {!this.state.posicionLo ? null : (
                                        <FaCheckCircle />
                                      )}
                                    </strong>
                                  </Form.Label>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <b>-</b>
                                    </InputGroup.Text>
                                    <FormControl
                                      type="text"
                                      maxLength="14"
                                      autoComplete="off"
                                      placeholder="0.0"
                                      value={
                                        this.state.longitud == null
                                          ? ""
                                          : this.state.longitud
                                      }
                                      onChange={this.onChangeLongitud}
                                      onKeyUp={this.handlePosicion}
                                    />
                                    <InputGroup.Text>
                                      <b>°</b>
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <Alert
                                    variant={"nocolor"}
                                    show={
                                      this.state.longitud &&
                                      this.state.posicionLo === false
                                        ? true
                                        : false
                                    }
                                  >
                                    {
                                      <Badge bg="primary">
                                        Inserte una longitud de Veracruz{" "}
                                        <FaExclamationTriangle />
                                      </Badge>
                                    }
                                  </Alert>
                                </Form.Group>
                              </Col>
                            </Form.Group>
                            <b>
                              ¿Necesita la ubicación?{" "}
                              <a
                                href="https://www.google.com/maps/"
                                target="_blank"
                                rel="noreferrer"
                                className="linktext"
                              >
                                <Button
                                  variant="whatsapp"
                                  size="sm"
                                  className="mb-1"
                                >
                                  Google maps <FaMapMarkerAlt />
                                </Button>
                              </a>{" "}
                            </b>
                            <OverlayTrigger
                              placement="auto"
                              show={this.state.errPegado}
                              overlay={
                                <Tooltip>
                                  Copie las coordenadas en grados decimales
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="whatsapp2"
                                size="sm"
                                className="mb-1"
                                onClick={this.pasteLatitudLongitud}
                              >
                                {"Pegar coordenadas "}
                                <FaPaste />
                              </Button>
                            </OverlayTrigger>
                          </Col>

                          <Col xl="6" sm="12">
                            <Form.Group className="mb-1" controlId="direccion">
                              <Form.Label>
                                <strong>Dirección*:</strong>
                              </Form.Label>
                              <Form.Control
                                rows={3}
                                maxLength={8192}
                                as="textarea"
                                placeholder="Ingrese la dirección del refugio"
                                value={
                                  this.state.direccion == null
                                    ? ""
                                    : this.state.direccion
                                }
                                onChange={this.onChangeDireccion}
                                onKeyUp={this.handleKeypress}
                              />
                              <Alert
                                variant={"nocolor"}
                                show={this.state.direccion ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>

                          <Col xl="6" sm="12" className="mb-3">
                            <Form.Group className="mb-1">
                              <Form.Label>
                                <strong>Capacidad máxima*:</strong>
                              </Form.Label>
                              <InputGroup>
                                <FormControl
                                  type="text"
                                  maxLength="6"
                                  placeholder={0}
                                  value={
                                    this.state.capacidad == null
                                      ? ""
                                      : this.state.capacidad
                                  }
                                  onChange={this.onChangeCapacidad}
                                  onKeyUp={this.handleKeypress}
                                />
                                <InputGroup.Text>
                                  <strong>personas</strong>
                                </InputGroup.Text>
                              </InputGroup>
                              <Alert
                                variant={"nocolor"}
                                show={this.state.capacidad ? false : true}
                              >
                                {badge}
                              </Alert>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Stack direction="horizontal" gap={3}>
                          <div>
                            <Button
                              variant="outline-refugio"
                              className="ms-auto"
                              size="lg"
                              onClick={this.goBack}
                            >
                              <ImArrowLeft2 /> Regresar
                            </Button>
                          </div>

                          <div
                            className="ms-auto"
                            onMouseOver={this.handleKeypress}
                          >
                            {this.state.cargando ? (
                              <Button
                                variant="brown"
                                className="m-2"
                                disabled={true}
                                size="lg"
                              >
                                <b>Cargando</b>{" "}
                                <Spinner
                                  animation="border"
                                  variant="refugio"
                                  size="sm"
                                />
                              </Button>
                            ) : (
                              <OverlayTrigger
                                placement="left"
                                show={this.state.camposFaltantes}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip>
                                    Faltan campos por llenar / Datos Incorrectos
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="brown"
                                  className="m-2"
                                  disabled={this.state.camposFaltantes}
                                  size="lg"
                                  onClick={this.saveRefugio}
                                >
                                  <BsCheckLg /> <b>Guardar</b>
                                </Button>
                              </OverlayTrigger>
                            )}
                          </div>
                        </Stack>
                      </div>
                    )}
                  </Alert>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithRouter(RefugioAdd);
