import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { AiFillFileExcel } from "react-icons/ai";
import { Button } from "react-bootstrap";
import ReporteService from "./ReporteService";

const monthNames = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

let reporteCompleto;

let fechaActual;
let year;
let month;
let day;
let hour;
let minute;
let second;
let mesActual;
let diaActual;
let horaActual;
let minutoActual;
let segundoActual;

const getDate = () => {
  fechaActual = new Date();
  year = new Date(fechaActual).getFullYear();
  month = new Date(fechaActual).getMonth() + 1;
  day = new Date(fechaActual).getDate();
  hour = new Date(fechaActual).getHours();
  minute = new Date(fechaActual).getMinutes();
  second = new Date(fechaActual).getSeconds();

  if (month < 10) {
    mesActual = "0" + month;
  } else {
    mesActual = month;
  }

  if (day < 10) {
    diaActual = "0" + day;
  } else {
    diaActual = day;
  }

  if (hour < 10) {
    horaActual = "0" + hour;
  } else {
    horaActual = hour;
  }

  if (minute < 10) {
    minutoActual = "0" + minute;
  } else {
    minutoActual = minute;
  }

  if (second < 10) {
    segundoActual = "0" + second;
  } else {
    segundoActual = second;
  }

  return (
    "Reportes_" +
    diaActual +
    "_" +
    mesActual +
    "_" +
    year +
    "_" +
    horaActual +
    "_" +
    minutoActual +
    "_" +
    segundoActual
  );
};

function getReporteId(id) {
  return ReporteService.getReporteCompleto(id).then(
    (response) => response.data
  );
}

export const ExportCSV = ({ csvData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData) => {
    const reportesCompletos = csvData.map((reporte) =>
      getReporteId(reporte.id)
    );

    Promise.all(reportesCompletos).then((values) => (reporteCompleto = values));

    setTimeout(() => {
      const rows = csvData.map((reporte, index) => ({
        FOLIO: reporte.folio,
        FENOMENO: reporte.tipoR.fenomeno.nombreF,
        TIPO_DE_REPORTE:
          reporte.tipoR.id === 46 ? reporte.evento : reporte.tipoR.nombreT,
        MUNICIPIO: reporteCompleto[index].ubicacionPersonalizada
          ? reporteCompleto[index].ubicacionPersonalizada + " (externo)"
          : reporte.municipio
          ? reporte.municipio.municipio
          : reporte.region
          ? "Región " + reporte.region.region
          : "",
        LOCALIDAD: reporte.localidad,
        FECHA_DEL_REPORTE:
          (new Date(reporte.fechaReporte).getDate() < 10 ? "0" : "") +
          new Date(reporte.fechaReporte).getDate() +
          "/" +
          monthNames[new Date(reporte.fechaReporte).getMonth()] +
          "/" +
          new Date(reporte.fechaReporte).getUTCFullYear(),
        HORA_DEL_REPORTE:
          (new Date(reporte.fechaReporte).getHours() < 10 ? "0" : "") +
          new Date(reporte.fechaReporte).getHours() +
          ":" +
          (new Date(reporte.fechaReporte).getMinutes() < 10 ? "0" : "") +
          new Date(reporte.fechaReporte).getMinutes(),
        ESTATUS: reporte.estatus.estatus,
        FECHA_DE_OCURRENCIA:
          (new Date(reporte.fechaOcurrencia).getDate() < 10 ? "0" : "") +
          new Date(reporte.fechaOcurrencia).getDate() +
          "/" +
          monthNames[new Date(reporte.fechaOcurrencia).getMonth()] +
          "/" +
          new Date(reporte.fechaOcurrencia).getUTCFullYear(),
        HORA_DE_OCURRENCIA:
          (new Date(reporte.fechaOcurrencia).getHours() < 10 ? "0" : "") +
          new Date(reporte.fechaOcurrencia).getHours() +
          ":" +
          (new Date(reporte.fechaOcurrencia).getMinutes() < 10 ? "0" : "") +
          new Date(reporte.fechaOcurrencia).getMinutes() +
          (new Date(reporte.fechaOcurrencia).getSeconds() < 1
            ? ""
            : new Date(reporte.fechaOcurrencia).getSeconds() < 10
            ? ":0" + new Date(reporte.fechaOcurrencia).getSeconds()
            : ":" + new Date(reporte.fechaOcurrencia).getSeconds()) +
          (reporte.estimado ? " aprox" : ""),
        ÚLTIMA_MODIFICACIÓN: reporte.user
          ? reporte.user.nombre +
            " " +
            reporte.user.apellidoP +
            " " +
            reporte.user.apellidoM
          : "",
        EVENTO: reporte.evento,
        REPORTÓ: reporte.reporta,
        CARGO: reporte.cargo,
        COORDENADAS:
          (reporte.latitud ? reporte.latitud + "°" : "") +
          (reporte.longitud ? reporte.longitud + "°" : ""),
        PROFUNDIDAD: reporteCompleto[index].profundidad
          ? reporteCompleto[index].profundidad + " Km."
          : "",
        MAGNITUD: reporteCompleto[index].magnitud,
        LOCALIZACIÓN: reporteCompleto[index].localizacion,
        MUNICIPIOS_CON_PERCEPCIÓN: reporteCompleto[index].municipiosPercepcionN
          ? "(" +
            reporteCompleto[index].municipiosPercepcionN +
            "): " +
            (reporteCompleto[index].municipiosPercepcion
              ? reporteCompleto[index].municipiosPercepcion
              : "")
          : "",

        INMUEBLES_EVACUADOS: reporteCompleto[index].inmueblesEvacuadosN
          ? "(" +
            reporteCompleto[index].inmueblesEvacuadosN +
            "): " +
            (reporteCompleto[index].inmueblesEvacuados
              ? reporteCompleto[index].inmueblesEvacuados
              : "")
          : "",
        UBICACIONES: reporteCompleto[index].ubicacionesN
          ? "(" +
            reporteCompleto[index].ubicacionesN +
            "): " +
            (reporteCompleto[index].ubicaciones
              ? reporteCompleto[index].ubicaciones
              : "")
          : reporteCompleto[index].ubicacion
          ? reporteCompleto[index].ubicacion
          : "",
        AFECTACIONES: reporteCompleto[index].afectacionesN
          ? "(" +
            reporteCompleto[index].afectacionesN +
            "): " +
            (reporteCompleto[index].afectaciones
              ? reporteCompleto[index].afectaciones
              : "")
          : "",
        SUSTANCIA_INVOLUCRADA: reporteCompleto[index].sustancias,
        ESTATUS_FUGA: reporteCompleto[index].estatusFuga
          ? reporteCompleto[index].estatusFuga.estatusFuga
          : "",
        ESTATUS_INCENDIO:
          reporteCompleto[index].estatusControl ||
          reporteCompleto[index].estatusLiquidacion
            ? (reporteCompleto[index].estatusControl
                ? reporteCompleto[index].estatusControl.estatusControl
                : "") +
              (reporteCompleto[index].estatusControl &&
              reporteCompleto[index].estatusLiquidacion
                ? ", "
                : "") +
              (reporteCompleto[index].estatusLiquidacion
                ? reporteCompleto[index].estatusLiquidacion.estatusLiquidacion
                : "")
            : "",
        VIVIENDAS_AFECTADAS: reporteCompleto[index].viviendasAN
          ? "(" +
            reporteCompleto[index].viviendasAN +
            "): " +
            (reporteCompleto[index].viviendasA
              ? reporteCompleto[index].viviendasA
              : "")
          : "",
        VÍAS_ALTERNAS: reporteCompleto[index].viasAlternas,
        LOCALIDADES_AFECTADAS: reporteCompleto[index].localidadesAfectadasN
          ? "(" +
            reporteCompleto[index].localidadesAfectadasN +
            "): " +
            (reporteCompleto[index].localidadesAfectadas
              ? reporteCompleto[index].localidadesAfectadas
              : "")
          : "",
        LOCALIDADES_INCOMUNICADAS: reporteCompleto[index]
          .localidadesIncomunicadasN
          ? "(" +
            reporteCompleto[index].localidadesIncomunicadasN +
            "): " +
            (reporteCompleto[index].localidadesIncomunicadas
              ? reporteCompleto[index].localidadesIncomunicadas
              : "")
          : "",
        LOCALIDADES_CON_ACCESO: reporteCompleto[index].localidadesConAccesoN
          ? "(" +
            reporteCompleto[index].localidadesConAccesoN +
            "): " +
            (reporteCompleto[index].localidadesConAcceso
              ? reporteCompleto[index].localidadesConAcceso
              : "")
          : "",
        COLONIAS_AFECTADAS: reporteCompleto[index].coloniasAN
          ? "(" +
            reporteCompleto[index].coloniasAN +
            "): " +
            (reporteCompleto[index].coloniasA
              ? reporteCompleto[index].coloniasA
              : "")
          : "",
        COMUNIDADES_AFECTADAS: reporteCompleto[index].coloniasAN
          ? "(" +
            reporteCompleto[index].coloniasAN +
            "): " +
            (reporteCompleto[index].coloniasA
              ? reporteCompleto[index].coloniasA
              : "")
          : "",
        ÁRBOLES_CAÍDOS: reporteCompleto[index].caidaArbolesN
          ? "(" +
            reporteCompleto[index].caidaArbolesN +
            "): " +
            (reporteCompleto[index].caidaArboles
              ? reporteCompleto[index].caidaArboles
              : "")
          : "",
        POSTES_CAÍDOS: reporteCompleto[index].caidaPostesN
          ? "(" +
            reporteCompleto[index].caidaPostesN +
            "): " +
            (reporteCompleto[index].caidaPostes
              ? reporteCompleto[index].caidaPostes
              : "")
          : "",
        ESPECTACULARES_CAÍDOS: reporteCompleto[index].caidaEspectacularesN
          ? "(" +
            reporteCompleto[index].caidaEspectacularesN +
            "): " +
            (reporteCompleto[index].caidaEspectaculares
              ? reporteCompleto[index].caidaEspectaculares
              : "")
          : "",
        VÍAS_FECTADAS: reporteCompleto[index].viasAN
          ? "(" +
            reporteCompleto[index].viasAN +
            "): " +
            (reporteCompleto[index].viasA ? reporteCompleto[index].viasA : "")
          : "",
        CONSTRUCCIONES_FECTADAS: reporteCompleto[index].construccionesAN
          ? "(" +
            reporteCompleto[index].construccionesAN +
            "): " +
            (reporteCompleto[index].construccionesA
              ? reporteCompleto[index].construccionesA
              : "")
          : "",
        PUENTES_FECTADOS: reporteCompleto[index].puentesAN
          ? "(" +
            reporteCompleto[index].puentesAN +
            "): " +
            (reporteCompleto[index].puentesA
              ? reporteCompleto[index].puentesA
              : "")
          : "",
        ESCUELAS_FECTADAS: reporteCompleto[index].escuelasAN
          ? "(" +
            reporteCompleto[index].escuelasAN +
            "): " +
            (reporteCompleto[index].escuelasA
              ? reporteCompleto[index].escuelasA
              : "")
          : "",
        INCONFORMIDAD: reporteCompleto[index].inconformidad,
        NÚMERO_DE_AFLUENCIA: reporteCompleto[index].afluenciaN
          ? reporteCompleto[index].afluenciaN + " personas"
          : "",
        ATENCIONES_MÉDICAS: reporteCompleto[index].atencionesMedicas,
        UNIDADES_INVOLUCRADAS: reporteCompleto[index].unidadesInvolucradasN
          ? "(" +
            reporteCompleto[index].unidadesInvolucradasN +
            "): " +
            (reporteCompleto[index].unidadesInvolucradas
              ? reporteCompleto[index].unidadesInvolucradas
              : "")
          : "",
        PERSONAS_EVACUADAS: reporteCompleto[index].personasEvacuadasN
          ? "(" +
            reporteCompleto[index].personasEvacuadasN +
            "): " +
            (reporteCompleto[index].personasEvacuadas
              ? reporteCompleto[index].personasEvacuadas
              : "")
          : "",
        PERSONAS_REFUGIADAS: reporteCompleto[index].personasRefugiadasN
          ? "(" +
            reporteCompleto[index].personasRefugiadasN +
            "): " +
            (reporteCompleto[index].personasRefugiadas
              ? reporteCompleto[index].personasRefugiadas
              : "")
          : "",
        PERSONAS_RESCATADAS: reporteCompleto[index].personasRescatadasN
          ? "(" +
            reporteCompleto[index].personasRescatadasN +
            "): " +
            (reporteCompleto[index].personasRescatadas
              ? reporteCompleto[index].personasRescatadas
              : "")
          : "",
        PERSONAS_LOCALIZADAS: reporteCompleto[index].personasLocalizadasN
          ? "(" +
            reporteCompleto[index].personasLocalizadasN +
            "): " +
            (reporteCompleto[index].personasLocalizadas
              ? reporteCompleto[index].personasLocalizadas
              : "")
          : "",
        PERSONAS_NO_LOCALIZADAS: reporteCompleto[index].personasNoLocalizadasN
          ? "(" +
            reporteCompleto[index].personasNoLocalizadasN +
            "): " +
            (reporteCompleto[index].personasNoLocalizadas
              ? reporteCompleto[index].personasNoLocalizadas
              : "")
          : "",
        PERSONAS_ATRAPADAS: reporteCompleto[index].personasAtrapadasN
          ? "(" +
            reporteCompleto[index].personasAtrapadasN +
            "): " +
            (reporteCompleto[index].personasAtrapadas
              ? reporteCompleto[index].personasAtrapadas
              : "")
          : "",
        PERSONAS_LESIONADAS: reporteCompleto[index].personasLesionadasN
          ? "(" +
            reporteCompleto[index].personasLesionadasN +
            "): " +
            (reporteCompleto[index].personasLesionadas
              ? reporteCompleto[index].personasLesionadas
              : "")
          : "",
        PERSONAS_AHOGADAS: reporteCompleto[index].personasAhogadasN
          ? "(" +
            reporteCompleto[index].personasAhogadasN +
            "): " +
            (reporteCompleto[index].personasAhogadas
              ? reporteCompleto[index].personasAhogadas
              : "")
          : "",
        PERSONAS_FALLECIDAS: reporteCompleto[index].personasFallecidasN
          ? "(" +
            reporteCompleto[index].personasFallecidasN +
            "): " +
            (reporteCompleto[index].personasFallecidas
              ? reporteCompleto[index].personasFallecidas
              : "")
          : "",
        SERVICIOS_ESTRATÉGICOS: reporteCompleto[index].serviciosEstrategicosN
          ? "(" +
            reporteCompleto[index].serviciosEstrategicosN +
            "): " +
            (reporteCompleto[index].serviciosEstrategicos
              ? reporteCompleto[index].serviciosEstrategicos
              : "")
          : reporteCompleto[index].serviciosEstrategicos
          ? reporteCompleto[index].serviciosEstrategicos
          : "",
        CNA: reporteCompleto[index].cna,
        PEMEX: reporteCompleto[index].pemex,
        SUBCENTROS_REGIONALES: reporteCompleto[index].subcentrosRegionales,
        LAGUNA_VERDE: reporteCompleto[index].lagunaVerde,
        SECRETARÍA_DE_SALUD: reporteCompleto[index].secretariaSalud,
        TÚNEL_SUMERGIDO: reporteCompleto[index].tunelSumergido,
        TÍTULO_1: reporteCompleto[index].titulo1,
        CONTENIDO_1: reporteCompleto[index].contenido1,
        TÍTULO_2: reporteCompleto[index].titulo2,
        CONTENIDO_2: reporteCompleto[index].contenido2,
        TÍTULO_3: reporteCompleto[index].titulo3,
        CONTENIDO_3: reporteCompleto[index].contenido3,
        TÍTULO_4: reporteCompleto[index].titulo4,
        CONTENIDO_4: reporteCompleto[index].contenido4,
        REFUGIO_UTILIZADO: reporte.refugio ? reporte.refugio.nombre : "",
        CAPACIDAD_REFUGIO: reporte.refugio
          ? reporte.refugio.capacidad + " personas"
          : "",
        FECHA_DE_ACTIVACIÓN: reporte.fechaActivacion
          ? (new Date(reporte.fechaActivacion).getDate() < 10 ? "0" : "") +
            new Date(reporte.fechaActivacion).getDate() +
            "/" +
            monthNames[new Date(reporte.fechaActivacion).getMonth()] +
            "/" +
            new Date(reporte.fechaActivacion).getUTCFullYear()
          : "",
        HORA_DE_ACTIVACIÓN: reporte.fechaActivacion
          ? (new Date(reporte.fechaActivacion).getHours() < 10 ? "0" : "") +
            new Date(reporte.fechaActivacion).getHours() +
            ":" +
            (new Date(reporte.fechaActivacion).getMinutes() < 10 ? "0" : "") +
            new Date(reporte.fechaActivacion).getMinutes()
          : "",
        FECHA_DE_DESACTIVACIÓN: reporte.fechaDesactivacion
          ? (new Date(reporte.fechaDesactivacion).getDate() < 10 ? "0" : "") +
            new Date(reporte.fechaDesactivacion).getDate() +
            "/" +
            monthNames[new Date(reporte.fechaDesactivacion).getMonth()] +
            "/" +
            new Date(reporte.fechaDesactivacion).getUTCFullYear()
          : "",
        HORA_DE_DESACTIVACIÓN: reporte.fechaDesactivacion
          ? (new Date(reporte.fechaDesactivacion).getHours() < 10 ? "0" : "") +
            new Date(reporte.fechaDesactivacion).getHours() +
            ":" +
            (new Date(reporte.fechaDesactivacion).getMinutes() < 10
              ? "0"
              : "") +
            new Date(reporte.fechaDesactivacion).getMinutes()
          : "",
        FECHA_DE_TÉRMINO: reporteCompleto[index].fechaTermino
          ? (new Date(reporteCompleto[index].fechaTermino).getDate() < 10
              ? "0"
              : "") +
            new Date(reporteCompleto[index].fechaTermino).getDate() +
            "/" +
            monthNames[
              new Date(reporteCompleto[index].fechaTermino).getMonth()
            ] +
            "/" +
            new Date(reporteCompleto[index].fechaTermino).getUTCFullYear()
          : "",
        HORA_DE_TÉRMINO: reporteCompleto[index].fechaTermino
          ? (new Date(reporteCompleto[index].fechaTermino).getHours() < 10
              ? "0"
              : "") +
            new Date(reporteCompleto[index].fechaTermino).getHours() +
            ":" +
            (new Date(reporteCompleto[index].fechaTermino).getMinutes() < 10
              ? "0"
              : "") +
            new Date(reporteCompleto[index].fechaTermino).getMinutes() +
            (new Date(reporteCompleto[index].fechaTermino).getSeconds() < 1
              ? ""
              : new Date(reporteCompleto[index].fechaTermino).getSeconds() < 10
              ? ":0" +
                new Date(reporteCompleto[index].fechaTermino).getSeconds()
              : ":" +
                new Date(reporteCompleto[index].fechaTermino).getSeconds()) +
            (reporteCompleto[index].estimado ? " aprox" : "")
          : "",
        ACCIONES_IMPLEMENTADAS: reporte.accionesImplementadas,
        NOTAS: reporte.notas,
        FUERZAS_TAREA: reporte.fuerzaTarea,
      }));

      const ws = XLSX.utils.json_to_sheet(rows);
      const wb = {
        Sheets: { Reportes: ws },
        SheetNames: ["Reportes"],
      };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, getDate(), fileExtension);
    }, 400);
  };

  return (
    <Button variant="success" size="sm" onClick={() => exportToCSV(csvData)}>
      Excel <AiFillFileExcel />
    </Button>
  );
};
